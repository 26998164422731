import { Button } from '../Button';
import { useTranslation } from 'react-i18next';

import { AddOutlined, Delete, FactCheckOutlined } from '@mui/icons-material';
import { Alert, AlertTitle, Box, IconButton, TextField, Typography } from '@mui/material';
import { useEffect, useMemo, useState } from 'react';
import { ChatWithUs } from '../ChatWithUs';
import { apiRequest } from '../../../globals';
import { apiLinks } from '../../../config/api-config';
import { toast } from 'react-toastify';
import { PalletAccountTable } from './PalletAccountTable';
import { Controller, useForm } from 'react-hook-form';
import { ConfirmationDialog } from '../ConfirmationDialog';

export const PalletAccounts = () => {
  const { t } = useTranslation();

  const [loading, setLoading] = useState(false);
  const [palletAccountList, setPalletAccountList] = useState([]);
  const [showAddAccountForm, setShowAddAccountForm] = useState(false);

  const [errorDialog, setErrorDialog] = useState({
    open: false,
  });

  const [deleteDialog, setDeleteDialog] = useState({
    open: false,
  });

  const {
    control,
    handleSubmit,
    formState: { errors, isValid },
    reset,
  } = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: {
      palletAccountNumber: '',
    },
  });

  const onSubmit = data => {
    const submitData = async () => {
      try {
        setLoading(true);
        const params = {
          body: {
            palletAccounts: [
              {
                accountNumber: data.palletAccountNumber,
              },
            ],
          },
        };

        const result = await apiRequest(apiLinks.addPalletAccount, 'PUT', params);

        if (result.status === 200) {
          const responseData = result?.data?.palletAccounts;

          if (responseData) {
            const newlyAddedAccount = responseData.find(
              item => `${item.accountNumber}` === `${data.palletAccountNumber}`,
            );
            if (newlyAddedAccount) {
              setPalletAccountList(prev => [
                ...prev,
                {
                  ...newlyAddedAccount,
                  palletAccount: newlyAddedAccount.accountNumber,
                  accountApprovalStatus: newlyAddedAccount.status,
                },
              ]);
            }
          }

          toast(
            <Alert severity="success" variant="standard">
              {t(`editProfile|Pallet Account has been added`)}
            </Alert>,
          );
          reset();
          setShowAddAccountForm(false);
          setLoading(false);
        } else {
          const message = (
            <>
              {t(`editProfile|Account does not exist. Try again or call technical support`)} {t('general|or')}{' '}
              <ChatWithUs />
            </>
          );
          setErrorDialog({
            open: true,
            message,
          });
          setLoading(false);
        }
      } catch (error) {
        console.error('Error during saving the account', error);
        const message = (
          <>
            {t(`editProfile|Account adding failed. Try again or call technical support`)} {t('general|or')}{' '}
            <ChatWithUs />
          </>
        );
        setErrorDialog({
          open: true,
          message,
        });
        setLoading(false);
      }
    };
    submitData();
  };

  const deleteAccount = async account => {
    const handleError = () => {
      const message = (
        <>
          {t(`editProfile|Account removal failed. Try again or call technical support`)} {t('general|or')} `
          <ChatWithUs />
        </>
      );
      setErrorDialog({
        open: true,
        message,
      });
      setLoading(false);
    };
    try {
      const params = {
        body: {
          palletAccounts: [
            {
              accountNumber: account.palletAccount,
            },
          ],
        },
      };
      const result = await apiRequest(apiLinks.deletePalletAccount, 'DELETE', params);

      if (result.status === 200) {
        toast(
          <Alert severity="success" variant="standard">
            {t(`editProfile|Pallet Account has been removed`)}
          </Alert>,
        );
        setPalletAccountList(prev => prev.filter(item => item.palletAccount !== account.palletAccount));
      } else {
        handleError();
      }
    } catch (error) {
      handleError();
    }
  };

  const approvedAccounts = useMemo(
    () => palletAccountList.filter(item => item.accountApprovalStatus === 'APPROVED'),
    [palletAccountList],
  );
  const waitingAccounts = useMemo(
    () => palletAccountList.filter(item => item.accountApprovalStatus === 'WAITING'),
    [palletAccountList],
  );

  useEffect(() => {
    const fetchData = async () => {
      const response = await apiRequest(apiLinks.palletBalances, 'GET');
      if (response.status === 200) {
        setPalletAccountList(response.data);
      }
    };
    fetchData();
  }, []);

  return (
    <>
      <Typography
        variant="body1"
        sx={{
          mb: '16px',
          display: 'flex',
          alignItems: 'center',
          gap: '6px',
        }}
      >
        <FactCheckOutlined sx={{ fontSize: '20px' }} />
        {t(`editProfile|Pallet account(s) approved`)}
      </Typography>
      <PalletAccountTable accounts={approvedAccounts} setDeleteDialog={setDeleteDialog} showActionButtons />
      <Alert severity="info" variant="outlined" sx={{ my: '32px', display: 'inline-flex' }}>
        <AlertTitle>{t('general|Note')}:</AlertTitle>
        {t('general|Negative balance')} = {t('general|Number of pallets in credit')}
        <br />
        {t('general|Positive balance')} = {t('general|Number of pallets in debt')}
      </Alert>
      <Typography variant="body1" my="16px">
        {t(`editProfile|Pallet account(s) waiting for approval`)}
      </Typography>
      <PalletAccountTable accounts={waitingAccounts} setDeleteDialog={setDeleteDialog} />

      {showAddAccountForm ? (
        <form
          onSubmit={handleSubmit(data => onSubmit(data))}
          noValidate // disable scroll to field onSubmit validation error
        >
          <Box
            sx={{
              display: 'flex',
              gap: '16px',
              alignItems: 'flex-start',
              my: '16px',
            }}
          >
            <Controller
              name="palletAccountNumber"
              control={control}
              rules={{
                required: true,
                minLength: 5,
              }}
              render={({ field }) => (
                <TextField
                  {...field}
                  error={!!errors.palletAccountNumber}
                  helperText={errors.palletAccountNumber && t('general|labels|inputs|required')}
                  size="small"
                  fullWidth
                  sx={{
                    maxWidth: '393px',
                  }}
                  required
                  label={t(`editProfile|Pallet account number`)}
                />
              )}
            />

            <Box
              sx={{
                display: 'flex',
                justifyContent: 'flex-start',
                gap: '32px',
              }}
            >
              <Button
                disabled={!isValid}
                loading={loading}
                variant="contained"
                type="submit"
                color="primary"
                data-tracking="fcp-edit-print-settings-submit"
                dataTestId="fcp-edit-print-settings-submit-btn"
              >
                {t('general|Submit')}
              </Button>
            </Box>
            <IconButton
              size="small"
              color="error"
              sx={{
                '& svg': {
                  fontSize: '26px',
                },
              }}
              onClick={() => {
                reset();
                setShowAddAccountForm(false);
              }}
            >
              <Delete />
            </IconButton>
          </Box>
        </form>
      ) : (
        <Box>
          <Button
            sx={{ mt: '16px', mb: '16px', pt: '7px', pb: '6.5px' }}
            variant="contained"
            color="primary"
            startIcon={<AddOutlined />}
            disabled={showAddAccountForm}
            onClick={() => {
              setShowAddAccountForm(true);
            }}
            dataTestId="addAnotherPalletAccountBtn"
          >
            {t('editProfile|Add another Pallet account')}
          </Button>
        </Box>
      )}

      <ConfirmationDialog
        open={deleteDialog.open}
        title={t(`editProfile|You are about to remove account number`)}
        content={
          <Box sx={{ textAlign: 'center' }}>
            <Typography variant="body1">
              <strong className="color-DHL-red">{deleteDialog.palletAccount}</strong>
            </Typography>
            <Typography variant="body1">{t('general|Are you sure?')}</Typography>
          </Box>
        }
        onConfirm={() => {
          deleteAccount(deleteDialog);
        }}
        confirmLabel={t('general|Yes')}
        cancelLabel={t('general|No')}
        onClose={() => {
          setDeleteDialog({ open: false });
        }}
      />
      <ConfirmationDialog
        open={errorDialog.open}
        content={
          <Box sx={{ textAlign: 'center' }}>
            <Typography variant="body1">{errorDialog.message}</Typography>
          </Box>
        }
        onClose={() => {
          setErrorDialog({ open: false });
        }}
      />
    </>
  );
};
