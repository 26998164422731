import React, { Component } from 'react';
import { MyProvider, MyContext } from '../../context/context';
import AppTabs from '../AppTabs/AppTabs';
import { Alert, Box, Typography } from '@mui/material';

import './../../css/global/_variables.css';
// import './../../css/global/_icons.css';
// import './../../css/base/iconfont.css';
// import './../../css/base/react-datepicker.css';

import './AppQuoteTool.css';
import 'globals/utils/debugHelpers';
import { GeneralError } from 'components';
import cssVars from 'css-vars-ponyfill';
import { Translation } from 'react-i18next';
import '../../css/styles.css';
import { withQuote } from '../../useQuote';

const ENV_DEV = process.env.NODE_ENV === 'development';

class AppQuoteTool extends Component {
  componentDidMount() {
    cssVars();
  }

  render() {
    if (!this.props.useQuoteData.enableQuote) {
      return (
        <Translation>
          {t => (
            <Box
              sx={{
                maxWidth: '500px',
                mx: 'auto',
                my: '32px',
              }}
            >
              <Alert severity="error" variant="outlined">
                {t('general|errors|unableToAccessQuote')}
              </Alert>
            </Box>
          )}
        </Translation>
      );
    }
    return (
      <Translation>
        {t => (
          <div className={ENV_DEV ? 'l-view' : ''}>
            <div className="c-page-headline component-small has-rte component-margin frc__heading">
              <div className="c-page-headline--wrapper">
                <Typography variant="h1" mt={{ xs: 0, sm: 2 }}>
                  {t('general|pageTitles|quote')}
                </Typography>
              </div>
              <p className="has-rte">{t(`quoteTool|Get an instant price and estimated transit time.`)}</p>
            </div>
            <MyProvider>
              <div className="frc__app transparent-bg">
                <MyContext.Consumer>
                  {context => (
                    <>
                      <GeneralError context={context} message={t('general|errors|General error')} />
                      <AppTabs thankYouPage={this.onThankYouPage} />
                    </>
                  )}
                </MyContext.Consumer>
              </div>
            </MyProvider>
          </div>
        )}
      </Translation>
    );
  }
}

export default withQuote(AppQuoteTool);
