import { config, CONST } from '../../data-config';
import { apiLinks } from 'config/api-config';
import { apiRequest } from 'globals/utils/requests';
import { debounce } from 'globals/utils/debounce';
import { getCookie, eraseCookie } from 'globals/utils/requests';

export const addressAutoComplete = (params, context, companyAttribute) => {
  params.secondParam = {
    [companyAttribute]: params.value.length >= config.minLengthToPerformCompanySearch,
  };

  context.updateField(params);
};

export const getCityName = async (countryCode, postalCode, cityName = false) => {
  const resultMap = {};

  let url = apiLinks.fetchCityName;
  url = url.replace('{countryCode}', countryCode);
  url = url.replace('{postalCode}', postalCode);

  if (cityName) {
    url = `${url}/${cityName}`;
  }

  await apiRequest(url, 'GET')
    .then(result => {
      if (result.status === CONST.STATUS_OK) {
        resultMap.data = result.data;
      } else {
        resultMap.error = true;
      }
    })
    .catch(() => {
      resultMap.error = true;
    });

  return resultMap;
};

export const validateAddressBookCheckBox = state => {
  const stateToSet = {
    addToAddressBookReceiver: JSON.parse(JSON.stringify(state.addToAddressBookReceiver)),
  };

  const mandatoryAddressBookReceiverFieldsFilled =
    state.deliveryReceiver.value &&
    !state.deliveryReceiver.error &&
    state.deliveryStreet.value &&
    !state.deliveryStreet.error &&
    state.deliveryPostalCode.value &&
    !state.deliveryPostalCode.error &&
    state.deliveryCity.value &&
    !state.deliveryCity.error &&
    state.deliveryCountry.value &&
    !state.deliveryCountry.error;

  // making street / receiver mandatory or not
  if (!mandatoryAddressBookReceiverFieldsFilled) {
    // uncheck if add to address is already selected
    stateToSet.addToAddressBookReceiver.value = false;
  }

  return stateToSet;
};

export const enableDisableAddToAddressBookCheckBox = debounce(({ state, updateState }) => {
  const stateToSet = validateAddressBookCheckBox(state);

  updateState(stateToSet);
}, config.mandatoryAddressBookFieldsTimeoutms);

export const setPickupAddress = pickupAddress => {
  try {
    let phoneNumber;

    if (pickupAddress.phone) {
      phoneNumber = getPickupPhoneNumber(pickupAddress.phone);
    } else if (pickupAddress.phoneNumbers && pickupAddress.phoneNumbers[0] && pickupAddress.phoneNumbers[0].number) {
      phoneNumber = getPickupPhoneNumber(pickupAddress.phoneNumbers[0].number);
    }

    const state = {
      pickupCity: { error: false, value: pickupAddress.city || '', isRequired: true },
      pickupCountry: {
        error: false,
        value: pickupAddress.countryCode || '',
        comparisonError: false,
        isRequired: true,
      },
      pickupEmail: { error: false, value: pickupAddress.email || '', isRequired: false },
      pickupName: {
        error: false,
        value: pickupAddress.contactName || pickupAddress.lastName || pickupAddress.name || '',
        isRequired: true,
      },
      pickupPostalCode: { error: false, value: pickupAddress.postalCode || '', isRequired: true },
      pickupSender: { error: false, value: pickupAddress.companyName || pickupAddress.name || '', isRequired: true },
      pickupStreet: { error: false, value: pickupAddress.street || '', isRequired: true },
      confirmationEmail: { error: false, value: pickupAddress.email || '', isRequired: true },
    };

    if (phoneNumber) {
      state.pickupPhoneNumbers = phoneNumber;
    }

    if (pickupAddress.address) {
      state.pickupCity = { error: false, value: pickupAddress.address.cityName || '', isRequired: true };
      state.pickupPostalCode = { error: false, value: pickupAddress.address.postalCode || '', isRequired: true };
      state.pickupStreet = { error: false, value: pickupAddress.address.street || '', isRequired: true };
      state.pickupCountry = {
        error: false,
        value: pickupAddress.address.countryCode || '',
        comparisonError: false,
        isRequired: true,
      };
    }

    return state;
  } catch {
    return;
  }
};

const setDeliveryAddress = deliveryAddress => {
  const phoneNumber = deliveryAddress.phone
    ? [
        {
          code: deliveryAddress.phone.split('-')[0],
          error: false,
          isRequired: false,
          number: deliveryAddress.phone.split('-')[1] || '',
        },
      ]
    : [
        {
          code: config.defaultCountryPhoneCode,
          error: false,
          isRequired: true,
          number: '',
        },
      ];

  const state = {
    deliveryCity: { error: false, value: deliveryAddress.city || '', isRequired: true },
    deliveryCountry: {
      error: false,
      value: deliveryAddress.countryCode || '',
      comparisonError: false,
      isRequired: true,
    },
    deliveryEmail: { error: false, value: deliveryAddress.email || '', isRequired: false },
    deliveryName: { error: false, value: deliveryAddress.name, isRequired: false },
    deliveryPostalCode: { error: false, value: deliveryAddress.postalCode || '', isRequired: true },
    deliveryPhoneNumbers: phoneNumber,
    deliveryReceiver: { error: false, value: deliveryAddress.companyName || '', isRequired: false },
    deliveryStreet: { error: false, value: deliveryAddress.street || '', isRequired: false },
    deliveryReceiverReference: { value: deliveryAddress.reference, error: false },
    receiverPickedFromAddressBook: true,
    receiverAddressID: { value: deliveryAddress.id },
  };

  return state;
};

export const fillAddress = async () => {
  try {
    let pickupAddress;
    let deliveryAdress;
    let pickupCookieCall;
    let deliveryCookieCall;
    let address = {};

    const isPickupCookieAvailable = getCookie('pickupAddress');

    if (isPickupCookieAvailable) {
      pickupAddress = setPickupAddress(JSON.parse(decodeURIComponent(getCookie('pickupAddress'))));
    } else {
      pickupCookieCall = fillPickupAddress();
    }

    if (getCookie(config.bookingCookieName)) {
      deliveryCookieCall = fillDeliveryAddress();

      await deliveryCookieCall.then(result => {
        if (result.data) {
          deliveryAdress = setDeliveryAddress(result.data);
        }
      });
    }

    if (!isPickupCookieAvailable) {
      await pickupCookieCall.then(result => {
        if (result.data) {
          pickupAddress = setPickupAddress(result.data);
        }
      });
    }

    if (pickupAddress) {
      address = { ...address, ...pickupAddress };
    }

    if (deliveryAdress) {
      address = { ...address, ...deliveryAdress };
    }

    return address;
  } catch {
    return;
  }
};

export const fillPickupAddress = async () => {
  const resultMap = {};

  await apiRequest(apiLinks.getUserData, 'GET').then(result => {
    if (result.status === CONST.STATUS_OK && result.data && result.data.user) {
      resultMap.data = result.data.user;
    }
  });

  return resultMap;
};

export const fillDeliveryAddress = async () => {
  try {
    const resultMap = {};
    const url = apiLinks.searchUpdateAddress.replace('{id}', getCookie(config.bookingCookieName));

    await apiRequest(url, 'GET').then(result => {
      if (result.status === CONST.STATUS_OK) {
        resultMap.data = result.data;
      }
    });

    eraseCookie(config.bookingCookieName);

    return resultMap;
  } catch {
    return;
  }
};

export const clearDeliveryLocation = (context, returnClearedObject) => {
  const cityValidationError = JSON.parse(JSON.stringify(context.state.cityValidationError));
  delete cityValidationError['deliveryCity'];

  const obj = {
    deliveryReceiver: { value: '', error: false },
    deliveryEmail: { value: '', error: false },
    deliveryCountry: { value: config.defaultCountry, error: false },
    deliveryVatNumber: { value: '', error: false },
    deliveryName: { value: '', error: false },
    deliveryStreet: { value: '', error: false },
    deliveryPostalCode: { value: '', error: false },
    deliveryCity: { value: '', error: false },
    deliveryPhoneNumbers: [{ code: config.defaultCountryPhoneCode, number: '', error: '' }],
    deliveryReceiverReference: { value: '', error: false },
    cityValidationError: cityValidationError,
  };

  if (returnClearedObject) {
    return obj;
  } else {
    context.updateState(obj);
  }
};

export const postalCodeValidationAPIRequest = async state => {
  let urlValidation = apiLinks.postalCodeValidation;
  let body = {
    destinationAllowDeviating: true,
    destinationCity: state.deliveryCity.value.trim(),
    destinationCountryCode: state.deliveryCountry.value,
    destinationPostalCode: state.deliveryPostalCode.value,
    sourceAllowDeviating: true,
    sourceCity: state.pickupCity.value.trim(),
    sourceCountryCode: state.pickupCountry.value,
    sourcePostalCode: state.pickupPostalCode.value,
  };
  let resultMap = {
    source: false,
    destination: false,
  };

  await apiRequest(urlValidation, 'POST', {
    body: body,
  })
    .then(result => {
      if (result.status === CONST.STATUS_OK) {
        resultMap.source = result.data.source;
        resultMap.destination = result.data.destination;
      } else {
        resultMap.error = true;
      }
    })
    .catch(() => {
      resultMap.error = true;
    });

  return resultMap;
};

export const getPickupPhoneNumber = phoneNumber =>
  phoneNumber
    ? [
        {
          code: phoneNumber.split('-')[0],
          error: false,
          isRequired: true,
          number: phoneNumber.split('-')[1] || '',
        },
      ]
    : [
        {
          code: config.defaultCountryPhoneCode,
          error: false,
          isRequired: true,
          number: '',
        },
      ];
