import { config, CONST } from '../../data-config';
import { apiLinks } from 'config/api-config';
import getLanguage from 'globals/utils/getLanguage';
import { formatDate } from 'globals';
import { allCountries } from 'globals/data-countries';
import { returnProperValueOrNull } from 'globals/utils/fieldValidations';
import { apiRequest } from 'globals/utils/requests';
import { patchPALLPriceToFreightCost } from '../EnterShipmentDetails/EnterShipmentDetails-helpers';
import {
  DOMESTIC_SHIPMENT_RANGE,
  EXPORT_SHIPMENT_TYPE,
  IMPORT_SHIPMENT_TYPE,
  INTERNATIONAL_SHIPMENT_RANGE,
  RECEIVER_PAYER,
  SENDER_PAYER,
  THIRD_PARTY_PAYER,
} from '../../../globals/constants';
import { formatDateTime } from '../../../globals';

export const getAccountNumber = (routeState, deliveryOptionState) => {
  if (routeState.productsRangeOne.value) {
    return routeState.accountNumber.value;
  } else if (deliveryOptionState.selectedProduct.account) {
    return deliveryOptionState.selectedProduct.account;
  }
};

const formWhoPaysForShipmentAndAccountInfo = (routeState, deliveryOptionState) => {
  const accountNumber = getAccountNumber(routeState, deliveryOptionState);
  const payerModal = {
    whoPay: SENDER_PAYER,
    isSenderAccSelect: true,
    isOtherAccSelect: false,
    senderAccount: accountNumber,
  };

  switch (routeState.shipmentRange.value) {
    case INTERNATIONAL_SHIPMENT_RANGE:
      switch (routeState.shipmentTypesImpOrExp.value) {
        case EXPORT_SHIPMENT_TYPE:
          if (routeState.pickupAddressResidential.value) {
            payerModal.whoPay = RECEIVER_PAYER;
            payerModal.isSenderAccSelect = true;
            payerModal.senderAccount = accountNumber;
          }

          if (routeState.deliveryAddressResidential.value) {
            payerModal.whoPay = SENDER_PAYER;
            payerModal.isSenderAccSelect = true;
            payerModal.senderAccount = accountNumber;
          }

          break;
        case IMPORT_SHIPMENT_TYPE:
          payerModal.whoPay = RECEIVER_PAYER;
          payerModal.isOtherAccSelect = true;
          payerModal.otherAccount = accountNumber;

          if (routeState.deliveryAddressResidential.value) {
            payerModal.whoPay = THIRD_PARTY_PAYER;
            payerModal.isOtherAccSelect = true;
            payerModal.otherAccount = accountNumber;
          }

          break;
        default:
          break;
      }

      break;
    case DOMESTIC_SHIPMENT_RANGE:
      if (routeState.pickupAddressResidential.value) {
        payerModal.whoPay = RECEIVER_PAYER;
        payerModal.isOtherAccSelect = true;
        payerModal.otherAccount = accountNumber;
      }

      if (routeState.deliveryAddressResidential.value) {
        payerModal.whoPay = SENDER_PAYER;
        payerModal.isSenderAccSelect = true;
        payerModal.senderAccount = accountNumber;
      }

      break;
    default:
      break;
  }

  return payerModal;
};

export const saveDataAsTemplate2AndCallPortalOrder = async (props, reviewState) => {
  const routeState = props.routeContext.state;
  const shipmentState = props.shipmentContext.state;
  const deliveryOptionState = props.deliveryContext.state;
  const resultMap = {};

  const payload = {
    calculatedPrice: deliveryOptionState.quotePriceMap?.TotalPrice?.value,
    calculatedPriceCurrency: deliveryOptionState.quotePriceMap?.TotalPrice?.unit,
    status: CONST.IN_COMPLETE,
    templateName: new Date(),
    templateOrder: '',
    templateType: 2,
    importExport: routeState.shipmentTypesImpOrExp.value || EXPORT_SHIPMENT_TYPE,
    productID: deliveryOptionState.selectedProduct.code,
    route: routeState.shipmentRange.value,
    totalLDM: shipmentState?.totals?.shipmentDetailsRows?.loadingMeter?.value ?? '',
    sdifferent: false,
    sresidential: routeState.pickupAddressResidential.value,
    rresidential: routeState.deliveryAddressResidential.value,
    rdifferent: false,
    ...(!!deliveryOptionState?.pickupDate?.value && { pickupDate: deliveryOptionState.pickupDate.value }),
    // party
    savedShipmentParties: [
      {
        countryCode: routeState.pickupCountry.value,
        postalCode: routeState.pickupPostalCode.value,
        city: routeState.pickupCity.value,
        type: 'sender',
      },
      {
        countryCode: routeState.deliveryCountry.value,
        postalCode: routeState.deliveryPostalCode.value,
        city: routeState.deliveryCity.value,
        type: 'receiver',
      },
    ],
    // shipment details
    savedShipmentPieces: formShipmentData(shipmentState.shipmentDetailsRows),
    // additional services
    savedShipmentVass: formAdditionalServicesData(deliveryOptionState.additionalServices),
  };

  Object.assign(payload, formWhoPaysForShipmentAndAccountInfo(routeState, deliveryOptionState));

  if (routeState.shipmentRange.value === INTERNATIONAL_SHIPMENT_RANGE) {
    payload.termsOfDelivery = routeState.payerCode;
  }

  await apiRequest(apiLinks.savedShipments, 'POST', {
    body: payload,
  })
    .then(result => {
      if (result.status === CONST.STATUS_OK) {
        resultMap.data = result.data;
        resultMap.error = false;
      } else if (result.status === CONST.CONFLICT_ERROR) {
        resultMap.conflictError = true;
      } else {
        resultMap.error = true;
      }
    })
    .catch(() => {
      resultMap.error = true;
    });

  return resultMap;
};

const formAdditionalServicesData = additionalServices => {
  const savedShipmentVass = [];

  additionalServices
    .filter(vas => vas.value)
    .forEach(additionalService => {
      if (config.additionalServicesTransferredToPortalOrderWithoutData.indexOf(additionalService.code) !== -1) {
        savedShipmentVass.push({
          vas: additionalService.code,
          vasField: '',
        });
      } else {
        if (additionalService.groups && additionalService.groups.length > 0) {
          additionalService.groups.forEach((group, groupIndex) => {
            if (group.options) {
              group.options.forEach((optionList, optionIndex) => {
                if (optionList.length > 0) {
                  optionList.forEach(option => {
                    if (config.OptionFieldTypes.inputTypes[option.type] || config.OptionFieldTypes[option.type]) {
                      savedShipmentVass.push({
                        vas: additionalService.code,
                        vasField: option.optionCode,
                        vasFieldValue:
                          config.OptionFieldTypes.Telephone === option.type
                            ? option.value[0].code && option.value[0].number
                              ? option.value[0].code + '-' + option.value[0].number
                              : ''
                            : config.OptionFieldTypes.DateTime === option.type
                            ? formatDateTime(option.value)
                            : option.value,
                        groupIndex: groupIndex,
                        optionIndex: optionIndex,
                      });
                    }
                  });
                } else {
                  savedShipmentVass.push({
                    vas: additionalService.code,
                    vasField: '',
                  });
                }
              });
            }
          });
        } else {
          savedShipmentVass.push({
            vas: additionalService.code,
            vasField: '',
          });
        }
      }
    });

  return savedShipmentVass;
};

const formShipmentData = shipmentDetails => {
  const savedShipmentPieces = [];

  shipmentDetails.forEach(initialShipmentRow => {
    // Pallet places fix
    const shipmentRow = JSON.parse(JSON.stringify(initialShipmentRow));

    savedShipmentPieces.push({
      height: shipmentRow.height?.value || null,
      length: shipmentRow.length?.value || null,
      loadMeter: returnProperValueOrNull(shipmentRow.loadingMeter.value),
      quantity: shipmentRow.quantity?.value || null,
      type: shipmentRow.shipmentType.value,
      nonStackable: shipmentRow.nonStackable.value,
      volume: returnProperValueOrNull(shipmentRow.volume.value),
      weight: returnProperValueOrNull(shipmentRow.weight.value),
      width: shipmentRow.width?.value || null,
    });
  });

  return savedShipmentPieces;
};

const parseFloatAndReplaceComma = attribute => {
  return attribute.value ? attribute.value.replace(',', '.') * 1 : 0;
};

export const printEmailQuote = async (isPrint, body) => {
  const resultMap = {};
  let url = '';

  const params = {
    body: body,
    headers: {
      responseType: 'blob',
    },
  };

  if (isPrint) url = apiLinks.printQuote;
  else url = apiLinks.emailQuote;

  await apiRequest(url, 'POST', params)
    .then(result => {
      if (result.type) {
        resultMap.error = false;

        if (isPrint) {
          var file = new Blob([result], {
            type: result.type,
          });

          if (window.navigator && window.navigator.msSaveOrOpenBlob) {
            window.navigator.msSaveOrOpenBlob(file, 'Summary.pdf');
          } else {
            const newWindowWithPDFSummary = window.open();
            newWindowWithPDFSummary.location = URL.createObjectURL(file);
          }
        }
      } else {
        resultMap.error = true;
      }
    })
    .catch(() => {
      resultMap.error = true;
    });

  return resultMap;
};

export const formQuoteSummaryPrice = (quotePriceMap, additionalServices, selectedProduct) => {
  if (Object.keys(quotePriceMap).length === 0) {
    return quotePriceMap;
  }
  // get language for description
  const language = getLanguage(config.regEx.urlValidation);
  const additionalServicesPrice = [];
  let selectedAdditionalServices = [];

  selectedAdditionalServices =
    additionalServices.length > 0 ? additionalServices.filter(additionalService => additionalService.value) : [];

  selectedAdditionalServices &&
    selectedAdditionalServices.forEach(({ code, translatedName, calculatedResult }) => {
      additionalServicesPrice.push({
        name: translatedName,
        code: code,
        price: quotePriceMap[code] ? quotePriceMap[code].calculatedResult : calculatedResult,
      });
    });

  if (quotePriceMap.OwnSurChargePerc || quotePriceMap.OwnSurChargeAmount) {
    quotePriceMap.TotalPriceNoVATNOMarkup = adjustTotalIfMarkupPresented(quotePriceMap);
  } else quotePriceMap.TotalPriceNoVATNOMarkup = quotePriceMap.TotalPrice.calculatedResult;

  quotePriceMap.TotalPrice = quotePriceMap.TotalPrice.calculatedResult;
  quotePriceMap.additionalServices = additionalServicesPrice;

  if (selectedProduct.code === config.dhlPALL) {
    quotePriceMap.FreightCost = {};

    quotePriceMap.FreightCost.calculatedResult = `${patchPALLPriceToFreightCost(quotePriceMap)} ${config.currency}`;
  }

  quotePriceMap.surcharges = getSurcharges(quotePriceMap, language);

  return quotePriceMap;
};

// calculate Total Markup
const calculateTotalMarkup = quotePriceMap => {
  return (
    (quotePriceMap.OwnSurChargeAmount ? parseFloatAndReplaceComma(quotePriceMap.OwnSurChargeAmount) : 0) +
    (quotePriceMap.OwnSurChargePerc ? parseFloatAndReplaceComma(quotePriceMap.OwnSurChargePerc) : 0)
  );
};

// reduce markup from total if markup presented
const adjustTotalIfMarkupPresented = quotePriceMap => {
  const totalPriceNoVATNOMarkup =
    parseFloatAndReplaceComma(quotePriceMap.TotalPrice) - calculateTotalMarkup(quotePriceMap);

  const modifiedTotalPriceNoVANOMarkup =
    totalPriceNoVATNOMarkup.toFixed(config.price.maxDecimals).toString().replace('.', ',') +
    ' ' +
    quotePriceMap.TotalPrice.unit;

  return modifiedTotalPriceNoVANOMarkup;
};

export const noPriceAvailable = ({ quoteSummary }) => {
  if (
    quoteSummary?.FreightCost?.value === null ||
    quoteSummary?.FreightCost?.value === 0 ||
    !quoteSummary?.FreightCost
  ) {
    return true;
  }
  return false;
};

export const createSummaryEmailAndPrintData = (quoteSummary, routeState, shipmentState, deliveryOptionState, t) => {
  const dataObj = {};
  const currentDate = formatDate(new Date());

  dataObj.distanceLabel = t(`general|Distance`) + ':';
  dataObj.distance = quoteSummary.Distance ? quoteSummary.Distance.calculatedResult : '';
  dataObj.chargableWeightLabel = t(`general|Chargeable weight`) + ':';
  dataObj.chargableWeight = quoteSummary.ChargedWeight ? quoteSummary.ChargedWeight.calculatedResult : '';

  dataObj.fromCountryLabel = t('general|From') + ' ' + t(`general|Country`) + ':';
  dataObj.fromCountry = allCountries.find(country => country.code === routeState.pickupCountry.value).name;

  dataObj.fromZipCodeLabel = t('general|From') + ' ' + t('general|labels|inputs|Postalcode') + ':';
  dataObj.fromZipCode = routeState.pickupPostalCode.value;

  dataObj.toCountryLabel = t('general|To') + ' ' + t(`general|Country`) + ':';
  dataObj.toCountry = allCountries.find(country => country.code === routeState.deliveryCountry.value).name;

  dataObj.toZipCodeLabel = t('general|To') + ' ' + t('general|labels|inputs|Postalcode') + ':';
  dataObj.toZipCode = routeState.deliveryPostalCode.value;

  dataObj.residentalLabel = t('general|This is a residential address');
  dataObj.residentalOrigin = routeState.pickupAddressResidential.value;
  dataObj.residentalDestination = routeState.deliveryAddressResidential.value;

  dataObj.quoteType = getQuoteType(routeState, t);
  dataObj.quoteTypeLabel = t(`quoteTool|How do you want to get your quote?`);

  dataObj.quoteDateLabel = t(`quoteTool|Quote Date`);
  dataObj.quoteDate = currentDate;
  dataObj.accountNumberLabel = t(`general|Account Number`);
  dataObj.forAccountLabel = t(`general|For account`);
  dataObj.accountNumber = deliveryOptionState.selectedProduct.account;
  dataObj.serviceLabel = t(`quoteTool|Service`);

  dataObj.shipmentDetails = [];
  dataObj.shipmentDetailsLabel = t('general|Shipment Details');
  dataObj.shipmentTotalDetail = getShipmentTotalDetails(
    shipmentState.totals.shipmentDetailsRows,
    dataObj.chargableWeight,
    t,
  );
  dataObj.selectedProductName = t(`products|${deliveryOptionState.selectedProduct.code}|name`);

  dataObj.netPricePerAgreement = quoteSummary.FreightCost ? quoteSummary.FreightCost.calculatedResult : '';
  dataObj.netPricePerAgreementLabel = t('general|Net price per agreement');
  dataObj.additionalServices = JSON.parse(JSON.stringify(quoteSummary.additionalServices));
  dataObj.additionalServices = dataObj.additionalServices.map(item => {
    if (noPriceAvailable({ quoteSummary })) {
      return {
        ...item,
        price: t('general|errors|No price available online'),
      };
    }
    return item;
  });
  dataObj.additionalServicesLabel = t('general|Additional Services') + ':';
  dataObj.surcharges = getSurchargesInDisplayForm(quoteSummary, t);
  dataObj.surchargesLabel = t('general|Surcharges (price per surcharge)');

  if (noPriceAvailable({ quoteSummary })) {
    dataObj.totalPriceNoMarkupNoVAT = t('general|errors|No price available online');
  } else {
    dataObj.totalPriceNoMarkupNoVAT = quoteSummary.TotalPriceNoVATNOMarkup;
  }

  dataObj.totalPriceNoMarkupNoVATLabel = t('general|labels|metrics|Total price') + ' ' + t(`general|(excl. VAT)`) + '*';

  if (noPriceAvailable({ quoteSummary })) {
    dataObj.markupAmount = t('general|errors|No price available online');
  } else {
    dataObj.markupAmount = quoteSummary.OwnSurChargeAmount ? quoteSummary.OwnSurChargeAmount.calculatedResult : '---';
  }

  dataObj.markupAmountLabel = t(`quoteTool|Markup`);

  if (noPriceAvailable({ quoteSummary })) {
    dataObj.markupPercentage = t('general|errors|No price available online');
  } else {
    dataObj.markupPercentage = quoteSummary.OwnSurChargePerc ? quoteSummary.OwnSurChargePerc.calculatedResult : '---';
  }
  dataObj.markupPercentageLabel = t(`quoteTool|Markup percentage`);

  if (noPriceAvailable({ quoteSummary })) {
    dataObj.totalPriceWithMarkupNoVAT = t('general|errors|No price available online');
  } else {
    dataObj.totalPriceWithMarkupNoVAT = quoteSummary.TotalPrice ? quoteSummary.TotalPrice : '---';
  }

  dataObj.totalPriceWithMarkupNoVATLabel =
    t(`quoteTool|Total price including markup`) + ' ' + t(`general|(excl. VAT)`) + '*';

  if (noPriceAvailable({ quoteSummary })) {
    dataObj.vat = t('general|errors|No price available online');
  } else {
    dataObj.vat = quoteSummary.VAT ? quoteSummary.VAT.calculatedResult : '---';
  }
  dataObj.vatLabel = t('general|VAT');

  if (noPriceAvailable({ quoteSummary })) {
    dataObj.totalPriceWithMarkupAndVAT = t('general|errors|No price available online');
  } else {
    dataObj.totalPriceWithMarkupAndVAT = quoteSummary.TotalPriceIncVAT
      ? quoteSummary.TotalPriceIncVAT.calculatedResult
      : '---';
  }

  dataObj.totalPriceWithMarkupAndVATLabel = t(`quoteTool|Total price incl. VAT & Markup`);

  dataObj.disclaimer = t(`general|book price info`);

  dataObj.quoteSummaryLabel = t(`quoteTool|Quote Summary`);
  dataObj.routeLabel = t('general|Route');
  dataObj.deliveryOptionLabel = t(`general|Delivery Options`);
  dataObj.priceBreakdownLabel = t('general|Price Breakdown');

  // Return data object without spaces
  return JSON.parse(JSON.stringify(dataObj).replace(/\s/g, ' '));
};

const getSurchargesInDisplayForm = (quoteSummary, t) => {
  const surchargesToDisplay = [];

  quoteSummary.surcharges.forEach(surcharge => {
    let price = surcharge.calculatedResult;
    if (noPriceAvailable({ quoteSummary })) {
      price = t('general|errors|No price available online');
    }
    surchargesToDisplay.push({ name: surcharge.label, price });
  });
  return surchargesToDisplay;
};

const excludeSurchargesListWithSelectedAS = quotePriceMap => {
  const exclusionList = config.surchargesToExcludeFromDisplayingOnSummary;

  quotePriceMap.additionalServices && quotePriceMap.additionalServices.forEach(AD => exclusionList.push(AD.code));

  return exclusionList;
};

const getSurcharges = (quotePriceMap, language) => {
  const surcharges = [];
  const isDefaultLanguage = config.locale.defaultLanguage === language;
  const exclusionList = excludeSurchargesListWithSelectedAS(quotePriceMap);
  let newSurchargeItem;

  // exclude items from final quote summary
  const surchargeItems = getItemsWhichAreNotPresentinSecondArray(Object.keys(quotePriceMap), exclusionList);

  surchargeItems &&
    surchargeItems.forEach(surchargeItem => {
      if (quotePriceMap[surchargeItem].value) {
        newSurchargeItem = JSON.parse(JSON.stringify(quotePriceMap[surchargeItem]));
        newSurchargeItem.label = isDefaultLanguage ? newSurchargeItem.descriptionEng : newSurchargeItem.description;
        surcharges.push(newSurchargeItem);
      }
    });

  return surcharges;
};

const getQuoteType = (routeState, t) => {
  return routeState.productsRangeOne.value
    ? t(`quoteTool|Quote for`) +
        '  ' +
        (routeState.accountNumber.value !== '' ? routeState.accountNumber.value : '---') +
        '  ' +
        t(`quoteTool|with`) +
        '  ' +
        (routeState.selectedProduct.value !== ''
          ? config.serviceDescriptions[routeState.selectedProduct.value].header
          : '---')
    : t(`quoteTool|All Available Products`);
};

const getShipmentTotalDetails = (shipmentDetailsRows, chargedWeight, t) => {
  const shipmentTotals = {};

  shipmentTotals.totalQuantity = t('general|labels|metrics|Total Quantity') + ': ' + shipmentDetailsRows.quantity.value;
  shipmentTotals.totalVolume =
    t('general|labels|metrics|Total Volume') +
    ': ' +
    shipmentDetailsRows.volume.value +
    (shipmentDetailsRows.volume.value ? ' m3' : '');
  shipmentTotals.totalWeight = t('general|labels|metrics|Total Weight') + ': ' + shipmentDetailsRows.weight.value;
  shipmentTotals.totalLoadingMeter =
    t('general|labels|metrics|Total Loading Meter') + ': ' + shipmentDetailsRows.loadingMeter.value;
  shipmentTotals.chargeableWeight = t(`general|Chargeable weight`) + ' ' + chargedWeight;

  return shipmentTotals;
};

const getItemsWhichAreNotPresentinSecondArray = (array1, array2) => {
  return array1.filter(item => array2.indexOf(item) === -1);
};
