import React, { Component } from 'react';
import { Translation } from 'react-i18next';
import { DataTable } from 'components';
import PiecesCustomCell from './PiecesCustomCell/PiecesCustomCell';

export default class PiecesDetails extends Component {
  constructor(props) {
    super(props);

    this.state = {
      columns: [
        // {
        //   name: this.props.t(`general|ID`),
        //   selector: 'packageId',
        // },
        {
          name: this.props.t(`general|ID`),
          cell: row => <PiecesCustomCell cellValue={row} type="StatusWithID" />,
        },
        {
          name: this.props.t(`general|Dimension`),
          cell: row => <PiecesCustomCell cellValue={row} type="Dimension" />,
        },
        {
          name: this.props.t('general|labels|metrics|Weight'),
          cell: row => <PiecesCustomCell cellValue={row} type="Weight" />,
        },
        {
          name: this.props.t('general|Status'),
          cell: row => <PiecesCustomCell cellValue={row} type="Status" />,
        },
        {
          name: '',
          cell: row => <PiecesCustomCell cellValue={row} type="Track" />,
        },
      ],
    };
  }

  render() {
    return <Translation>{t => <DataTable columns={this.state.columns} data={this.props.data} />}</Translation>;
  }
}
