import { config, CONST } from '../../data-config';
import { apiLinks } from 'config/api-config';
import { apiRequest, getPropperAPIData } from 'globals/utils/requests';
import { checkFieldAndReturnState } from 'globals/utils/fieldValidations';
import { internationalLTCDeliveryTime } from '../EnterPickupLocation/EnterPickupLocation-helpers';
import { fixCommaInWeight, formatDate, getDateFromDateTimeString } from 'globals/utils/formatting';
import { additionalServicesOptionsValidations } from './Validations/AdditionalServices-validations';
import { validateGiroNumber } from 'globals/utils/giroNumberValidations';
import { setDisabledAndResetVas } from '../../../portal-order/components/DeliveryOptions/DeliveryOptions-helpers';
import { payerCodeValidation } from '../../../portal-order/components/EnterPickupLocation/Validations/AccountNumber-validations';
import {
  CONSIGNEE_CUSTOMER_TYPE,
  DOMESTIC_SHIPMENT_RANGE,
  INTERNATIONAL_SHIPMENT_RANGE,
  RECEIVER_PAYER,
  SERVICE_POINT_PRODUCT_CODE,
} from '../../../globals/constants';

export const deliveryOptionsReset = () => {
  return {
    pickupDate: { value: new Date(), error: false },
    selectedProduct: { code: '' },
    desiredPickupDates: [],
    additionalServices: [],
    dangerousGoodsLinkedIfNeeded: true,
    servicePointUseResidentialAddress: { value: false, disabled: false, isProductServicePointDelivery: false },
    chargeableWeight: '',
    additionalServicesError: false,
    additionalServicesGeneralError: false,
    deliveryMonitoringExpanded: false,
    returnProdStatus: false,
    selectProductError: false,

    notificationsError: false,
    notificationEmail: {
      value: '',
      error: false,
    },
    isb2cProduct: false,
    notificationsForReceiver: [
      {
        type: 'sms',
        value: false,
        input: '',
        name: 'Notify by SMS',
      },
      {
        type: 'email',
        value: false,
        input: '',
        name: 'Notify by Email',
      },
    ],
    servicePointId: '',
    selectedServicePoint: {
      id: '',
      street: '',
      name: '',
      cityName: '',
      postalCode: '',
      countryCode: null,
      distance: 0,
      routeDistance: 0,
      featureCodes: [],
      lat: 0,
      lng: 0,
    },
    servicePointsError: false,
    initialServicePointStatus: false,
    markedServicePoint: {},

    loaders: {
      products: { value: false },
      additionalServices: { value: false },
      shipmentDetails: { value: false },
    },

    servicePoints: [],
  };
};

export const postTimeTable = async (ctxState, dateTime) => {
  let resultMap = {};

  const state = getPropperAPIData(ctxState, ctxState.selectedServicePoint && ctxState.selectedServicePoint.cityName);

  const body = {
    dateTime: dateTime,
    countryCode: state.pickupCountry.value,
    postalCode: state.pickupPostalCode.value,
    deliveryCountryCode: state.deliveryCountry.value,
    deliveryPostalCode: state.deliveryPostalCode.value,
    earliestSent: true,
  };

  await apiRequest(apiLinks.postTimeTable, 'POST', {
    body: body,
  })
    .then(result => {
      if (result.status === CONST.STATUS_OK) {
        if (!(result.data.status && result.data.status === 'error')) resultMap = result;
        else resultMap.status = false;
      } else if (result.status === CONST.BAD_REQUEST_ERROR) {
        resultMap.status = false;
      } else {
        resultMap.error = true;
      }
    })
    .catch(() => {
      resultMap.error = true;
    });

  return resultMap;
};

export const getServicePoints = async (params, context, preSelectedLocationId, updateServicePt = false) => {
  const servicePoints = [];
  const payload = fixCommaInWeight(params);
  let stateToSet = {};

  context.updateState({ hereLoading: true });

  await apiRequest(apiLinks.getServicePoints, 'POST', {
    body: { ...payload },
  })
    .then(res => {
      if (res.status === CONST.STATUS_OK && res.data.servicePoints.length > 0) {
        res.data.servicePoints.forEach(async point => {
          servicePoints.push({
            ...point,
            routeDistance: point.routeDistance || Math.round(point.distance / 1000),
          });

          stateToSet = {
            doorStepDelivery: res.data.doorStepDelivery,
            doorStepDeliveryAvailable: res.data.doorStepDeliveryAvailable,
            servicePoints: servicePoints,
            initialServicePointStatus: true,
            hereLoading: false,
            servicePointsError: false,
          };

          if (preSelectedLocationId) {
            switch (preSelectedLocationId) {
              // use-residential is pre-selected
              case RECEIVER_PAYER:
                stateToSet.selectedServicePoint = getResidentialServicePoint(context.state);
                stateToSet.markedServicePoint = servicePoints[0];
                break;

              default:
                const preSelectedServicePoint = servicePoints.find(servicePt => servicePt.id === preSelectedLocationId);
                // IF pre-selected present return same ELSE return 1st asusual
                stateToSet.selectedServicePoint = preSelectedServicePoint || servicePoints[0];
                stateToSet.markedServicePoint = preSelectedServicePoint || servicePoints[0];
                break;
            }
          } else {
            stateToSet.selectedServicePoint = updateServicePt ? servicePoints[0] : context.state.selectedServicePoint;
            stateToSet.markedServicePoint = servicePoints[0];
          }
        });
      } else {
        stateToSet = {
          initialServicePointStatus: context.state.initialServicePointStatus,
          servicePointsError: true,
          hereLoading: false,
        };

        if (
          context.state.isb2cProduct &&
          !context.state.servicePointUseResidentialAddress.isProductServicePointDelivery
        ) {
          stateToSet.servicePointUseResidentialAddress = JSON.parse(
            JSON.stringify(context.state.servicePointUseResidentialAddress),
          );
          stateToSet.servicePointUseResidentialAddress.disabled = true;
          stateToSet.selectedServicePoint = {};
          const isResidential = getResidentialFlag(context.state);

          if (isResidential) {
            stateToSet.selectedServicePoint = getResidentialServicePoint(context.state);
          }
        }
      }
    })
    .catch(() => {
      console.error('Error in promise');

      stateToSet = {
        initialServicePointStatus: context.state.initialServicePointStatus,
        servicePointsError: true,
        hereLoading: false,
      };
    });

  const { additionalServices } = context.state;

  if (additionalServices && additionalServices.length > 0) {
    let modifiedVas = disableServicePointRelatedVasWhenSpecificFeatureCodeNotFound(
      stateToSet.selectedServicePoint,
      additionalServices,
    );

    if (stateToSet?.selectedServicePoint?.locationType === 'locker') {
      modifiedVas = setDisabledAndResetVas(modifiedVas, config.servicePointLockerDisabledVas);
    }

    if (modifiedVas) {
      stateToSet.additionalServices = modifiedVas;
    }
  }

  return stateToSet;
};

export const updateLTCDates = (matchedProducts, state, pickupDate, isDomestic) => {
  const additionalServices = JSON.parse(JSON.stringify(state.additionalServices));
  const newSelectedProduct = matchedProducts.find(prod => prod.code === state.selectedProduct.code);

  if (newSelectedProduct) {
    // Updates all LTCDate VAS options of the selected product
    if (additionalServices && additionalServices.length > 0) {
      additionalServices.forEach((additionalService, i) => {
        if (additionalService.groups && additionalService.groups.length > 0) {
          additionalService.groups.forEach((group, groupIndex) => {
            if (group.options && group.options.length > 0) {
              group.options.forEach((optionList, parentOptionIndex) => {
                optionList.forEach((option, ind) => {
                  if (option.type === config.OptionFieldTypes.LtcDate && !isDomestic) {
                    additionalServices[i].groups[groupIndex].options[parentOptionIndex][ind].value = '';
                    additionalServices[i].groups[groupIndex].options[parentOptionIndex][ind].values =
                      newSelectedProduct.LTCDates ? newSelectedProduct.LTCDates.map(itm => itm) : [];
                  }

                  // loading date
                  if (config.vasSpecialCaseForPickupDate.indexOf(additionalService.code) !== -1) {
                    additionalServices[i].groups[groupIndex].options[parentOptionIndex][ind].value = new Date(
                      pickupDate,
                    );
                  }

                  // unloading date
                  if (config.vasSpecialCaseForDeliveryDate.indexOf(additionalService.code) !== -1) {
                    const unloadingDate =
                      additionalServices[i].groups[groupIndex].options[parentOptionIndex][ind].value;

                    // if new selected pickup date greater than current unloading date; set new date to unloading
                    if (new Date(pickupDate).getTime() > new Date(unloadingDate).getTime()) {
                      additionalServices[i].groups[groupIndex].options[parentOptionIndex][ind].value = new Date(
                        pickupDate,
                      );
                    }
                  }
                });
              });
            }
          });
        }
      });
    }
  }

  return [additionalServices];
};

const getLTCProd = ltcResponse => {
  const ltcProd = {};

  ltcResponse.forEach(ltc => (ltcProd[ltc.product_code] = !!ltc.delivery_date));

  return ltcProd;
};

const isEurapidAvail = matchedProducts => {
  return matchedProducts.some(product => product.code === '233' && product.value);
};

const resetProduct = matchedProducts => {
  const eurapid = matchedProducts.find(product => product.code === '233');
  eurapid.value = false;
  eurapid.disabled = true;

  return { matchedProducts, additionalServices: [] };
};

export const getTimeTable = async (
  date,
  context,
  loaderRef = false,
  isPickupDateCalledFromStep3 = false,
  updateState = true,
) => {
  const state = getPropperAPIData(
    context.state,
    context.state.selectedServicePoint && context.state.selectedServicePoint.cityName,
  );
  let productTypeName = '';
  let productDeliveryDt = null;
  const stateToSet = {};

  const isDomestic = context.state.shipmentRange.value === DOMESTIC_SHIPMENT_RANGE;
  const matchedProducts = JSON.parse(JSON.stringify(context.state.matchedProducts));

  if (loaderRef) loaderRef.classList.add('is-loading');

  context.updateState({ additionalServicesError: false });

  // DOMESTIC
  if (isDomestic) {
    productDeliveryDt = await postTimeTable(context.state, formatDate(date));
    productTypeName = 'product';
  }
  // INTERNATIONAL
  else {
    let formURL =
      apiLinks.ltc.commitedLeadtimes +
      '?country_from=' +
      state.pickupCountry.value +
      '&zip_from=' +
      state.pickupPostalCode.value +
      '&country_to=' +
      state.deliveryCountry.value +
      '&zip_to=' +
      state.deliveryPostalCode.value +
      '&customer_pickup_date=' +
      formatDate(date) +
      '&_=' +
      new Date().getTime().toString();

    productDeliveryDt = await internationalLTCDeliveryTime(formURL);
    productTypeName = 'product_code';
  }

  if (productDeliveryDt && !productDeliveryDt.error && productDeliveryDt.status) {
    if (matchedProducts) {
      stateToSet.matchedProducts = addDeliveryDate(
        matchedProducts,
        productDeliveryDt.data,
        productTypeName,
        productDeliveryDt.hasOwnProperty('status') && productDeliveryDt.status,
      );

      // Update fixedDeliveryDate VAS LTCdate option on change of pickup date
      const ltcProd = getLTCProd(productDeliveryDt.data);

      if (
        context.state.selectedProduct &&
        context.state.selectedProduct.constructor === Object &&
        Object.keys(context.state.selectedProduct).length > 0
      ) {
        [stateToSet.additionalServices] = updateLTCDates(stateToSet.matchedProducts, context.state, date);
      }

      // reset vas only in 3rd step
      if (isPickupDateCalledFromStep3) {
        // euroconnect
        if (context.state.selectedProduct && context.state.selectedProduct === '202') {
          stateToSet.additionalServices = stateToSet.additionalServices
            ? resetVASIfFixedDeliveryPresent(stateToSet.additionalServices, context.state.ltcFailed || !ltcProd['ECE'])
            : resetVASIfFixedDeliveryPresent(
                context.state.additionalServices,
                context.state.ltcFailed || !ltcProd['ECE'],
              );
        }

        //eurapid
        if (!ltcProd['ERA'] && isEurapidAvail(matchedProducts, context.state)) {
          Object.assign(stateToSet, resetProduct());
        }
      } else {
        // step 4 - LTC call
        if (context.state.selectedProduct.code === '202') {
          //  ECE selected && no delivery date from LTC
          const oldAdditionalServices = stateToSet.additionalServices || context.state.additionalServices;

          stateToSet.additionalServices = oldAdditionalServices.map(oldStateVas => {
            if (config.vasExcludedForFailedLTC.indexOf(oldStateVas.code) !== -1) {
              // disable if any of notCompatible is checked else if ECE is not present
              oldStateVas.disabled =
                oldStateVas.notCompatibleWith.some(deleselect =>
                  context.state.additionalServices.some(opt => opt.code === deleselect && opt.value),
                ) || !ltcProd['ECE'];
            }

            return oldStateVas;
          });
        }

        // pre selected Eurapid - LTC not avail
        if (!ltcProd['ERA'] && isEurapidAvail(matchedProducts, context.state)) {
          const eurapidProd = stateToSet.matchedProducts.find(prod => prod.code === '233');
          // setting it to diabled false because we disable it in addDeliveryDate method
          eurapidProd.disabled = false;
        }
      }
    }

    stateToSet.ltcFailed = false;
  } else {
    // LTC failed
    stateToSet.ltcFailed = true;

    // set ltc date error
    stateToSet.matchedProducts = setDeliveryDateError(matchedProducts, isPickupDateCalledFromStep3);

    if (isPickupDateCalledFromStep3) {
      // product Eurapid is disabled
      if (isEurapidAvail(matchedProducts, context.state)) {
        Object.assign(stateToSet, resetProduct());
      } else {
        // perform reset on vas only when PU date called from step 3
        stateToSet.additionalServices = resetVASIfFixedDeliveryPresent(context.state.additionalServices, true);
      }
    } else if (context.state.selectedProduct.code && context.state.selectedProduct.code !== '233') {
      // LTC failed and selected prod !== Eurapid
      const eurapidProd = matchedProducts.find(prod => prod.code === '233');
      if (eurapidProd) {
        eurapidProd.disabled = true;
      }
    }
  }

  // Remove G Services if no time table
  stateToSet.priorityServiceDisabled = isDomestic && (productDeliveryDt.error || productDeliveryDt.status === false);

  // pre selected product - set dates
  if (
    !stateToSet.hasOwnProperty('selectedProduct') &&
    context.state.selectedProduct &&
    context.state.selectedProduct.code
  ) {
    stateToSet.selectedProduct = stateToSet.matchedProducts.find(
      product => product.code === context.state.selectedProduct.code,
    );
  }

  stateToSet.pickupDate = { value: date, error: false };

  if (updateState) {
    context.updateState(stateToSet);
  } else {
    return stateToSet;
  }

  if (loaderRef) {
    loaderRef.classList.remove('is-loading');
  }
};

const resetVASIfFixedDeliveryPresent = (additionalServices, disable) => {
  const modifiedVAS = JSON.parse(JSON.stringify(additionalServices));

  if (additionalServices.some(VAS => config.vasExcludedForFailedLTC.indexOf(VAS.code) !== -1)) {
    modifiedVAS.forEach(VAS => {
      VAS.value = false;
      VAS.disabled = false;

      if (config.vasExcludedForFailedLTC.indexOf(VAS.code) !== -1) {
        VAS.disabled = disable;
      }
    });
  }

  return modifiedVAS;
};

export const resetOptionsSchemaOnVasUnCheck = groups => {
  groups.forEach(group => {
    if (group.options && group.options.length > 0) {
      group.options.forEach(optionList => {
        optionList.forEach(option => {
          const resetOptionsSchema = config.resetOptionSchemaFieldTypes.indexOf(option.type) !== -1;
          const isListCOT = option.optionCode === config.OptionFieldTypes.ListCOT;

          if (resetOptionsSchema && !isListCOT) {
            option.value = '';
            option.error = false;
          } else if (resetOptionsSchema && isListCOT) {
            // reset to default terminal
            const defaultTerminal = option.values.find(
              listCotValue => listCotValue.terminal && listCotValue.terminal.defaultTerminal,
            );

            if (defaultTerminal) {
              option.value = defaultTerminal.value;
            }
            option.error = false;
          }
        });
      });
    }
  });

  return groups;
};

const setDeliveryDateError = (matchedProducts, isPickupDateCalledFromStep3 = false) => {
  if (matchedProducts && matchedProducts.length > 0) {
    matchedProducts.forEach(prod => {
      prod.deliveryDate = 'ltc_error';

      if (prod.code === '233' && isPickupDateCalledFromStep3) {
        prod.disabled = true;
        prod.value = false;
      }

      if (config.schedulePickup.indexOf(prod.code) !== -1) {
        prod.LTCDates = [];
      }
    });
  }

  return matchedProducts;
};

export const validateGeoLocation = async (state, additionalServices, productCode, getVas = false) => {
  let url = apiLinks.postGeoLocationValidator;
  const selectedServices = [];
  let resultMap = {};
  const body = {};

  state = getPropperAPIData(state);

  if (config.homeDeliveryMapping[productCode].deliveryType === 'Return') {
    body.cityName = state.pickupCity ? state.pickupCity.value : '';
    body.postalCode = state.pickupPostalCode ? state.pickupPostalCode.value : '';
  } else {
    body.cityName = state.deliveryCity ? state.deliveryCity.value : '';
    body.postalCode = state.deliveryPostalCode ? state.deliveryPostalCode.value : '';
  }

  body.deliveryType = config.homeDeliveryMapping[productCode].deliveryType;

  if (additionalServices && additionalServices.length > 0) {
    additionalServices.forEach(additionalService => {
      if (additionalService.value) selectedServices.push(additionalService.code);
    });
  }

  // getVas is get Home Delivery validator VAS.
  if (getVas) {
    url = apiLinks.postGeoLocation;
    body.AgreementNo = state.payerAccount;
  } else {
    body.AgreementNo = state.thirdPartyNumber.value || state.receiverNumber.value || state.accountNumber.value;
    body.additionalServices = selectedServices;
  }

  await apiRequest(url, 'POST', {
    body: body,
  })
    .then(result => {
      if (result.status === CONST.STATUS_OK && !(result.data.status && result.data.status === 'error')) {
        resultMap = result;
        resultMap.error = false;
      } else if (result.status === CONST.BAD_REQUEST_ERROR) {
        resultMap.status = false;
      } else {
        resultMap.error = true;
      }
    })
    .catch(() => {
      resultMap.error = true;
    });

  return resultMap;
};

export const updateServicePointToReceiverAddress = (params, props, servicePoint) => {
  const stateObj = {};
  const routeState = getPropperAPIData(props.context.state);

  stateObj.servicePointUseResidentialAddress = JSON.parse(
    JSON.stringify(props.context.state.servicePointUseResidentialAddress),
  );
  if (params.value) {
    stateObj.servicePointUseResidentialAddress.value = true;

    stateObj.selectedServicePoint = {
      id: RECEIVER_PAYER,
      street: routeState.deliveryStreet.value,
      name: routeState.deliveryReceiver.value,
      postalCode: routeState.deliveryPostalCode.value,
      cityName: routeState.deliveryCity.value,
      countryCode: routeState.deliveryCountry.value,
    };
  } else {
    stateObj.servicePointUseResidentialAddress.value = false;
    stateObj.selectedServicePoint =
      props.context.state.servicePoints.length > 0
        ? JSON.parse(JSON.stringify(props.context.state.servicePoints[0]))
        : {};
    if (servicePoint) {
      stateObj.selectedServicePoint = servicePoint;
    }
  }

  props.context.updateState(stateObj);
};

export const checkSubmit = async context => {
  let error = false;
  let homeDeliveryError = false;
  let stateToSet = {};
  const state = JSON.parse(JSON.stringify(context.state));

  if (context?.state?.shipmentRange?.value === INTERNATIONAL_SHIPMENT_RANGE) {
    ({ stateToSet, hasErrors: error } = payerCodeValidation(state, stateToSet, error));
  }

  if (context.state.selectedProduct.code) {
    const additionalServices = JSON.parse(JSON.stringify(context.state.additionalServices));
    let deliveryMonitoringExpanded = false;
    error = additionalServicesOptionsValidations(additionalServices);

    if (
      document.getElementById('frc_additional-services_delivery_monitoring') &&
      document.getElementById('frc_additional-services_delivery_monitoring').classList.contains('expand')
    ) {
      deliveryMonitoringExpanded = true;
    }

    if (context.state.returnProdStatus) {
      const getState = checkFieldAndReturnState({
        name: 'deliveryInstructions',
        value: context.state.deliveryInstructions.value,
        regEx: config.regEx.everything,
        lengthCheck: [RegExp(`[\\r\\n]{0,${config.maxInstructionsLength}}$`)],
        isRequired: false,
      });

      stateToSet.deliveryInstructions = { value: getState.value, error: getState.error };

      if (getState.error) error = true;
    }

    stateToSet.additionalServices = additionalServices;
    stateToSet.selectProductError = false;
    stateToSet.deliveryMonitoringExpanded = deliveryMonitoringExpanded;

    // Perform homedelivery check to validate access point
    if (config.homeDeliveryProductCodes.indexOf(context.state.selectedProduct.code) !== -1) {
      const validation = await validateGeoLocation(
        context.state,
        additionalServices,
        context.state.selectedProduct.code,
      );

      // replyStatus = 1 means VAS combination issue
      if (validation.error || validation?.data?.replyStatus !== 'Ok') {
        homeDeliveryError = true;
        stateToSet.additionalServicesError = true;
      } else {
        stateToSet.partyData = validation.data;
      }
    } else {
      stateToSet.partyData = null;
    }

    stateToSet.additionalServicesGeneralError = error;
    context.updateState(stateToSet);
  } else {
    error = true;
    document.getElementById('fcp-product-container').classList.add('has-pricelist-error');
    stateToSet = {
      selectProductError: true,
      additionalServicesGeneralError: error,
    };

    context.updateState(stateToSet);
  }

  return error || homeDeliveryError;
};

export const checkForDoorstepDeliveryLogicImplementation = (newStateToSet, selectedProduct) => {
  const doorstepDeliveryToBeSet = !!newStateToSet.doorStepDeliveryAvailable;
  const selectedProductAvailable = selectedProduct && selectedProduct.code;

  if (doorstepDeliveryToBeSet && selectedProductAvailable) {
    const productCode = selectedProduct.code;
    const checkProductCode = config.doorStepDeliveryToBeHadnledIfNotAvailable.indexOf(productCode) > -1;

    if (checkProductCode) {
      newStateToSet.doorStepVASAvailable = true;
      return;
    }
  }

  newStateToSet.doorStepVASAvailable = false;
};

export const isValidBankPlusGiroNumber = context => {
  let isValid = true;
  const COD = context.state.additionalServices.filter(item => item.code === 'cashOnDelivery' && item.value === true);

  if (COD.length !== 0 && COD[0] && COD[0].groups && COD[0].groups.length > 0) {
    COD[0].groups.forEach(group => {
      group.options.forEach(option => {
        if (isValid) {
          const bankGiroOption = option.optionCode === 'bankGiroNumber';
          const plusGiroOption = option.optionCode === 'plusGiroNumber';

          if (bankGiroOption && bankGiroOption.value) {
            isValid = !validateGiroNumber(bankGiroOption.value).isError;
            bankGiroOption.error = !isValid;
          }

          if (plusGiroOption && plusGiroOption.value) {
            isValid = !validateGiroNumber(plusGiroOption.value).isError;
            plusGiroOption.error = !isValid;
          }
        }
      });
    });
  }

  return isValid;
};

export const addDeliveryDate = (matchedProducts, productsWithDeliveryDate, productTypeName, status) => {
  if (matchedProducts && matchedProducts.length > 0) {
    matchedProducts.forEach(prod => {
      if (status) {
        const item = productsWithDeliveryDate.find(pdWDd => {
          return (
            (pdWDd[productTypeName] && pdWDd[productTypeName].toLowerCase()) ===
            (prod.product_code && prod.product_code.toLowerCase())
          );
        });

        if (item) {
          prod.deliveryDate = item.deliveryDate ? getDateFromDateTimeString(item.deliveryDate) : item.delivery_date;
          prod.product_features = item.product_features ? item.product_features : undefined;
          prod.LTCDates = item.available_fixed_delivery_dates || [];
          prod.possible_pickups = item.possible_pickups;
          prod.possible_pickups_holidays = item.possible_pickups_holidays;

          if (prod.code === '233' && prod.deliveryDate) {
            prod.disabled = false;
          }

          if (item.options !== undefined) {
            prod.priorityServices = item.options;
          }
        } else {
          prod.deliveryDate = 'ltc_error';
          prod.product_features = [];
          prod.LTCDates = [];
          prod.possible_pickups = [];
          prod.possible_pickups_holidays = [];

          if (prod.code === '233') {
            prod.disabled = true;
          }
        }
      } else {
        if (config.schedulePickup.indexOf(prod.code) !== -1) {
          prod.LTCDates = [];
          prod.deliveryDate = 'ltc_error';

          // CR-54 Ltc committed delivery date api failure
          if (prod.code === '233') {
            prod.disabled = true;
          }
        }
      }
    });
  }

  return matchedProducts;
};

export const getProductCodeForHDBasedOnVAS = ({ additionalServices, selectedProduct }) => {
  const productCodes = additionalServices
    .filter(vas => vas.value)
    .map(selectedVAS => selectedVAS.productCode)
    .filter((val, i, arr) => arr.indexOf(val) === i)
    .sort();

  // possible chances -> [401, 501] , [401], [501]
  return productCodes[0] || selectedProduct.code;
};

const getResidentialFlag = state => {
  let isResidential = false;

  const countrySpecificRules = state.deliveryTypesForParcelConnect[state.deliveryCountry.value];
  if (Array.isArray(countrySpecificRules)) {
    isResidential = countrySpecificRules.some(rule => rule.code.toLowerCase() === 'residential');
  }

  return isResidential;
};

export const isDomesticReturnProduct = productCode => {
  return config.useResidentialAddressDisableProducts.indexOf(productCode) !== -1;
};

export const getResidentialServicePoint = state => {
  const properState = getPropperAPIData(state);

  return {
    id: RECEIVER_PAYER,
    street: properState.deliveryStreet.value,
    name: properState.deliveryReceiver.value,
    postalCode: properState.deliveryPostalCode.value,
    cityName: properState.deliveryCity.value,
    countryCode: properState.deliveryCountry.value,
  };
};

export const servicePointCallAndFormDesiredPickupDate = async (context, preSelectedLocationId = false) => {
  let stateToSet = {};

  if (context.state.servicePoints.length === 0) {
    const matchedProductForPickupDates = context.state.matchedProducts.find(prod => {
      return config.desiredPickupDateMainProductCode === prod.code;
    });

    const isB2CProduct = context.state.matchedProducts.find(prod => {
      return config.b2cProducts.indexOf(prod.code) !== -1;
    });

    if (isB2CProduct && !context.state.selectedServicePoint.cityName) {
      const routeState = getPropperAPIData(context.state);
      const pieces = [];

      context.state.shipmentDetailsRows.forEach(row => {
        const obj = {
          length: row.length.value || null,
          height: row.height.value || null,
          weight: row.weight.value || null,
          width: row.width.value || null,
          numberOfPieces: row.quantity.value || null,
        };

        pieces.push(obj);
      });

      const params = {
        dhlProductCode:
          context.state.shipmentRange.value === INTERNATIONAL_SHIPMENT_RANGE ? '109' : SERVICE_POINT_PRODUCT_CODE,
        pieces: pieces,
        parties: [
          {
            address: {
              cityName: routeState.deliveryCity.value,
              countryCode: routeState.deliveryCountry.value,
              postalCode: routeState.deliveryPostalCode.value,
              street: routeState.deliveryStreet.value,
              streetNumber: null,
              locationType: null,
              additionalAddressInfo: null,
            },
            type: CONSIGNEE_CUSTOMER_TYPE,
          },
        ],
      };

      stateToSet = await getServicePoints(params, context, preSelectedLocationId, true);
    }

    // Apply dates from LTC only on International case
    if (
      matchedProductForPickupDates &&
      context.state.shipmentRange &&
      context.state.shipmentRange.value === INTERNATIONAL_SHIPMENT_RANGE
    ) {
      const desiredPickupDate = getDesiredPickDates(matchedProductForPickupDates, context);

      if (desiredPickupDate) {
        Object.assign(stateToSet, desiredPickupDate);
      }
    }
  }

  stateToSet.servicePointUseResidentialAddress = JSON.parse(
    JSON.stringify(context.state.servicePointUseResidentialAddress),
  );

  if (!isDomesticReturnProduct(context.state.selectedProduct.code)) {
    const servicePtAPISuccess =
      stateToSet.selectedServicePoint &&
      !stateToSet.servicePointsError &&
      stateToSet.servicePoints &&
      stateToSet.servicePoints.length > 0;

    // servicePointUseResidentialAddress disable fix based on residential value
    const isResidential = getResidentialFlag(context.state);
    stateToSet.servicePointUseResidentialAddress.disabled = !isResidential;
    // selectedServicePoint !== RECEIVER_PAYER, then it means selected service pt is from api
    if (servicePtAPISuccess && stateToSet.selectedServicePoint.id !== RECEIVER_PAYER) {
      stateToSet.servicePointUseResidentialAddress.value = false;
    } else if (servicePtAPISuccess && stateToSet.selectedServicePoint.id === RECEIVER_PAYER) {
      //selectedServicePoint === RECEIVER_PAYER; then selected address is TO address
      stateToSet.servicePointUseResidentialAddress.value = true;
    } else {
      //service point api failed; servicePointUseResidentialAddress is true only if the delivery country has 'residential'
      stateToSet.servicePointUseResidentialAddress.value = isResidential;
      stateToSet.servicePointUseResidentialAddress.disabled = true;
    }
  } else {
    stateToSet.servicePointUseResidentialAddress.value = false;
  }

  return stateToSet;
};

export const disableServicePointRelatedVasWhenSpecificFeatureCodeNotFound = (
  servicePoint,
  additionalServices,
  callFromServicePoint = false,
) => {
  if (
    servicePoint &&
    servicePoint.featureCodes &&
    !servicePoint.featureCodes.some(code => config.servicePointFeatureCode.indexOf(code) !== -1) &&
    additionalServices.some(VAS => config.servicePointDependentVas.indexOf(VAS.code) !== -1)
  ) {
    return resetVAS(additionalServices, config.servicePointDependentVas);
  }

  if (callFromServicePoint) {
    return resetVAS(additionalServices);
  }

  return additionalServices;
};

export const handleDoorStepAndVas = (state, doorStepVASAvailable) => {
  const isDoorStepAlreadySelected = state.additionalServices.find(
    additionalService => additionalService.code === 'doorstepDelivery' && additionalService.value,
  );
  const shouldResetVas =
    (isDoorStepAlreadySelected && !doorStepVASAvailable) || (!isDoorStepAlreadySelected && doorStepVASAvailable);

  if (shouldResetVas) {
    state.additionalServices = resetVAS(state.additionalServices);
  }
};

export const resetVAS = (additionalServices, vasesToBeGreyedOut = false) => {
  const modifiedVAS = JSON.parse(JSON.stringify(additionalServices));

  modifiedVAS.forEach(VAS => {
    VAS.value = false;
    VAS.calculatedResult = null;

    if (vasesToBeGreyedOut && vasesToBeGreyedOut.indexOf(VAS.code) !== -1) {
      VAS.disabled = true;
    } else {
      VAS.disabled = false;
    }

    if (VAS.groups && VAS.groups.length > 0) {
      VAS.groups = resetOptionsSchemaOnVasUnCheck(JSON.parse(JSON.stringify(VAS.groups)));
    }
  });

  return modifiedVAS;
};

const getDesiredPickDates = (product, context) => {
  const dates = [];

  if (product.possible_pickups && product.possible_pickups.length > 0) {
    const pickupDate = JSON.parse(JSON.stringify(context.state.pickupDate));
    const defaultDate = pickupDate.value.split('T');
    let getNextPossibleDate = product.possible_pickups.indexOf(defaultDate[0]) === -1;

    product.possible_pickups.forEach(date => {
      // Pushes date to the filter list
      dates.push(new Date(date));

      // check for next possible date if needed
      if (getNextPossibleDate && defaultDate[0] < date) {
        pickupDate.value = `${date}T${defaultDate[1]}`;
        getNextPossibleDate = false;
      }
    });

    context.updateState({ desiredPickupDates: dates, pickupDate });
  }
};
