import {
  Alert,
  Autocomplete,
  Box,
  Checkbox,
  FormControlLabel,
  IconButton,
  Link,
  TextField,
  Typography,
} from '@mui/material';
import { v4 as uuidv4 } from 'uuid';

import { ROUTES, useRouting } from 'router';

import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import { Controller, useForm } from 'react-hook-form';

import { BusinessOutlined, ContactPhoneOutlined, OpenInNew, PublicOutlined } from '@mui/icons-material';

import { Section } from 'components';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { globalConfig } from '../../../globals/data-config-global';
import { getPostalCodeRegex } from '../../../globals/helpers/postalcode';
import { fetchCity } from '../../../hooks/api/useGetCity';
import { useConfigCountries } from '../../../hooks/useConfigCoutries';
import { useLeavePage } from '../../../hooks/useLeavePage';
import { validateAccountNumber } from '../../../utils/accounts';
import { Button } from '../Button';
import { Captcha } from '../Captcha';
import { ConfirmationDialog } from '../ConfirmationDialog';
import PhoneCountryCode from '../PhoneCountryCode';
import { PROFILE_CONSTANTS } from '../edit-profile/constants';

export const PROFILE_FIELD_WIDTH = '600px';

export const ProfileFieldWrap = ({ children }) => {
  return <Box sx={{ my: '16px' }}>{children}</Box>;
};

export const SectionTitle = ({ icon: Icon, children, ...rest }) => {
  return (
    <Typography variant="h3" sx={{ display: 'flex', alignItems: 'center', mt: '10px', mb: '24px' }} {...rest}>
      {Icon ? <Icon sx={{ fontSize: '25px', mr: '12px' }} /> : null}
      {children}
    </Typography>
  );
};

export const ProfileForm = ({
  defaultValues,
  enableAccountCountryEdit,
  enableAccountEditing,
  enableEmailEdit,
  onAddAccount,
  onDeleteAccount,
  onSubmit,
  setSubmitSuccessMessage,
  showApprovedAccounts,
  showForgotAccountNumber,
  showRegistrationHints,
  showTermsAndCaptcha,
  submitError,
  submitInProgress = false,
  submitLabel,
  submitSuccessMessage,
  captcha,
  headerCountryCode,
}) => {
  const captchaToken = captcha?.captchaToken;

  const { t } = useTranslation();
  const { linkTo } = useRouting();

  const { accountCountries, companyCountries, companyPhoneCountryCodeOptions, urlCountry } = useConfigCountries();

  const [validatingPostCode, setValidatingPostCode] = useState(false);
  const [postCodeValidationResult, setPostCodeValidationResult] = useState();

  const [confirmationDialogData, setConfirmationDialogData] = useState({
    open: false,
  });

  const {
    control,
    handleSubmit,
    formState: { errors, isDirty },
    watch,
    setValue,
    getValues,
    setError,
    trigger,
    clearErrors,
    reset,
  } = useForm({
    mode: 'onSubmit',
    reValidateMode: 'onBlur',
    defaultValues: {
      // account country + accounts:
      accountCountry: null,
      accountList: [
        {
          id: uuidv4(),
          accountNumber: '',
          accountReferenceName: '',
          status: 'WAITING',
        },
      ],
      // company:
      company: '',
      companyVatNumber: '',
      companyStreetAddress: '',
      companyPostCode: '',
      companyCity: '',
      companyCountry: null,
      companyPhoneCountryCode: null,
      companyPhoneNumber: '',

      // contact:
      contactName: '',
      contactEmail: '',

      // terms of use:
      acceptTermsOfUse: false,
      captchaToken: undefined, // provided by captcha backend api
      captchaCode: '', // provided by user input
    },
  });
  const watchAccountList = watch('accountList');
  const watchAccountCountry = watch('accountCountry');
  const watchCompanyCountry = watch('companyCountry');
  const watchCompanyPhoneNumber = watch('companyPhoneNumber');
  const watchCompanyPostCode = watch('companyPostCode');
  const watchCompanyStreetAddress = watch('companyStreetAddress');
  const watchCompanyCity = watch('companyCity');
  const watchCompanyPhoneCountryCode = watch('companyPhoneCountryCode');

  const postalCodeMaxLength =
    watchCompanyCountry?.value?.toUpperCase() === 'IE'
      ? PROFILE_CONSTANTS.irelandCountyCharacterLimit
      : PROFILE_CONSTANTS.postalCodeCharacterLimit;

  useEffect(() => {
    if (!watchCompanyCity && !watchCompanyPostCode && !watchCompanyStreetAddress && watchAccountCountry) {
      setValue('companyCountry', watchAccountCountry);
    }
  }, [watchAccountCountry, setValue, watchCompanyCity, watchCompanyPostCode, watchCompanyStreetAddress]);

  useEffect(() => {
    if (!watchCompanyPhoneNumber && watchAccountCountry) {
      setValue(
        'companyPhoneCountryCode',
        companyPhoneCountryCodeOptions.find(item => {
          return item.code.toLowerCase() === watchAccountCountry.value.toLowerCase();
        }),
      );
    }
  }, [watchAccountCountry, setValue, watchCompanyPhoneNumber, companyPhoneCountryCodeOptions]);

  useEffect(() => {
    if (!watchCompanyPhoneCountryCode && watchCompanyCountry) {
      setValue(
        'companyPhoneCountryCode',
        companyPhoneCountryCodeOptions.find(item => {
          return item.code.toLowerCase() === watchCompanyCountry.value.toLowerCase();
        }),
      );
    }
  }, [watchCompanyPhoneCountryCode, watchCompanyCountry, setValue, companyPhoneCountryCodeOptions]);

  useEffect(() => {
    if (showTermsAndCaptcha) {
      setValue('captchaToken', captchaToken);
    }
  }, [setValue, captchaToken, showTermsAndCaptcha]);

  useEffect(() => {
    reset(defaultValues);
  }, [defaultValues, reset]);

  useEffect(() => {
    if (isDirty && setSubmitSuccessMessage && submitSuccessMessage) {
      setSubmitSuccessMessage(undefined);
    }
  }, [isDirty, setSubmitSuccessMessage, submitSuccessMessage]);

  useLeavePage(isDirty, 'Your registration is not finished. Do you want to leave?');

  const mapAccounts = (item, index) => {
    const fieldId = `accountNumber${item.id}`;
    const accountReferenceNameId = `accountReferenceName${item.id}`;

    return (
      <Box key={`${item.id}-${index}`}>
        <Box
          sx={{
            width: '100%',
            maxWidth: `calc(${PROFILE_FIELD_WIDTH} + 55px + ${item.newlyAddedAccount ? '83px' : '0px'})`,
            my: '16px',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              gap: '16px',
              alignItems: 'flex-start',
            }}
          >
            <TextField
              error={errors[fieldId] ? true : false}
              helperText={errors && errors[fieldId] ? errors[fieldId].message : undefined}
              value={item.accountNumber ?? ''}
              onChange={event => {
                const value = event.target.value;
                const cutOffValue =
                  value?.length > 0 ? value.slice(0, PROFILE_CONSTANTS.accountNumberCharacterLimit) : value;
                validateAccountNumber({
                  accountCountryCode: watchAccountCountry?.value?.toLowerCase(),
                  accountList: watchAccountList,
                  clearErrors,
                  fieldId,
                  id: item.id,
                  setError,
                  setValue,
                  t,
                  value: cutOffValue,
                });
              }}
              required
              label={t('registration|accountNumber')}
              sx={{
                width: '100%',
                maxWidth: '300px',
              }}
              disabled={!enableAccountEditing && !item.newlyAddedAccount}
              data-testid="registrationAccountNumber"
            />
            <TextField
              error={errors[accountReferenceNameId] ? true : false}
              helperText={errors && errors[accountReferenceNameId] ? errors[accountReferenceNameId].message : undefined}
              value={item.accountReferenceName}
              onChange={event => {
                const value = event.target.value;
                const newValue =
                  value?.length > 0 ? value.slice(0, PROFILE_CONSTANTS.accountReferenceNameCharacterLimit) : value;
                const newWatchAccountList = [...watchAccountList].map(account => {
                  if (account.id === item.id) {
                    return { ...account, accountReferenceName: newValue };
                  }
                  return account;
                });
                setValue('accountList', newWatchAccountList);
                clearErrors(accountReferenceNameId, { required: false });

                const valid = newValue.length <= PROFILE_CONSTANTS.accountReferenceNameCharacterLimit;
                if (!valid) {
                  setError(accountReferenceNameId, {
                    type: 'value',
                    message: t('general|errors|Invalid maxchar error', {
                      name: t('registration|accountReferenceNameOptional'),
                      max: PROFILE_CONSTANTS.accountReferenceNameCharacterLimit,
                    }),
                  });
                }
              }}
              label={t('registration|accountReferenceNameOptional')}
              sx={{
                width: '100%',
                maxWidth: '300px',
              }}
              disabled={!enableAccountEditing && !item.newlyAddedAccount}
              data-testid="registrationAccountReference"
            />
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                visibility:
                  watchAccountList.length <= 1 || (item.status === 'WAITING' && !item.newlyAddedAccount)
                    ? 'hidden'
                    : undefined,
                marginLeft: '-16px',
                marginTop: '3px',
              }}
            >
              <IconButton
                disabled={watchAccountList.length === 1}
                data-testid="registrationRemoveAccountBtn"
                size="large"
                color="error"
                sx={{
                  '& svg': {
                    fontSize: '26px',
                  },
                }}
                onClick={() => {
                  const deleteAction = () => {
                    setValue(
                      'accountList',
                      watchAccountList.filter(newItem => newItem.id !== item.id),
                    );
                    clearErrors(`accountNumber${item.id}`);
                    if (onDeleteAccount && !item.newlyAddedAccount) {
                      onDeleteAccount(item);
                    }
                  };
                  if (item.newlyAddedAccount) {
                    deleteAction();
                  } else {
                    setConfirmationDialogData({
                      ...item,
                      open: true,
                      onConfirm: () => {
                        deleteAction();
                      },
                    });
                  }
                }}
              >
                <DeleteIcon />
              </IconButton>
            </Box>
            {onAddAccount && item.newlyAddedAccount ? (
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  marginLeft: '-10px',
                }}
              >
                <Button
                  disabled={watchAccountList.length === 1 || Boolean(errors[fieldId])}
                  dataTestId="registrationSubmitBtn"
                  color="primary"
                  variant="contained"
                  sx={{
                    mt: '10px',
                    '& svg': {
                      fontSize: '26px',
                    },
                  }}
                  onClick={() => {
                    clearErrors(fieldId, { required: true });
                    const valid = validateAccountNumber({
                      accountCountryCode: watchAccountCountry?.value?.toLowerCase(),
                      accountList: watchAccountList,
                      clearErrors,
                      fieldId,
                      id: item.id,
                      setError,
                      setValue,
                      t,
                      value: item.accountNumber,
                    });
                    if (valid) {
                      onAddAccount({
                        account: item,
                        onSuccess: () => {
                          setValue(
                            'accountList',
                            watchAccountList.map(item => ({
                              ...item,
                              newlyAddedAccount: false,
                            })),
                          );
                        },
                      });
                    }
                  }}
                >
                  {t('general|Submit')}
                </Button>
              </Box>
            ) : null}
          </Box>
        </Box>
        {item.products?.length ? (
          <>
            <Typography variant="body1" mb="10px">
              {t('editProfile|Account products')}:
            </Typography>
            <Box
              component="ul"
              sx={{
                display: 'flex',
                flexWrap: 'wrap',
              }}
            >
              {item.products.map(product => {
                return (
                  <Box
                    key={product.productName}
                    component="li"
                    sx={{
                      flexBasis: '250px',
                      marginLeft: '20px',
                    }}
                  >
                    {t(`products|${product.productCode}|name`)}
                  </Box>
                );
              })}
            </Box>
          </>
        ) : null}
      </Box>
    );
  };

  const approvedAccounts = useMemo(
    () => watchAccountList.filter(item => item.status === 'APPROVED'),
    [watchAccountList],
  );
  const waitingAccounts = useMemo(() => watchAccountList.filter(item => item.status === 'WAITING'), [watchAccountList]);

  const resolvePostCodeValidation = useCallback(
    ({ responseFromPostalCode, validateCity, setError }) => {
      if (responseFromPostalCode?.data?.validated === false && !responseFromPostalCode?.data?.city) {
        if (getValues('companyCity')) {
          trigger('companyCity');
        }
        return t('general|errors|Incorrect Postal Code');
      } else if (responseFromPostalCode?.data?.validated === true) {
        clearErrors('companyPostCode', { required: true });
        if (
          !getValues('companyCity') &&
          !globalConfig.disableCityFetchCountryList.includes(getValues('companyCountry')?.value?.toUpperCase())
        ) {
          setValue('companyCity', responseFromPostalCode.data.city);
        }
        trigger('companyCity');
        return true;
      } else if (responseFromPostalCode?.data?.validated === false && responseFromPostalCode?.data?.city) {
        if (validateCity) {
          setTimeout(() => {
            setError('companyCity', {
              type: 'invalidCity',
            });
          }, 100);
          return t('general|errors|City API Error');
        }
        return false;
      }

      return true;
    },
    [clearErrors, getValues, setValue, t, trigger],
  );

  return (
    <form
      onSubmit={handleSubmit(data => {
        let allAccountsValid = true;
        watchAccountList.forEach(item => {
          const valid = validateAccountNumber({
            accountCountryCode: watchAccountCountry?.value?.toLowerCase(),
            accountList: watchAccountList,
            clearErrors,
            fieldId: `accountNumber${item.id}`,
            id: item.id,
            setError,
            setValue,
            t,
            value: item.accountNumber,
          });
          if (!valid) {
            allAccountsValid = false;
            return;
          }
        });
        if (allAccountsValid) {
          return onSubmit(data, setValue);
        }
      })}
      noValidate // disable scroll to field onSubmit validation error
    >
      <Section sx={{ mt: '64px', mb: '32px' }}>
        <SectionTitle icon={PublicOutlined}>{t('registration|selectAccountCountryTitle')}</SectionTitle>

        <Typography variant="body1">{t('registration|selectAccountCountryDescription')}</Typography>

        <ProfileFieldWrap>
          <Controller
            name="accountCountry"
            control={control}
            rules={{
              required: true,
            }}
            render={({ field }) => {
              return (
                <Autocomplete
                  {...field}
                  options={accountCountries}
                  onChange={(event, newValue) => {
                    field.onChange(newValue);
                    watchAccountList.forEach(item => {
                      if (item.accountNumber) {
                        validateAccountNumber({
                          accountCountryCode: newValue?.value?.toLowerCase(),
                          accountList: watchAccountList,
                          clearErrors,
                          fieldId: `accountNumber${item.id}`,
                          id: item.id,
                          setError,
                          setValue,
                          t,
                          value: item.accountNumber,
                        });
                      }
                    });
                  }}
                  getOptionLabel={option => t(`${option.translationKey}`)}
                  clearText=""
                  openText=""
                  isOptionEqualToValue={(option, value) => option.value === value.value}
                  sx={{ width: '100%', maxWidth: PROFILE_FIELD_WIDTH, my: '16px', mb: '32px' }}
                  disabled={!enableAccountCountryEdit}
                  renderInput={params => (
                    <TextField
                      {...params}
                      required
                      label={t('registration|accountCountry')}
                      data-testid="registrationAccountCountry"
                      error={!!errors.accountCountry}
                      helperText={errors.accountCountry && t('general|labels|inputs|required')}
                    />
                  )}
                />
              );
            }}
          />
        </ProfileFieldWrap>

        <>
          <ProfileFieldWrap>
            {showApprovedAccounts ? (
              <Box sx={{ bgcolor: '#fff', padding: '16px' }}>
                <SectionTitle>{t(`editProfile|DHL Freight account number(s) approved`)}</SectionTitle>
                {approvedAccounts.length ? approvedAccounts.map(mapAccounts) : '---'}
              </Box>
            ) : null}
            {showApprovedAccounts ? (
              <Box sx={{ bgcolor: '#fff', padding: '16px', mt: '32px' }}>
                <SectionTitle> {t(`editProfile|Account number(s) waiting for approval`)}</SectionTitle>
                {waitingAccounts.length ? waitingAccounts.map(mapAccounts) : '---'}
              </Box>
            ) : (
              <>{waitingAccounts.length ? waitingAccounts.map(mapAccounts) : '---'}</>
            )}
          </ProfileFieldWrap>
          <Box
            sx={{
              display: 'flex',
              gap: '32px',
              alignItems: 'center',
            }}
          >
            <Button
              variant="outlined"
              dataTestId="registrationAddAccountBtn"
              color="primary"
              startIcon={<AddIcon />}
              disabled={watchAccountList.filter(item => item.newlyAddedAccount).length > 0 && !enableAccountEditing}
              onClick={() => {
                setValue('accountList', [
                  ...watchAccountList,
                  {
                    accountNumber: '',
                    accountReferenceName: '',
                    status: 'WAITING',
                    newlyAddedAccount: true,
                    id: uuidv4(),
                  },
                ]);
              }}
            >
              {t('general|Add another TMS account')}
            </Button>
            {showRegistrationHints ? (
              <Typography variant="body2">{t('registration|additionalAccountsText')}</Typography>
            ) : null}
          </Box>

          {showForgotAccountNumber ? (
            <ProfileFieldWrap>
              <Link
                id="forgotAccountNumberBtn"
                href={t(`pageLinks|forgotten-account-page-link`)}
                sx={{ display: 'inline-flex', alignItems: 'center' }}
              >
                {t(`registration|I've forgotten my account number`)}
                <OpenInNew fontSize="16px" sx={{ ml: '0.75rem' }} />
              </Link>
            </ProfileFieldWrap>
          ) : null}
        </>
      </Section>

      <Box
        sx={{
          display: 'flex',
          gap: '32px',
          '& > *': {
            flex: '1 1 50%',
          },
        }}
      >
        <Section sx={{ my: '32px' }}>
          <SectionTitle icon={BusinessOutlined}>{t('registration|companyDetails')}</SectionTitle>
          <ProfileFieldWrap>
            <Controller
              name="companyCountry"
              control={control}
              rules={{
                required: true,
              }}
              render={({ field }) => {
                return (
                  <Autocomplete
                    {...field}
                    options={companyCountries}
                    onChange={(event, newValue) => {
                      field.onChange(newValue);
                    }}
                    clearText=""
                    getOptionLabel={option => t(`${option.translationKey}`)}
                    isOptionEqualToValue={(option, value) => option.value === value.value}
                    sx={{ width: '100%', maxWidth: PROFILE_FIELD_WIDTH, my: '16px' }}
                    renderInput={params => (
                      <TextField
                        {...params}
                        required
                        label={t('registration|companyCountry')}
                        data-testid="registrationCompanyCountry"
                        error={!!errors.companyCountry}
                        helperText={errors.companyCountry && t('general|labels|inputs|required')}
                      />
                    )}
                  />
                );
              }}
            />
          </ProfileFieldWrap>
          <ProfileFieldWrap>
            <Controller
              name="company"
              control={control}
              rules={{
                required: true,
                maxLength: PROFILE_CONSTANTS.companyCharacterLimit,
              }}
              render={({ field }) => {
                const getHelperText = () => {
                  if (errors.company?.type === 'maxLength') {
                    return t('general|errors|Invalid maxchar error', {
                      name: t('general|Company'),
                      max: PROFILE_CONSTANTS.companyCharacterLimit,
                    });
                  }
                  if (errors.company) {
                    return t('general|labels|inputs|required');
                  }
                  return undefined;
                };
                return (
                  <TextField
                    {...field}
                    required
                    label={t('general|Company')}
                    data-testid="registrationCompany"
                    sx={{
                      width: '100%',
                      maxWidth: PROFILE_FIELD_WIDTH,
                    }}
                    onChange={event => {
                      const targetValue = event.target.value;
                      const newValue =
                        targetValue?.length > 0
                          ? targetValue.slice(0, PROFILE_CONSTANTS.companyCharacterLimit)
                          : targetValue;
                      field.onChange(newValue);
                    }}
                    error={!!errors.company}
                    helperText={getHelperText()}
                  />
                );
              }}
            />
          </ProfileFieldWrap>
          <ProfileFieldWrap>
            <Controller
              name="companyVatNumber"
              control={control}
              rules={{
                required: true,
                pattern: globalConfig.regEx.everything,
                maxLength: PROFILE_CONSTANTS.vatNumberCharacterLimit,
              }}
              render={({ field }) => {
                const getError = () => {
                  if (errors.companyVatNumber) {
                    if (errors.companyVatNumber?.type === 'required') {
                      return t('general|labels|inputs|required');
                    }
                    if (errors.companyVatNumber?.type === 'maxLength') {
                      return t('general|errors|Invalid maxchar error', {
                        name: t('general|VAT Number'),
                        max: PROFILE_CONSTANTS.vatNumberCharacterLimit,
                      });
                    }
                    return t('general|labels|inputs|invalidFormat');
                  }
                  return null;
                };

                return (
                  <TextField
                    {...field}
                    required
                    label={t('general|VAT Number')}
                    data-testid="registrationVatNumber"
                    sx={{
                      width: '100%',
                      maxWidth: PROFILE_FIELD_WIDTH,
                    }}
                    onChange={event => {
                      const targetValue = event.target.value;
                      const newValue =
                        targetValue?.length > 0
                          ? targetValue.slice(0, PROFILE_CONSTANTS.vatNumberCharacterLimit)
                          : targetValue;
                      field.onChange(newValue);
                    }}
                    error={!!errors.companyVatNumber}
                    helperText={<>{getError()}</>}
                  />
                );
              }}
            />
          </ProfileFieldWrap>
          <ProfileFieldWrap>
            <Controller
              name="companyStreetAddress"
              control={control}
              rules={{
                required: true,
                maxLength: PROFILE_CONSTANTS.streetAddressCharacterLimit,
              }}
              render={({ field }) => {
                const getHelperText = () => {
                  if (errors.companyStreetAddress) {
                    if (errors.companyStreetAddress.type === 'maxLength') {
                      return t('general|errors|Invalid maxchar error', {
                        name: t('registration|streetAddress'),
                        max: PROFILE_CONSTANTS.streetAddressCharacterLimit,
                      });
                    }
                    return t('general|labels|inputs|required');
                  }
                  return undefined;
                };
                return (
                  <TextField
                    {...field}
                    required
                    label={t('registration|streetAddress')}
                    data-testid="registrationStreetAddress"
                    sx={{
                      width: '100%',
                      maxWidth: PROFILE_FIELD_WIDTH,
                    }}
                    onChange={event => {
                      const targetValue = event.target.value;
                      const newValue =
                        targetValue?.length > 0
                          ? targetValue.slice(0, PROFILE_CONSTANTS.streetAddressCharacterLimit)
                          : targetValue;
                      field.onChange(newValue);
                    }}
                    error={!!errors.companyStreetAddress}
                    helperText={getHelperText()}
                  />
                );
              }}
            />
          </ProfileFieldWrap>
          <ProfileFieldWrap>
            <Controller
              name="companyPostCode"
              control={control}
              rules={{
                required: true,
                pattern:
                  watchCompanyCountry?.value &&
                  getPostalCodeRegex({
                    countryCode: watchCompanyCountry?.value,
                    accountCountryCode: watchAccountCountry?.value,
                    strict: true,
                  }),
                maxLength: postalCodeMaxLength,
                validate: async (value, formValues) => {
                  try {
                    setValidatingPostCode(true);
                    const validateCity = formValues.companyCountry?.value?.toLowerCase() === 'se';
                    const responseFromPostalCode = await fetchCity({
                      countryCode: formValues.companyCountry?.value?.toUpperCase(),
                      postalCode: value,
                      cityName: validateCity ? formValues.companyCity : undefined,
                      useCustomCountryCode: true,
                      headerCountryCode: watchAccountCountry?.value || headerCountryCode,
                    });
                    setPostCodeValidationResult(responseFromPostalCode);
                    const isPostCodeValid = resolvePostCodeValidation({
                      responseFromPostalCode,
                      validateCity,
                      setError,
                    });

                    setValidatingPostCode(false);
                    return isPostCodeValid;
                  } catch (error) {
                    const message = t('general|errors|unableToFetchPostalCodeCity');
                    setError('companyCity', {
                      type: 'server',
                      message,
                    });
                    setValidatingPostCode(false);
                    return message;
                  }
                },
              }}
              render={({ field, fieldState, formState }) => {
                const getError = () => {
                  if (validatingPostCode) {
                    return (
                      <Box component="span" sx={{ position: 'absolute', top: '17px', right: '10px' }}>
                        {t('general|validating')}
                      </Box>
                    );
                  }
                  if (errors.companyPostCode) {
                    if (errors.companyPostCode?.type === 'maxLength') {
                      return t('general|errors|Invalid maxchar error', {
                        name: t('general|labels|inputs|Postalcode'),
                        max: postalCodeMaxLength,
                      });
                    } else if (errors.companyPostCode?.type === 'validate') {
                      return errors.companyPostCode.message;
                    } else if (errors.companyPostCode?.type === 'required') {
                      return t('general|labels|inputs|required');
                    } else if (errors.companyPostCode?.type === 'pattern') {
                      return t('general|labels|inputs|invalidFormat');
                    }
                    return t('general|errors|Incorrect Postal Code');
                  }
                  return null;
                };
                return (
                  <TextField
                    {...field}
                    onChange={event => {
                      const targetValue = event.target.value;
                      const newValue =
                        targetValue?.length > 0 ? targetValue.slice(0, postalCodeMaxLength) : targetValue;
                      field.onChange(newValue);
                    }}
                    onBlur={() => {
                      trigger('companyPostCode');
                    }}
                    required
                    label={
                      watchCompanyCountry?.value?.toUpperCase() === 'IE'
                        ? t('general|labels|inputs|County')
                        : t('general|labels|inputs|Postalcode')
                    }
                    data-testid="registrationPostalCode"
                    sx={{
                      width: '100%',
                      maxWidth: PROFILE_FIELD_WIDTH,
                    }}
                    error={!!errors.companyPostCode}
                    helperText={getError()}
                  />
                );
              }}
            />
          </ProfileFieldWrap>
          <ProfileFieldWrap>
            <Controller
              name="companyCity"
              control={control}
              rules={{
                required: true,
                maxLength: PROFILE_CONSTANTS.cityCharacterLimit,
              }}
              render={({ field }) => {
                const getError = () => {
                  if (validatingPostCode) {
                    return (
                      <Box
                        component="span"
                        sx={{
                          position: 'absolute',
                          top: '17px',
                          right: '10px',
                        }}
                      >
                        {t('general|validating')}
                      </Box>
                    );
                  }
                  if (errors.companyCity) {
                    if (errors.companyCity?.type === 'maxLength') {
                      return t('general|errors|Invalid maxchar error', {
                        name: t('general|labels|inputs|City'),
                        max: PROFILE_CONSTANTS.cityCharacterLimit,
                      });
                    }
                    if (errors.companyCity?.type === 'server') {
                      return t('general|errors|unableToFetchPostalCodeCity');
                    }
                    if (errors.companyCity?.type === 'required') {
                      return t('general|labels|inputs|required');
                    }
                    if (errors.companyCity?.type === 'invalidCity') {
                      return (
                        <span>
                          {t('general|errors|invalidCity')}{' '}
                          <Box
                            component="span"
                            sx={{ textDecoration: 'underline', cursor: 'pointer' }}
                            onClick={() => {
                              setValue('companyCity', postCodeValidationResult?.data.city);
                              trigger('companyCity');
                              trigger('companyPostCode');
                            }}
                          >
                            {postCodeValidationResult?.data.city}
                          </Box>
                          ?
                        </span>
                      );
                    }
                  }
                  return null;
                };

                return (
                  <TextField
                    {...field}
                    data-testid="registrationCity"
                    onChange={event => {
                      const targetValue = event.target.value;
                      const newValue =
                        targetValue?.length > 0
                          ? targetValue.slice(0, PROFILE_CONSTANTS.cityCharacterLimit)
                          : targetValue;
                      field.onChange(newValue);
                    }}
                    onBlur={e => {
                      if (watchCompanyCountry?.value?.toLowerCase() === 'se') {
                        const value = e.target.value;
                        if (
                          value === '' &&
                          postCodeValidationResult?.data?.city &&
                          !globalConfig.disableCityFetchCountryList.includes(watchCompanyCountry?.value?.toUpperCase())
                        ) {
                          setValue('companyCity', postCodeValidationResult.data.city);
                          trigger('companyCity');
                          trigger('companyPostCode');
                        } else if (value !== '') {
                          trigger('companyPostCode');
                        }
                      } else {
                        trigger('companyCity');
                      }
                    }}
                    required
                    label={t('general|labels|inputs|City')}
                    sx={{
                      width: '100%',
                      maxWidth: PROFILE_FIELD_WIDTH,
                    }}
                    error={!!errors.companyCity}
                    helperText={getError()}
                  />
                );
              }}
            />
          </ProfileFieldWrap>
        </Section>
        <Box>
          <Section sx={{ my: '32px' }}>
            <SectionTitle icon={ContactPhoneOutlined}>{t('registration|enterContactDetails')}</SectionTitle>

            <ProfileFieldWrap>
              <Controller
                name="contactName"
                control={control}
                rules={{
                  required: true,
                  maxLength: PROFILE_CONSTANTS.personNameCharacterLimit,
                }}
                render={({ field }) => {
                  const getHelperText = () => {
                    if (errors.contactName) {
                      if (errors.contactName.type === 'maxLength') {
                        return t('general|errors|Invalid maxchar error', {
                          name: t('registration|yourName'),
                          max: PROFILE_CONSTANTS.personNameCharacterLimit,
                        });
                      }
                      return t('general|labels|inputs|required');
                    }
                    return undefined;
                  };
                  return (
                    <TextField
                      {...field}
                      required
                      label={t('registration|yourName')}
                      data-testid="registrationYourName"
                      sx={{
                        width: '100%',
                        maxWidth: PROFILE_FIELD_WIDTH,
                      }}
                      onChange={event => {
                        const targetValue = event.target.value;
                        const newValue =
                          targetValue?.length > 0
                            ? targetValue.slice(0, PROFILE_CONSTANTS.personNameCharacterLimit)
                            : targetValue;
                        field.onChange(newValue);
                      }}
                      error={!!errors.contactName}
                      helperText={getHelperText()}
                    />
                  );
                }}
              />
            </ProfileFieldWrap>
            <ProfileFieldWrap>
              <Box
                sx={{
                  display: 'flex',
                  gap: '16px',
                  maxWidth: PROFILE_FIELD_WIDTH,
                }}
              >
                <Controller
                  name="companyPhoneCountryCode"
                  control={control}
                  rules={{
                    required: true,
                  }}
                  render={({ field }) => {
                    return (
                      <PhoneCountryCode
                        field={field}
                        label={t('registration|phoneNumberCountryCode')}
                        data-testid="registrationPhoneNumberCountryCode"
                        onChange={(event, newValue) => {
                          field.onChange(newValue);
                        }}
                        error={!!errors.companyPhoneCountryCode}
                        helperText={errors.companyPhoneCountryCode && t('general|labels|inputs|required')}
                        inputLabelProps={{ shrink: true }}
                        labelOverlapped={true}
                      />
                    );
                  }}
                />
                <Controller
                  name="companyPhoneNumber"
                  reg
                  control={control}
                  rules={{
                    required: true,
                    maxLength: PROFILE_CONSTANTS.companyPhoneNumberLimit,
                    /**
                   Matches the following allowed formats:
                    123 123 123
                    123-123 123
                    123-123123
                    208-422-2010
                    208.422.2010
                    208 422 2010
                    12-31 23 123
                    12-31-23-123
                    123.12.31.23
                    (1)2-31-23-123
                    (12)2-31-23-123
                    1/23 123 123
                   */
                    pattern: /^(((\d|\(\d{1,4}\))[ /.-]?)+(\d|\(\d{1,4}\)))*$/,
                  }}
                  render={({ field }) => {
                    const getError = () => {
                      if (errors.companyPhoneNumber) {
                        if (errors.companyPhoneNumber?.type === 'required') {
                          return t('general|labels|inputs|required');
                        }
                        if (errors.companyPhoneNumber?.type === 'pattern') {
                        }
                        return t('general|labels|inputs|invalidFormat');
                      }
                      return null;
                    };

                    return (
                      <TextField
                        {...field}
                        onChange={event => {
                          const targetValue = event.target.value;
                          const newValue =
                            targetValue?.length > 0
                              ? targetValue.slice(0, PROFILE_CONSTANTS.companyPhoneNumberLimit)
                              : targetValue;
                          const noSpacesValue = newValue?.replace(/\s/g, '');
                          field.onChange(noSpacesValue);
                        }}
                        required
                        label={t('registration|yourPhoneNumber')}
                        data-testid="registrationYourPhoneNumber"
                        sx={{
                          width: '100%',
                          maxWidth: PROFILE_FIELD_WIDTH,
                        }}
                        error={!!errors.companyPhoneNumber}
                        helperText={<>{getError()}</>}
                      />
                    );
                  }}
                />
              </Box>
            </ProfileFieldWrap>
            <ProfileFieldWrap>
              <Controller
                name="contactEmail"
                control={control}
                rules={{
                  required: true,
                  pattern: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
                  maxLength: PROFILE_CONSTANTS.emailCharacterLimit,
                }}
                render={({ field }) => {
                  const getError = () => {
                    if (errors.contactEmail) {
                      if (errors.contactEmail?.type === 'required') {
                        return t('general|labels|inputs|required');
                      }
                      if (errors.contactEmail?.type === 'pattern') {
                      }
                      return t('general|labels|inputs|invalidFormat');
                    }
                    return null;
                  };
                  return (
                    <TextField
                      {...field}
                      required
                      disabled={!enableEmailEdit}
                      label={t('registration|yourEmailUsername')}
                      data-testid="registrationYourEmail"
                      onChange={event => {
                        const targetValue = event.target.value;
                        const newValue =
                          targetValue?.length > 0
                            ? targetValue.slice(0, PROFILE_CONSTANTS.emailCharacterLimit)
                            : targetValue;
                        field.onChange(newValue);
                      }}
                      sx={{
                        width: '100%',
                        maxWidth: PROFILE_FIELD_WIDTH,
                      }}
                      error={!!errors.contactEmail}
                      helperText={getError()}
                    />
                  );
                }}
              />
            </ProfileFieldWrap>
            {showRegistrationHints ? (
              <Alert severity="info" variant="outlined">
                {urlCountry === 'fi'
                  ? t('registration|confirmationEmailShortDescription')
                  : t('registration|confirmationEmailDescription')}
              </Alert>
            ) : null}
          </Section>
        </Box>
      </Box>

      {showTermsAndCaptcha ? (
        <Section sx={{ my: '32px' }}>
          <Typography variant="h2">{t('general|Terms of Use')}</Typography>
          <ProfileFieldWrap>
            <Controller
              name="acceptTermsOfUse"
              control={control}
              rules={{
                required: true,
              }}
              render={({ field }) => (
                <FormControlLabel
                  control={<Checkbox {...field} checked={field.value} required size="large" />}
                  label={
                    <>
                      {t('general|I accept the DHL Freight')}{' '}
                      <Link href={linkTo(ROUTES.termsOfUse)} target="_blank" rel="noopener noreferrer">
                        {t('general|Terms of Use')}
                      </Link>
                    </>
                  }
                  data-testid="registrationTermsOfUse"
                  error={!!errors.acceptTermsOfUse ? 'true' : undefined}
                />
              )}
            />
          </ProfileFieldWrap>

          <Typography variant="body1">
            {t('general|If you would like to learn more about how DHL uses your personal data, please read our')}
            &nbsp;
            <Link href={linkTo(ROUTES.privacyPolicy)} target="_blank" rel="noopener noreferrer">
              {t('general|Privacy Notice')}
            </Link>
            .
          </Typography>
          <Section sx={{ bgcolor: theme => theme.palette.background.default, my: '32px' }}>
            <Box
              sx={{
                my: '32px',
              }}
            >
              <Captcha {...captcha} />
            </Box>
            <Box
              sx={{
                textAlign: 'center',
              }}
            >
              <Controller
                name="captchaCode"
                control={control}
                rules={{
                  required: true,
                }}
                render={({ field }) => (
                  <TextField
                    {...field}
                    required
                    label={t('general|Captcha code')}
                    data-testid="registrationCaptchaCode"
                    sx={{
                      width: '100%',
                      maxWidth: '300px',
                    }}
                    error={!!errors.captchaCode}
                    helperText={errors.captchaCode && t('general|labels|inputs|required')}
                  />
                )}
              />
            </Box>
          </Section>
        </Section>
      ) : null}
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'flex-end',
          gap: '32px',
          my: '32px',
        }}
      >
        {submitError ? <Alert severity="error">{submitError}</Alert> : null}
        {submitSuccessMessage && !isDirty ? <Alert severity="success">{submitSuccessMessage}</Alert> : null}
        {Object.keys(errors).length > 0 ? <Alert severity="error">{t('registration|invalidData')}</Alert> : null}
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <Button
            loading={submitInProgress}
            variant="contained"
            type="submit"
            color="primary"
            size="large"
            dataTestId="submit-form-btn"
          >
            {submitLabel}
          </Button>
        </Box>
      </Box>
      <ConfirmationDialog
        open={confirmationDialogData.open}
        title={t(`editProfile|You are about to remove account number`)}
        content={
          <Box sx={{ textAlign: 'center' }}>
            <Typography variant="body1">
              <strong className="color-DHL-red">{confirmationDialogData.accountNumber}</strong>
            </Typography>
            <Typography variant="body1">{t('general|Are you sure?')}</Typography>
          </Box>
        }
        onConfirm={confirmationDialogData.onConfirm}
        confirmLabel={t('general|Yes')}
        cancelLabel={t('general|No')}
        onClose={() => {
          setConfirmationDialogData({ open: false });
        }}
      />
    </form>
  );
};
