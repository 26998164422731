import React from 'react';
import { useTranslation } from 'react-i18next';
import './PriceBreakdown.css';
import { formatDate } from 'globals/utils/formatting';
import { noPriceAvailable } from '../../../../quote/components/ReviewQuote/ReviewQuote-helpers';

export default function PriceBreakdown({ quoteSummary }) {
  const checkValidPrice = price => {
    if (!price || price === 'null' || price === 0) {
      return '---';
    }
    return price;
  };

  const { t } = useTranslation();

  return (
    <div className=" l-grid--w-100pc-s frc__generic--field-wrapper ">
      <h4 className="margin-bottom-0 frc__red-lined-title">{t('general|Price Breakdown')}</h4>
      <div className="frc__shipment-details-row">
        <div className="frc__summary-row-section">
          <div className="js--fcp-summary-surcharges-section">
            <div className="l-grid frc__summary-line">
              <div className="l-grid--w-75pc-s ">{t(`general|date`)}</div>
              <div className="l-grid--w-25pc-s l-grid--right-s ">{formatDate(new Date())}</div>
            </div>
          </div>
        </div>
        <div className="frc__summary-row-section ">
          <div className="l-grid frc__summary-line">
            <div className="l-grid--w-75pc-s">
              <h5 className="frc__price-breakdown-title"> {t('general|Net price per agreement')}</h5>
            </div>
            <div className="l-grid--w-25pc-s l-grid--right-s ">
              <h5 className="frc__price-breakdown-title">
                {quoteSummary.FreightCost ? (
                  <>
                    {noPriceAvailable({ quoteSummary }) ? (
                      <>{t('general|errors|No price available online')}</>
                    ) : (
                      <>{checkValidPrice(quoteSummary.FreightCost.calculatedResult)}</>
                    )}
                  </>
                ) : (
                  '---'
                )}
              </h5>
            </div>
          </div>
        </div>
        {quoteSummary.additionalServices && quoteSummary.additionalServices.length > 0 && (
          <div className="frc__summary-row-section">
            <div className="js-fcp-summary-additional-services">
              <div className="frc__summary-row-section-title">
                <h5 className="frc__price-breakdown-title"> {t('general|Additional Services')}:</h5>
              </div>
              {quoteSummary.additionalServices.map((AD, index) => {
                return (
                  <div key={index} className="l-grid frc__summary-line">
                    <div className="l-grid--w-75pc-s js--fcp-summary--single-AS-name">
                      {t(`additionalServices|${AD.code}|name`)}
                    </div>
                    <div className="l-grid--w-25pc-s js--fcp-summary--single-AS-price l-grid--right-s">
                      {noPriceAvailable({ quoteSummary }) ? (
                        <>{t('general|errors|No price available online')}</>
                      ) : (
                        <>{checkValidPrice(AD.price)}</>
                      )}
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        )}
        <div className="frc__summary-row-section ">
          <div className="frc__summary-row-section-title">
            <h5 className="frc__price-breakdown-title"> {t('general|Surcharges (price per surcharge)')}</h5>
          </div>
          <div className="js--fcp-summary-surcharges-section">
            {quoteSummary.surcharges &&
              quoteSummary.surcharges.map((surcharge, index) => {
                let price = checkValidPrice(surcharge.calculatedResult);
                if (noPriceAvailable({ quoteSummary })) {
                  price = t('general|errors|No price available online');
                }
                return (
                  <div key={index} className="l-grid frc__summary-line">
                    <div className="l-grid--w-75pc-s ">{surcharge.label}</div>
                    <div className="l-grid--w-25pc-s l-grid--right-s ">{price}</div>
                  </div>
                );
              })}
          </div>
        </div>

        <div className="frc__summary-row-section ">
          <div className="l-grid frc__summary-line frc__summary-total-price">
            <div className="l-grid--w-75pc-s">
              <h4 className="frc__price-breakdown-title">
                {t('general|labels|metrics|Total price')} {t(`general|(excl. VAT)`)}*
              </h4>
            </div>
            <div className="l-grid--w-25pc-s l-grid--right-s">
              <h4 className="frc__price-breakdown-title">
                {' '}
                {noPriceAvailable({ quoteSummary }) ? (
                  <>{t('general|errors|No price available online')}</>
                ) : (
                  checkValidPrice(quoteSummary.TotalPriceNoVATNOMarkup)
                )}{' '}
              </h4>
            </div>
          </div>
          <div className="l-grid frc__summary-line">
            <div className="l-grid--w-75pc-s">{t('general|VAT')}</div>
            <div className="l-grid--w-25pc-s l-grid--right-s ">
              {noPriceAvailable({ quoteSummary }) ? (
                <>{t('general|errors|No price available online')}</>
              ) : (
                <>{quoteSummary.VAT ? checkValidPrice(quoteSummary.VAT.calculatedResult) : '---'}</>
              )}
            </div>
          </div>
          <div className="l-grid frc__summary-line">
            <div className="l-grid--w-75pc-s">{t(`portalOrder|Total price incl. VAT`)}</div>
            <div className="l-grid--w-25pc-s l-grid--right-s">
              <span className="l-grid--w-25pc-s l-grid--right-s ">
                {quoteSummary.TotalPriceIncVAT ? (
                  <>
                    {noPriceAvailable({ quoteSummary }) ? (
                      <>{t('general|errors|No price available online')}</>
                    ) : (
                      checkValidPrice(quoteSummary.TotalPriceIncVAT.calculatedResult)
                    )}
                  </>
                ) : (
                  '---'
                )}
              </span>
            </div>
          </div>
        </div>
      </div>
      <div className="l-grid--right-s">
        <p className="frc__detailed-information">{t(`general|book price info`)}</p>
      </div>
    </div>
  );
}
