import React from 'react';
import { useTranslation } from 'react-i18next';
import StatusCustomCell from '../../CustomCell/StatusCustomCell';
import { trackShipment } from '../../../ActionUtils';
import { formatBrowserDateTimeReadable } from 'globals/utils/formatting';

export default function PiecesCustomCell({ cellValue, type }) {
  const { t } = useTranslation();

  switch (type) {
    case 'StatusWithID':
      return (
        <div className="frc__custom-cell-line-break">
          <div>{cellValue.packageId || ''}</div>

          {cellValue.shipmentDescription && (
            <div className="">
              <br />
              <div>{t(`general|Actual Status`)}: </div>
              <div>
                <div>{t(`general|${cellValue.shipmentDescription}`)} </div>
                {cellValue.terminalCountry && cellValue.terminalName && (
                  <div>
                    <strong>
                      {cellValue.terminalCountry}-{cellValue.terminalName}
                    </strong>
                    &nbsp;
                    <i>{formatBrowserDateTimeReadable(cellValue.statusUpdated, cellValue.statusUpdatedHasTime)}</i>
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
      );

    case 'Dimension':
      return (
        <div className="frc__custom-cell-line-break">
          {cellValue.length || cellValue.width || cellValue.height ? (
            <div>
              {cellValue.length || '-'} {' x '} {cellValue.width || '-'} {' x '}
              {cellValue.height || '-'} {' ' + t(`general|cm`)}
            </div>
          ) : (
            '---'
          )}
        </div>
      );

    case 'Weight':
      return (
        <div className="frc__custom-cell-line-break">
          <div>
            {cellValue.weight || ''} {' ' + t(`general|kg`)}
          </div>
        </div>
      );

    case 'Status':
      return <StatusCustomCell cellValue={{ status: cellValue.status || '' }} t={t} />;

    case 'Track':
      return (
        <button
          id={`track${cellValue.packageId || ''}Btn`}
          className={'base-button ' + (!cellValue.packageId ? 'disabled' : '')}
          disabled={!cellValue.packageId}
          onClick={() => trackShipment(t, cellValue.packageId)}
        >
          {t('general|labels|buttons|Track')}
        </button>
      );

    default:
      return;
  }
}
