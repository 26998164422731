import React, { Component } from 'react';
import { Translation } from 'react-i18next';
import { config, CONST } from '../../data-config';

import { Checkbox, Error } from 'components';
import PayingAccountNumber from './PayingAccountNumber/PayingAccountNumber';
import { afterCheck, beforeCheck, callbackAfterUpdate, productAPICall } from './ServiceDetails-helpers';
import './ServiceDetails.css';
import { ChatWithUs } from '../../../components/fcp-components/ChatWithUs';

export default class ServiceDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      apiError: false,
    };
  }

  componentDidMount = async () => {
    this.setState({ isLoading: true }, async () => {
      const stateToSet = {};
      // product v1 call
      const productAPIBackendCall = await productAPICall(this.props.context);

      if (productAPIBackendCall.apiError) {
        stateToSet.apiError = true;
      }
      stateToSet.isLoading = false;
      this.setState(stateToSet);
    });
  };

  render() {
    return (
      <div className="frc__generic-section--wrapper">
        <Translation>
          {t => (
            <>
              {this.state.apiError ? (
                <Error
                  name="serviceDetailsApiError"
                  className="text-center"
                  message={
                    t(`general|Booking failed. Try again or call technical support.`) +
                    ' ' +
                    t('general|or') +
                    ' ' +
                    <ChatWithUs />
                  }
                />
              ) : (
                ''
              )}
              <h4 className="margin-bottom-1">{t('general|Service Details')}</h4>
              <div className="frc__service-details-section--wrapper">
                <div>
                  <p>{t('portalPickup|Please select the service(s) used for the shipment(s) in this pickup')}</p>
                  <div className="l-grid l-grid--w-100pc-s frc__generic-row--wrapper">
                    {this.props.context.state.dhlParti.allowed ? (
                      <div className="l-grid--w-50pc-s l-grid--w-100pc-s-s l-grid--w-30pc-m l-grid--w-50pc-m-s">
                        <Checkbox
                          context={this.props.context}
                          config={config}
                          CONST={CONST}
                          label={t('products|212|name')}
                          name="dhlParti"
                          isRequired={false}
                          checked={this.props.context.state.dhlParti.value}
                          deselect={[
                            'dhlPall',
                            'dhlStycke',
                            'dhlPaket',
                            'dhlParcelConnectPlus',
                            'dhlServicePoint',
                            'dhlServicePointRetur',
                            'dhlParcelConnect',
                            'dhlParcelReturnConnect',
                            'dhlHomeDelivery',
                            'dhlHomeDeliveryReturn',
                            'dhlEuroconnect',
                            'dhlEuroconnectPlus',
                            'dhlEurapid',
                          ]}
                          tooltip={t('products|212|tooltip')}
                          checkGroupIndex={1}
                          beforeUpdate={beforeCheck}
                          afterUpdate={(context, params) => afterCheck(context, params, t)}
                          callback={callbackAfterUpdate}
                        />
                      </div>
                    ) : (
                      ''
                    )}

                    {this.props.context.state.dhlPall.allowed ? (
                      <div className="l-grid--w-50pc-s l-grid--w-100pc-s-s l-grid--w-30pc-m l-grid--w-50pc-m-s">
                        <Checkbox
                          context={this.props.context}
                          config={config}
                          CONST={CONST}
                          label={t('products|210|name')}
                          name="dhlPall"
                          isRequired={false}
                          checked={this.props.context.state.dhlPall.value}
                          disabled={this.props.context.state.dhlParti.value}
                          tooltip={t('products|210|tooltip')}
                          checkGroupIndex={1}
                          beforeUpdate={beforeCheck}
                          afterUpdate={(context, params) => afterCheck(context, params, t)}
                          callback={callbackAfterUpdate}
                        />
                      </div>
                    ) : (
                      ''
                    )}

                    {this.props.context.state.dhlStycke.allowed ? (
                      <div className="l-grid--w-50pc-s l-grid--w-100pc-s-s l-grid--w-30pc-m l-grid--w-50pc-m-s">
                        <Checkbox
                          context={this.props.context}
                          config={config}
                          CONST={CONST}
                          label={t('products|211|name')}
                          name="dhlStycke"
                          isRequired={false}
                          checked={this.props.context.state.dhlStycke.value}
                          disabled={this.props.context.state.dhlParti.value}
                          tooltip={t('products|211|tooltip')}
                          checkGroupIndex={1}
                          beforeUpdate={beforeCheck}
                          afterUpdate={(context, params) => afterCheck(context, params, t)}
                          callback={callbackAfterUpdate}
                        />
                      </div>
                    ) : (
                      ''
                    )}

                    {this.props.context.state.dhlPaket.allowed ? (
                      <div className="l-grid--w-50pc-s l-grid--w-100pc-s-s l-grid--w-30pc-m l-grid--w-50pc-m-s">
                        <Checkbox
                          context={this.props.context}
                          config={config}
                          CONST={CONST}
                          label={t('products|102|name')}
                          name="dhlPaket"
                          isRequired={false}
                          checked={this.props.context.state.dhlPaket.value}
                          disabled={this.props.context.state.dhlParti.value}
                          tooltip={t('products|102|tooltip')}
                          checkGroupIndex={1}
                          beforeUpdate={beforeCheck}
                          afterUpdate={(context, params) => afterCheck(context, params, t)}
                          callback={callbackAfterUpdate}
                        />
                      </div>
                    ) : (
                      ''
                    )}

                    {this.props.context.state.dhlParcelConnectPlus.allowed ? (
                      <div className="l-grid--w-50pc-s l-grid--w-100pc-s-s l-grid--w-30pc-m l-grid--w-50pc-m-s">
                        <Checkbox
                          context={this.props.context}
                          config={config}
                          CONST={CONST}
                          label={t('products|112|name')}
                          name="dhlParcelConnectPlus"
                          isRequired={false}
                          checked={this.props.context.state.dhlParcelConnectPlus.value}
                          disabled={this.props.context.state.dhlParti.value}
                          tooltip={t('products|112|tooltip')}
                          checkGroupIndex={1}
                          beforeUpdate={beforeCheck}
                          afterUpdate={(context, params) => afterCheck(context, params, t, false)}
                          callback={callbackAfterUpdate}
                        />
                      </div>
                    ) : (
                      ''
                    )}

                    {this.props.context.state.dhlServicePoint.allowed ? (
                      <div className="l-grid--w-50pc-s l-grid--w-100pc-s-s l-grid--w-30pc-m l-grid--w-50pc-m-s">
                        <Checkbox
                          context={this.props.context}
                          config={config}
                          CONST={CONST}
                          label={t('products|103|name')}
                          name="dhlServicePoint"
                          isRequired={false}
                          checked={this.props.context.state.dhlServicePoint.value}
                          disabled={this.props.context.state.dhlParti.value}
                          tooltip={t('products|103|tooltip')}
                          checkGroupIndex={1}
                          beforeUpdate={beforeCheck}
                          afterUpdate={(context, params) => afterCheck(context, params, t)}
                          callback={callbackAfterUpdate}
                        />
                      </div>
                    ) : (
                      ''
                    )}

                    {this.props.context.state.dhlParcelConnect.allowed ? (
                      <div className="l-grid--w-50pc-s l-grid--w-100pc-s-s l-grid--w-30pc-m l-grid--w-50pc-m-s">
                        <Checkbox
                          context={this.props.context}
                          config={config}
                          CONST={CONST}
                          label={t('products|109|name')}
                          name="dhlParcelConnect"
                          isRequired={false}
                          checked={this.props.context.state.dhlParcelConnect.value}
                          disabled={this.props.context.state.dhlParti.value}
                          tooltip={t('products|109|tooltip')}
                          checkGroupIndex={1}
                          beforeUpdate={beforeCheck}
                          afterUpdate={(context, params) => afterCheck(context, params, t, false)}
                          callback={callbackAfterUpdate}
                        />
                      </div>
                    ) : (
                      ''
                    )}

                    {this.props.context.state.dhlServicePointRetur.allowed ? (
                      <div className="l-grid--w-50pc-s l-grid--w-100pc-s-s l-grid--w-30pc-m l-grid--w-50pc-m-s">
                        <Checkbox
                          context={this.props.context}
                          config={config}
                          CONST={CONST}
                          label={t('products|104|name')}
                          name="dhlServicePointRetur"
                          isRequired={false}
                          checked={this.props.context.state.dhlServicePointRetur.value}
                          disabled={this.props.context.state.dhlParti.value}
                          tooltip={t('products|104|tooltip')}
                          checkGroupIndex={1}
                          beforeUpdate={beforeCheck}
                          afterUpdate={(context, params) => afterCheck(context, params, t)}
                          callback={callbackAfterUpdate}
                        />
                      </div>
                    ) : (
                      ''
                    )}

                    {this.props.context.state.dhlParcelReturnConnect.allowed ? (
                      <div className="l-grid--w-50pc-s l-grid--w-100pc-s-s l-grid--w-30pc-m l-grid--w-50pc-m-s">
                        <Checkbox
                          context={this.props.context}
                          config={config}
                          CONST={CONST}
                          label={t('products|107|name')}
                          name="dhlParcelReturnConnect"
                          isRequired={false}
                          checked={this.props.context.state.dhlParcelReturnConnect.value}
                          disabled={this.props.context.state.dhlParti.value}
                          tooltip={t('products|107|tooltip')}
                          checkGroupIndex={1}
                          beforeUpdate={beforeCheck}
                          afterUpdate={(context, params) => afterCheck(context, params, t)}
                          callback={callbackAfterUpdate}
                        />
                      </div>
                    ) : (
                      ''
                    )}

                    {this.props.context.state.dhlHomeDelivery.allowed ? (
                      <div className="l-grid--w-50pc-s l-grid--w-100pc-s-s l-grid--w-30pc-m l-grid--w-50pc-m-s">
                        <Checkbox
                          context={this.props.context}
                          config={config}
                          CONST={CONST}
                          label={t('products|401|name')}
                          name="dhlHomeDelivery"
                          isRequired={false}
                          checked={this.props.context.state.dhlHomeDelivery.value}
                          disabled={this.props.context.state.dhlParti.value}
                          tooltip={t('products|401|tooltip')}
                          checkGroupIndex={1}
                          beforeUpdate={beforeCheck}
                          afterUpdate={(context, params) => afterCheck(context, params, t)}
                          callback={callbackAfterUpdate}
                        />
                      </div>
                    ) : (
                      ''
                    )}

                    {this.props.context.state.dhlHomeDeliveryReturn.allowed ? (
                      <div className="l-grid--w-50pc-s l-grid--w-100pc-s-s l-grid--w-30pc-m l-grid--w-50pc-m-s">
                        <Checkbox
                          context={this.props.context}
                          config={config}
                          CONST={CONST}
                          label={t('products|402|name')}
                          name="dhlHomeDeliveryReturn"
                          isRequired={false}
                          checked={this.props.context.state.dhlHomeDeliveryReturn.value}
                          disabled={this.props.context.state.dhlParti.value}
                          tooltip={t('products|402|tooltip')}
                          checkGroupIndex={1}
                          beforeUpdate={beforeCheck}
                          afterUpdate={(context, params) => afterCheck(context, params, t)}
                          callback={callbackAfterUpdate}
                        />
                      </div>
                    ) : (
                      ''
                    )}

                    {this.props.context.state.dhlEuroconnect.allowed ? (
                      <div className="l-grid--w-50pc-s l-grid--w-100pc-s-s l-grid--w-30pc-m l-grid--w-50pc-m-s">
                        <Checkbox
                          context={this.props.context}
                          config={config}
                          CONST={CONST}
                          label={t('products|202|name')}
                          name="dhlEuroconnect"
                          isRequired={false}
                          checked={this.props.context.state.dhlEuroconnect.value}
                          disabled={this.props.context.state.dhlParti.value}
                          tooltip={t('products|202|tooltip')}
                          checkGroupIndex={1}
                          beforeUpdate={beforeCheck}
                          afterUpdate={(context, params) => afterCheck(context, params, t)}
                          callback={callbackAfterUpdate}
                        />
                      </div>
                    ) : (
                      ''
                    )}

                    {this.props.context.state.dhlEuroconnectPlus.allowed ? (
                      <div className="l-grid--w-50pc-s l-grid--w-100pc-s-s l-grid--w-30pc-m l-grid--w-50pc-m-s">
                        <Checkbox
                          context={this.props.context}
                          config={config}
                          CONST={CONST}
                          label={t('products|232|name')}
                          name="dhlEuroconnectPlus"
                          isRequired={false}
                          checked={this.props.context.state.dhlEuroconnectPlus.value}
                          disabled={this.props.context.state.dhlParti.value}
                          tooltip={t('products|232|tooltip')}
                          checkGroupIndex={1}
                          beforeUpdate={beforeCheck}
                          afterUpdate={(context, params) => afterCheck(context, params, t)}
                          callback={callbackAfterUpdate}
                        />
                      </div>
                    ) : (
                      ''
                    )}

                    {this.props.context.state.dhlEurapid.allowed ? (
                      <div className="l-grid--w-50pc-s l-grid--w-100pc-s-s l-grid--w-30pc-m l-grid--w-50pc-m-s">
                        <Checkbox
                          context={this.props.context}
                          config={config}
                          CONST={CONST}
                          label={t('products|233|name')}
                          name="dhlEurapid"
                          isRequired={false}
                          checked={this.props.context.state.dhlEurapid.value}
                          disabled={this.props.context.state.dhlParti.value}
                          tooltip={t('products|233|tooltip')}
                          checkGroupIndex={1}
                          beforeUpdate={beforeCheck}
                          afterUpdate={(context, params) => afterCheck(context, params, t)}
                          callback={callbackAfterUpdate}
                        />
                      </div>
                    ) : (
                      ''
                    )}
                  </div>

                  {this.props.context.state.productSelectionError ? (
                    <Error
                      name="serviceDetailsproductSelectionError"
                      className="margin-bottom-2"
                      message={t(`general|At least one product should be selected`)}
                    />
                  ) : (
                    ''
                  )}

                  <div className="l-grid--w-100pc-s frc__generic-row--wrapper ">
                    <PayingAccountNumber context={this.props.context} />
                  </div>
                </div>
                <div
                  className={
                    'frc__generic--loader-overlay' +
                    (this.state.isLoading || this.props.context.state.loaders.products.value ? ' is-loading ' : '')
                  }
                />
              </div>
            </>
          )}
        </Translation>
      </div>
    );
  }
}
