import { config, CONST } from '../../../data-config';
import { apiLinks } from 'config/api-config';
import { apiRequest } from 'globals/utils/requests';
import { homeDeliveryValidations } from './ServiceDetails-helpers/ServiceDetails-Home-Delivery-helpers';
import getLanguage from 'globals/utils/getLanguage';
import {
  checkAndTranslateOptionSchemas,
  applyOptionSchemaResultToVAS,
  filterServicesForGivenProduct,
  filterGServices,
  applyValidationRulesToASlist,
} from './ServiceDetails-helpers/ServiceDetails-helpers';
import { combineADMainAndSecondaryList } from './ServiceDetails-helpers/ServiceDetails-helpers';
import {
  arrangeVASAlphabetically,
  setLoadersState,
  getTranslatedVASName,
  arrangeVASBasedOnSelectedLanguage,
  getArrayIntersectionValues,
} from './ServiceDetails-helpers/ServiceDetails-General-helpers';
import { loadCollectionAtTerminal } from './ServiceDetails-helpers/ServiceDetails-Collection-At-Terminal';
import { tailLiftServiceFilter } from '../../../../portal-order/components/ServiceDetails/ServiceDetails-helpers/ServiceDetails-Filter-VAS';

export const beforeCheck = context => {
  return setLoadersState(context, true);
};

/**
 * afterCheck
 * performs additional logic after ticking a checkbox
 */
export const afterCheck = async (context, product, countryConfig) => {
  const stateObject = await getAdditionalServicesForOneSelectedProduct(product, context.state, countryConfig);
  Object.assign(stateObject, setLoadersState(context, false));
  context.updateState(stateObject);
};

const setShipmentRowsNumberMax = (productCode, state) => {
  const product = state.products.find(element => {
    return element.code === productCode;
  });

  return product.shipmentRowsNumberMax ? product.shipmentRowsNumberMax : config.maxAllowedShipmentDetailsRows;
};

const additionalServiceAndValidationCall = async (product, disabledVas) => {
  const stateObject = {};
  const urlAdditionalServices = apiLinks.getAdditionalServices.replace('{productCode}', product);

  await apiRequest(urlAdditionalServices, 'GET')
    .then(async result => {
      if (result.status === CONST.STATUS_OK) {
        result.data.forEach(element => {
          element.value = false;
          element.productCode = product;
        });

        stateObject.additionalServices = filterServicesForGivenProduct(product, result.data, disabledVas);

        await getValidationResults(JSON.parse(JSON.stringify(stateObject.additionalServices)), product).then(
          validationResult => {
            if (validationResult.data) {
              stateObject.additionalServices = validationResult.data;
            } else if (validationResult.error) {
              stateObject.error = true;
            }
          },
        );
      } else {
        stateObject.error = true;
      }
    })
    .catch(() => {
      stateObject.error = true;
    });

  return stateObject;
};

export const getOptionSchema = async (stateObject, product) => {
  const urlOptions = apiLinks.getPortalOrderVAS.replace('{productCode}', product);

  // API Call for optional schema
  await apiRequest(urlOptions, 'GET')
    .then(result => {
      if (result.status === CONST.STATUS_OK) {
        stateObject.additionalServices = applyOptionSchemaResultToVAS(stateObject.additionalServices, result.data);
        if (stateObject.additionalServices) {
          stateObject.additionalServices = checkAndTranslateOptionSchemas(stateObject.additionalServices);
        }
      } else {
        stateObject.error = true;
      }
    })
    .catch(() => {
      stateObject.error = true;
    });
};

/**
 * getAdditionalServicesForOneSelectedProduct
 * main filtering functionality
 */
export const getAdditionalServicesForOneSelectedProduct = async (product, state, countryConfig) => {
  const stateObject = {};

  const language = getLanguage(config.regEx.urlValidation);
  const totalShipmentWeight = state.totals.shipmentDetailsRows.weight.value * 1;
  const disabledVas = countryConfig?.disabledVass ?? {};
  let vasAPICall;
  let homeDeliveryAPICall;
  let vasResultObj = {};
  let homeDeliveryVasResultObj = {};

  const isHomeDelivery = config.homeDeliveryProductCodes.indexOf(product) !== -1;

  stateObject.error = false;

  vasAPICall = additionalServiceAndValidationCall(product, disabledVas);

  if (isHomeDelivery) {
    homeDeliveryAPICall = homeDeliveryValidations(product, state);
    await homeDeliveryAPICall.then(result => (homeDeliveryVasResultObj = result));
  }

  await vasAPICall.then(result => (vasResultObj = result));

  if (!vasResultObj.error) {
    stateObject.additionalServices = vasResultObj.additionalServices;
  }

  // FCPG-44 tailLift service filtering based on weight
  stateObject.additionalServices = tailLiftServiceFilter({
    additionalServices: structuredClone(stateObject.additionalServices),
    totalShipmentWeight,
    shipmentRows: state.shipmentDetailsRows,
    tailLiftConfig: countryConfig?.vasFilters?.tailLiftLoading,
    serviceName: 'tailLiftLoading',
  });

  stateObject.additionalServices = tailLiftServiceFilter({
    additionalServices: structuredClone(stateObject.additionalServices),
    totalShipmentWeight,
    shipmentRows: state.shipmentDetailsRows,
    tailLiftConfig: countryConfig?.vasFilters?.tailLiftUnloading,
    serviceName: 'tailLiftUnloading',
  });

  // home delivery
  if (isHomeDelivery) {
    // no vas error and geo location error
    if (!homeDeliveryVasResultObj.error && !homeDeliveryVasResultObj.geoLocationError) {
      stateObject.geoLocationError = false;

      // combine main VAS and Home Delivery Vas
      const preFilteredList = combineADMainAndSecondaryList(
        stateObject.additionalServices,
        homeDeliveryVasResultObj.additionalServices,
      );
      // get intersection between VAS and Home Delivery Validators VAS
      if (homeDeliveryVasResultObj.geoLocationAdditionalServices) {
        stateObject.additionalServices = getArrayIntersectionValues(
          homeDeliveryVasResultObj.geoLocationAdditionalServices,
          preFilteredList,
        );
      }
    } else {
      stateObject.error = true;
      stateObject.geoLocationError = homeDeliveryVasResultObj.geoLocationError;
    }
  }

  // get options schema
  if (!stateObject.error) {
    await getOptionSchema(stateObject, product);
  }

  if (!stateObject.error) await collectionAtTerminal(stateObject, state);

  if (!stateObject.error) {
    stateObject.additionalServicesGeneralError = false;
    stateObject.additionalServices = arrangeVASAlphabetically(stateObject.additionalServices, language);
    stateObject.additionalServices = arrangeVASBasedOnSelectedLanguage(
      stateObject.additionalServices,
      'translatedName',
    );
    stateObject.additionalServices = getTranslatedVASName(stateObject.additionalServices, state.languageCode);

    if (product && product.hasOwnProperty('priorityServices')) {
      stateObject.additionalServices = filterGServices(product, stateObject.additionalServices);
    }
  } else {
    stateObject.additionalServices = [];
  }

  stateObject.selectProductError = false;
  stateObject.additionalServicesAPIError = stateObject.error;
  stateObject.shipmentRowsNumberMax = setShipmentRowsNumberMax(product, state);

  return stateObject;
};

const collectionAtTerminal = async (stateObject, state) => {
  let collectionAtTerminalIndex = -1;

  stateObject.additionalServices.forEach(function (vas, i) {
    if (vas.code === config.OptionFieldTypes.collectionAtTerminal) {
      collectionAtTerminalIndex = i;
      return true;
    }
  });

  if (collectionAtTerminalIndex !== -1) {
    await loadCollectionAtTerminal(stateObject, collectionAtTerminalIndex, state);
  }
};

/**
 * getValidationResults
 * secondary filtering from validations recieved by API call
 */
export const getValidationResults = async (additionalServices, productCode) => {
  const resultObj = {};
  const urlValudationResults = apiLinks.getValidationResults.replace('{productCode}', productCode);
  const payloadDataForValidationResults = {};

  // Prepare payload for the API call
  additionalServices.forEach(service => {
    payloadDataForValidationResults[service.code] = true;
  });

  // API Call for validations
  await apiRequest(urlValudationResults, 'POST', {
    body: payloadDataForValidationResults,
  })
    .then(result => {
      if (result.status === CONST.STATUS_OK) {
        additionalServices = applyValidationRulesToASlist(additionalServices, result.data);
        resultObj.data = JSON.parse(JSON.stringify(additionalServices));
      } else {
        resultObj.error = true;
      }
    })
    .catch(() => {
      resultObj.error = true;
    });

  return resultObj;
};
