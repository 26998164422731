import { globalConfig, GLOBAL_CONST } from 'globals/data-config-global';
import { SERVICE_POINT_PRODUCT_CODE } from '../globals/constants';

export const componentConfig = {
  optionsSchemaRequiredAsDateTime: ['dateTime', 'invoiceDate'],

  printDocumentTypes: [
    { type: 'label', label: 'Label' },
    { type: 'waybill', label: 'Waybill' },
    { type: 'shipmentlist', label: 'Shipment List' },
  ],
  productsReturnShipmentAvailable: [SERVICE_POINT_PRODUCT_CODE, '109'],
};

const COMPONENT_CONST = {
  API_DOMAIN: 'dashboard',
  DASHBOARD: 'DSB',
};

export const config = Object.assign({}, globalConfig, componentConfig);
export const CONST = Object.assign({}, GLOBAL_CONST, COMPONENT_CONST);
