import React, { Component } from 'react';
import { config } from '../../data-config';
import { Error } from 'components';
import { onAddressSearch } from '../ActionUtils';
import { downloadExampleTemplateExcel, uploadAndProcess } from './ImportContactModal-helpers';
import { Translation } from 'react-i18next';

import './ImportContactModal.css';
import { Box } from '@mui/material';

export default class ImportContactModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      file: false,
      downloadExampleTemplateError: false,
      fileNotSupported: false,
      resultContent: null,
      uploadAndProcessError: false,
      loader: false,
    };

    this.fileInputRef = React.createRef(null);
  }

  onUploadAndProcess = async () => {
    if (
      (this.state.file && this.state.file.type === 'application/vnd.ms-excel') ||
      this.state.file.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
    ) {
      this.setState(
        {
          loader: true,
          downloadExampleTemplateError: false,
          uploadAndProcessError: false,
        },
        async () => {
          const resultMap = await uploadAndProcess(this.state.file);

          if (!resultMap.error) {
            this.setState(
              {
                resultContent: resultMap.data,
                uploadAndProcessError: false,
                loader: false,
                ...this.resetFileInput(),
              },
              () => {
                onAddressSearch(this.props.context);
              },
            );
          } else {
            this.setState({
              resultContent: (resultMap.data && resultMap.data) || '',
              uploadAndProcessError: true,
              loader: false,
              ...this.resetFileInput(),
            });
          }
        },
      );
    } else {
      this.setState({
        fileNotSupported: true,
        uploadAndProcessError: false,
      });
    }
  };

  downloadExampleTemplate = async () => {
    this.setState(
      {
        loader: true,
      },
      async () => {
        const csvError = await downloadExampleTemplateExcel();

        if (csvError) {
          this.setState({
            downloadExampleTemplateError: true,
            loader: false,
          });
        } else {
          this.setState({
            loader: false,
          });
        }
      },
    );
  };

  uploadFile = event => {
    const files = event.target.files;

    this.setState({
      file: files[0],
      fileNotSupported: false,
      uploadAndProcessError: false,
      downloadExampleTemplateError: false,
      resultContent: null,
    });
  };

  resetFileInput = () => {
    this.fileInputRef.current.value = null;
    return {
      file: false,
    };
  };

  render() {
    const { onCloseModalAction } = this.props;

    return (
      <>
        <Translation>
          {t => (
            <div className="frc__dialog">
              <div
                className="frc__dialog-overlay"
                onClick={this.state.loader ? null : () => onCloseModalAction()}
              ></div>
              <div className="frc__modal-import-dialog-width frc__dialog-wrapper">
                <div className="frc__dialog-container l-grid--w-100pc-s l-grid--w-80pc-m-s l-grid--w-60pc-m-m l-grid--left-s">
                  <div className="frc__dialog-close-button">
                    <button className="frc__button--delete--darker" onClick={onCloseModalAction}>
                      X
                    </button>
                  </div>

                  <h4 className="frc__modal-center">{t(`portalAddressBook|Import Contacts`)}</h4>
                  <div className="margin-bottom-1">
                    {t(`portalAddressBook|Import Address Message`, {
                      max: config.maxNosImportContact,
                    })}
                    :
                  </div>

                  <div className="l-grid--w-100pc-s l-grid--w-100pc-m-s l-grid--w-100pc-m-m frc__generic-row--wrapper margin-bottom-1 ">
                    <span className="frc__download_link" onClick={this.downloadExampleTemplate}>
                      {t(`portalAddressBook|Download example of template`)}
                    </span>

                    {this.state.downloadExampleTemplateError && (
                      <Error
                        name="exampleTemplateError"
                        message={
                          t('general|errors|General error') +
                          '. <br> ' +
                          t('general|Try again or call technical support.') +
                          ' ' +
                          t('general|or') +
                          ' ' +
                          `<strong><a href="${t('pageLinks|chat')}" 
                                            class ="frc__chat-with-us"
                                            target="_blank" rel="noopener noreferrer">${t(
                                              `general|Chat with us`,
                                            )}</a></strong>`
                        }
                      />
                    )}
                  </div>

                  <div className="l-grid l-grid--w-100pc-s margin-bottom-1">
                    <div
                      className=" l-grid--w-100pc-s 
                      l-grid--w-50pc-m-s l-grid--w-50pc-m-m frc__generic-row--wrapper frc__upload-doc-margin-right"
                    >
                      <input
                        type="file"
                        accept=".xls, .xlsx"
                        className="frc__upload_contact frc__upload-border-radius"
                        ref={this.fileInputRef}
                        onChange={event => this.uploadFile(event)}
                      />
                    </div>
                    <div className="l-grid--w-100pc-s l-grid--w-40pc-m-s l-grid--w-40pc-m-m ">
                      <button
                        onClick={this.state.file ? this.onUploadAndProcess : null}
                        disabled={!this.state.file}
                        className={
                          'base-button base-button--wide l-grid--w-25pc-w ' + (this.state.file ? '' : 'disabled')
                        }
                      >
                        {t(`portalAddressBook|Import Contacts`)}
                      </button>
                    </div>

                    <div className="l-grid--right-s l-grid--w-100pc-s">
                      {this.state.fileNotSupported || this.state.uploadAndProcessError ? (
                        <Error
                          name="excelUploadError"
                          message={
                            this.state.fileNotSupported
                              ? t('general|Unsupported file format. Please use one of following') +
                                ': ' +
                                t(`portalAddressBook|xls, xlsx`)
                              : this.state.uploadAndProcessError
                              ? t('general|errors|General error') +
                                '. <br> ' +
                                t('general|Try again or call technical support.') +
                                ' ' +
                                t('general|or') +
                                ' ' +
                                `<strong><a href="${t('pageLinks|chat')}" 
                                                  class ="frc__chat-with-us"
                                                  target="_blank" rel="noopener noreferrer">${t(
                                                    `general|Chat with us`,
                                                  )}</a></strong>`
                              : ''
                          }
                        />
                      ) : (
                        ''
                      )}
                    </div>
                  </div>

                  {t(`portalAddressBook|Downloading Results`)}
                  <div className="frc__import-modal-error l-grid--w-100pc-m l-grid--w-100pc-m-m l-grid--w-100pc-s">
                    {this.state.resultContent && (
                      <>
                        <Box>
                          {t(`portalAddressBook|Total rows`)}: {this.state.resultContent?.totalRows}
                        </Box>
                        <Box>
                          {t(`portalAddressBook|Total successful rows`)}: {this.state.resultContent?.totalSuccessRows}
                        </Box>
                        <Box>
                          {t(`portalAddressBook|Total failed rows`)}: {this.state.resultContent?.totalFailedRows}
                        </Box>
                      </>
                    )}
                  </div>

                  <div className="has-top-margin-1 frc__modal-center l-grid--w-100pc-s l-grid--w-100pc-m-s l-grid--w-100pc-m-m">
                    <button
                      className={'base-button  l-grid--w-20pc-s ' + (this.state.loader ? 'disabled' : '')}
                      id="closeContactBtn"
                      onClick={this.state.loader ? null : () => onCloseModalAction()}
                      disabled={this.state.loader}
                    >
                      <span>{t('general|labels|buttons|Close')}</span>
                    </button>
                  </div>

                  <div className={`frc__generic--loader-overlay ${this.state.loader ? 'is-loading' : ''}`} />
                </div>
              </div>
            </div>
          )}
        </Translation>
      </>
    );
  }
}
