import SearchIcon from '@mui/icons-material/Search';
import { Alert, Button, Stack, Typography } from '@mui/material';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { globalConfig } from '../../../globals/data-config-global';
import { useLazySearchDgItemsQuery } from '../../../store/api/fcpSavedShipment';
import { Button as FcpButton } from '../Button';
import { ControlledTextField } from '../form-inputs';
import { LookupTable } from './LookupTable';
import { transformDgApiToFormData } from './DgItemDetail';
import { isExcludedDangerousItem } from './DangerousGoods';

export const DgSearch = ({ setDgmId, onDetailSubmit, onDeleteItem, disableDelete, dangerousGoodsExclusions }) => {
  const { t } = useTranslation();
  const [adrLookupOpen, setAdrLookupOpen] = useState(false);
  const [showNoResultsError, setShowNoResultsError] = useState(false);
  const [showExclusionError, setShowExclusionError] = useState(false);

  const [searchDgItems] = useLazySearchDgItemsQuery();

  const { handleSubmit, control, getValues, setValue, formState } = useForm({
    defaultValues: { unNumber: '' },
    mode: 'onBlur',
  });

  const onSubmit = async formData => {
    setShowExclusionError(false);

    const { data } = await searchDgItems({ unNumber: formData.unNumber, languageCode: 'en' });
    setDgmId(null);
    setShowNoResultsError(false);

    if (!data?.dgItems) return;
    const dgItemsLength = data?.dgItems?.length;

    if (dgItemsLength === 0) {
      setShowNoResultsError(true);
    }

    if (dgItemsLength === 1) {
      const dangerousItem = data?.dgItems?.[0];

      if (isExcludedDangerousItem(dangerousItem, dangerousGoodsExclusions)) {
        // this dangerous item is excluded from transport, so we need to show error to the user
        setShowExclusionError(true);
      } else {
        const dgmId = dangerousItem?.dgmId;
        setDgmId(dgmId);
        onDetailSubmit(
          transformDgApiToFormData({
            dgmId,
            dataFromApi: dangerousItem,
          }),
        );
      }
    }

    if (dgItemsLength > 1) {
      setAdrLookupOpen(true);
    }
  };

  return (
    <>
      <Typography variant="body" fontWeight="bold">
        {t('general|dangerousGoods|unSearchTitle')}
      </Typography>
      <Stack direction="row" spacing={2} component="form" noValidate onSubmit={handleSubmit(onSubmit)}>
        <ControlledTextField
          control={control}
          label={t('general|UN Number')}
          fieldName="unNumber"
          rules={{
            pattern: {
              value: /^\d{4}$/,
              message: t('general|Dangerous Good Error', {
                name: t('general|UN Number'),
                max: globalConfig.dangerousGoodDimensions.max_un_number,
              }),
            },
          }}
          TextFieldProps={{
            inputProps: { maxLength: globalConfig.dangerousGoodDimensions.max_un_number },
            sx: { maxWidth: '200px' },
            'data-testid': 'dgSearchUnNumber',
          }}
          inputRegex={/^\d*$/}
          onChangeCb={() => setShowNoResultsError(false)}
          required
        />
        <Stack direction="row" justifyContent="space-between" pt="22px" flexGrow={1}>
          <FcpButton
            disabled={!formState.isDirty || !formState.isValid || formState.isSubmitting}
            variant="outlined"
            loading={formState.isSubmitting}
            type="submit"
            sx={{ height: '48px' }}
            data-testid="dgSearchSubmit"
          >
            {t('general|labels|buttons|enter')}
          </FcpButton>
          <Stack direction="row" gap="16px">
            <Button
              variant="outlined"
              onClick={() => {
                onDeleteItem();
              }}
              disabled={disableDelete}
              data-testid="dgSearchRemove"
            >
              {t('general|dangerousGoods|remove')}
            </Button>
            <Button
              variant="text"
              onClick={() => {
                setValue('unNumber', '');
                setAdrLookupOpen(true);
              }}
              startIcon={<SearchIcon />}
              sx={{ fontWeight: 'bold', maxHeight: '48px' }}
              data-testid="dgSearchSearchByName"
            >
              {t('general|dangerousGoods|searchByName')}
            </Button>
          </Stack>
        </Stack>
      </Stack>
      {showNoResultsError && (
        <Alert
          severity="error"
          variant="outlined"
          sx={{
            width: 'fit-content',
          }}
          data-testid="dgSearchUnNumberNotFound"
        >
          <Typography variant="body2" fontWeight="bold" color="error">
            {t('general|dangerousGoods|unNumberNotFound')}
          </Typography>
        </Alert>
      )}
      {showExclusionError && (
        <Alert
          data-testid="dgSearchDangerousItemExlusionError"
          severity="error"
          variant="outlined"
          sx={{
            width: 'fit-content',
          }}
        >
          <Typography variant="body2" fontWeight="bold" color="error">
            {t('general|dangerousGoods|dangerousItemExlusionError')}
          </Typography>
        </Alert>
      )}
      {adrLookupOpen && (
        <LookupTable
          unNumberInit={getValues('unNumber')}
          open={true}
          onClose={() => setAdrLookupOpen(false)}
          onSelect={setDgmId}
          dangerousGoodsExclusions={dangerousGoodsExclusions}
        />
      )}
    </>
  );
};
