import { Button, Checkbox, Error, Input, PhoneNumberV2, Select } from 'components';
import { sortCountries } from 'globals/data-sorted-countries';
import { analytics } from 'globals/utils/analytics';
import { getPropperAPIData, handleAddressBookCalls } from 'globals/utils/requests';
import React, { Component } from 'react';
import { Translation } from 'react-i18next';
import { POSTALCODE_ERRORS, PostalCodeInput } from '../../../components/fcp-components/PostalCodeInput';
import {
  DOMESTIC_SHIPMENT_RANGE,
  EXPORT_SHIPMENT_TYPE,
  IMPORT_SHIPMENT_TYPE,
  INTERNATIONAL_SHIPMENT_RANGE,
  PRODUCTS_RANGE,
  RECEIVER_PAYER,
  SENDER_PAYER,
} from '../../../globals/constants';
import { fetchUserAccountsAndProductSpecifications } from '../../../globals/helpers/data-from-api';
import { canFetchCityForPostalcode, getCityName } from '../../../globals/helpers/postalcode';
import i18n from '../../../locale/i18n';
import { config, CONST } from '../../data-config';
import { onSaveForFutureClick, saveShipmentCall } from './../../helpers/savedShipments';
import AddressPickup from './AddressPickup/AddressPickup';
import CompanyNameSearch from './CompanyNameSearch/CompanyNameSearch';
import { postalCodeAndCityValidation } from './EnterPickupLocation-API-validation';
import {
  addressAutoComplete,
  afterCountry,
  afterResidentialAddressUpdate,
  checkContactEmail,
  checkSubmit,
  fillAddressData,
  getPayerCode,
  getStateToSet,
  renderDifferentDeliveryAddress,
  renderDifferentPickUpAddress,
  resetAddUpdateAddressBookCheckBox,
  setAppropriateReferenceAndInstructions,
  setGeneralError,
} from './EnterPickupLocation-helpers';
import './EnterPickupLocation.css';
import { ImportExportSection } from './ImportExportSection/ImportExportSection';
import ProgressBar from './ProgressBar/ProgressBar';
import SavedTemplates from './SavedTemplates/SavedTemplates';
import { apiRequest } from '../../../globals';
import { apiLinks } from '../../../config/api-config';
import { connect } from 'react-redux';
import { rootApi } from '../../../store/api/apiSlice';
import {
  setAccountNumber,
  setEnableResidentialFromAddress,
  setEnableResidentialToAddress,
  setResidentialAddressFromRequired,
  setResidentialAddressToRequired,
} from '../../../store/portalOrderSlice';
import { Box, Grid, Skeleton, Typography } from '@mui/material';
import { changeCountryCodePhonePrefix } from '../../../globals/helpers/country-helpers';

class EnterPickupLocation extends Component {
  unsubscribeUserAccountsAndProducts = null;
  unsubscribeProducts = null;

  constructor(props) {
    super(props);

    this.state = {
      validationError: false,
      apiError: false,
      generalError: false,
      nextBtnLoader: false,
      sourcePostalCodeError: false,
      destinationPostalCodeError: false,
      sourceCityError: false,
      destinationCityError: false,
      addressPickupModal: React.createRef(),
      savedTemplatesModal: React.createRef(),
      saveForFutureError: false,
      saveShipmentsError: false,
      saveForFutureLoader: false,
      addressBookError: false,
      addressBookMaxLimitReachedError: false,
      cityValidationError: {},
      isLoading: false,
    };

    this.loaderRef = React.createRef();
  }

  resetPostalCodeState = () => {
    return {
      sourcePostalCodeError: false,
      destinationPostalCodeError: false,
      sourceCityError: false,
      destinationCityError: false,
    };
  };

  afterDifferentAddressCheckboxUpdate = () => {
    this.resetPostalCityError();
    afterCountry(this.props.context);
  };

  resetPostalCityError = () => {
    this.setState(this.resetPostalCodeState());
    const stateObj = checkContactEmail(this.props.context);
    Object.assign(stateObj, setAppropriateReferenceAndInstructions(this.props.context));
    this.props.context.updateState(stateObj);
  };

  resetCityValidationError = () => {
    const stateToSet = this.resetPostalCodeState();
    stateToSet.cityValidationError = {};

    this.setState(stateToSet);
  };

  handleSaveForFutureClick = async t => {
    this.setState({ saveForFutureLoader: true }, async () => {
      const result = await onSaveForFutureClick(this.props.context, t);

      if (result.error) {
        this.setState({ saveForFutureError: true, saveForFutureLoader: false });
      }
    });
  };

  setAppropriateCity = (suggestedCity = false, cityName, cityError) => {
    const city = JSON.parse(JSON.stringify(this.props.context.state[cityName]));
    const cityValidationError = JSON.parse(JSON.stringify(this.state.cityValidationError));

    city.value = suggestedCity || this.state.cityValidationError[cityName].value;
    city.error = false;
    delete cityValidationError[cityName];

    this.setState(
      {
        cityValidationError,
        [cityError]: false,
      },
      () => {
        this.props.context.updateState({ [cityName]: city });
      },
    );

    if (suggestedCity) this.loaderRef?.current?.classList?.remove('is-loading');
  };

  checkAppropriateCity = async (countryName, postalCodeName, cityName, postalCodeError, cityError) => {
    this.loaderRef?.current?.classList?.add('is-loading');
    const { state } = this.props.context;
    const accountCountryCode = state.user?.accountCountryCode;
    const countryCode = state[countryName].value;
    const postalCode = state[postalCodeName].value;

    const shouldValidateCity = canFetchCityForPostalcode({ accountCountryCode, countryCode, postalCode });

    if (shouldValidateCity) {
      const localStateToSet = {};

      const fetchCityCall = await getCityName({ countryCode, postalCode, cityName: state[cityName].value });
      localStateToSet.loader = false;
      localStateToSet.cityValidationError = JSON.parse(JSON.stringify(this.state.cityValidationError));
      localStateToSet.cityValidationError[cityName] = {};

      // postal code correct
      if (!fetchCityCall.error && fetchCityCall.data && fetchCityCall.data.city) {
        // city name correct
        if (state[cityName].value && countryCode?.toLowerCase() === 'se') {
          localStateToSet.cityValidationError[cityName] = {
            value: fetchCityCall.data.city,
            mismatchError: !fetchCityCall.data.validated,
          };

          if (fetchCityCall.data.validated) {
            delete localStateToSet.cityValidationError[cityName];
          }
        } else if (!state[cityName].value) {
          // city name not entered
          this.setAppropriateCity(fetchCityCall.data.city, cityName, cityError);

          return;
        }
      } else if (!fetchCityCall.error && fetchCityCall.data && !fetchCityCall.data.validated) {
        localStateToSet.cityValidationError[cityName] = { postalCodeError: true };
      }

      localStateToSet[postalCodeError] = false;
      localStateToSet[cityError] = false;

      if (
        localStateToSet.cityValidationError[cityName] &&
        Object.keys(localStateToSet.cityValidationError[cityName]).length === 0
      ) {
        delete localStateToSet.cityValidationError[cityName];
      }

      localStateToSet[postalCodeError] = false;
      localStateToSet[cityError] = false;

      this.setState(localStateToSet);
    }
    this.loaderRef.current.classList.remove('is-loading');
  };

  checkIfEnteredAndSuggestedCityIsValid = cityName => {
    const { cityValidationError } = this.state;

    if (
      cityValidationError[cityName] &&
      cityValidationError[cityName].mismatchError &&
      cityValidationError[cityName].value
    ) {
      const modifiedCityValidationError = JSON.parse(JSON.stringify(cityValidationError));

      modifiedCityValidationError[cityName].mismatchError =
        this.props.context.state[cityName].value.toLowerCase() !==
        modifiedCityValidationError[cityName].value.toLowerCase();

      if (!modifiedCityValidationError[cityName].mismatchError) {
        delete modifiedCityValidationError[cityName];
      }

      this.setState({
        cityValidationError: modifiedCityValidationError,
      });
    }
  };

  cityValidation = () => {
    return Object.keys(this.state.cityValidationError).length > 0;
  };

  onGoClick = async context => {
    const state = getPropperAPIData(context.state);
    const stateToSet = getStateToSet();

    // Initial validation check
    stateToSet.validationError = checkSubmit(context);
    const cityNotValid = this.cityValidation();

    if (cityNotValid) {
      stateToSet.validationError = true;
    }

    if (
      this.props.residentialAddressToRequired &&
      ((!context.state.deliveryAddressResidential.value && !context.state.deliverToDifferentAddress?.value) ||
        (context.state.deliverToDifferentAddress.value && !context.state.differentDeliveryAddressResidential.value))
    ) {
      stateToSet.validationError = true;
    }

    if (
      this.props.residentialAddressFromRequired &&
      ((!context.state.pickupAddressResidential.value && !context.state.pickupFromDifferentAddress?.value) ||
        (context.state.pickupFromDifferentAddress.value && !context.state.differentPickupAddressResidential.value))
    ) {
      stateToSet.validationError = true;
    }
    const newStateToSet = setGeneralError(stateToSet);
    this.setState(newStateToSet);

    if (!stateToSet.validationError) {
      this.setState({ nextBtnLoader: true }, async () => {
        stateToSet.goNextError = false;

        Object.assign(stateToSet, await postalCodeAndCityValidation(state, stateToSet));

        context.updateState({
          deviating: stateToSet.deviating || false,
          error: false,
        });

        this.updateLocalStateAndGoToNextTab(stateToSet);
      });
    }
  };

  updateLocalStateAndGoToNextTab = async stateToSet => {
    const newStateToSet = setGeneralError(stateToSet);

    let canProceedToNextStep = !newStateToSet.goNextError && !newStateToSet.generalError && !newStateToSet.apiError;

    // all validations successful
    if (canProceedToNextStep) {
      // address book add/update checkbox call
      const { hasAddressBookErrors, hasMaxLimitReached } = await handleAddressBookCalls(this.props.context);

      // proceed only when no address book add/update error
      if (!hasAddressBookErrors && !hasMaxLimitReached) {
        // reset address book checkbox
        const ctxStatetToSet = resetAddUpdateAddressBookCheckBox();
        ctxStatetToSet.payerCode = this.props.context.state?.payerCode || getPayerCode(this.props.context.state);
        this.props.context.updateState(ctxStatetToSet);

        //save shipment call - templateType- 0
        const savedShipmentResult = await saveShipmentCall(this.props.context);

        // no error -> proceed next step
        if (!savedShipmentResult.error) {
          analytics('step 1', CONST.ANALYTICS);
          this.props.nextTab(config.tabs.tab2);
        } else {
          // state update
          newStateToSet.saveShipmentsError = savedShipmentResult.error || false;
          canProceedToNextStep = false;
        }
      } else {
        newStateToSet.addressBookError = hasAddressBookErrors || false;
        newStateToSet.addressBookMaxLimitReachedError = hasMaxLimitReached || false;
        canProceedToNextStep = false;
      }
    }

    // in case, error occured
    if (!canProceedToNextStep) {
      newStateToSet.nextBtnLoader = false;
      this.setState(newStateToSet);
    }
  };

  showAddressPickup = party => {
    this.state.addressPickupModal.current.openModal(party);
  };

  onStartFromTemplateClick = () => {
    this.state.savedTemplatesModal.current.openModal();
  };

  componentDidMount = async () => {
    this.setState({ isLoading: true });
    let user = this.props.context.state.user;
    if (!this.props.context.state.user) {
      await apiRequest(apiLinks.getUserData, 'GET').then(result => {
        if (result.status === CONST.STATUS_OK) {
          this.props.context.updateState({
            user: result.data.user,
          });
          user = result.data.user;
        } else {
          console.error('Fetch user failed.');
        }
      });
    }

    const { getUserAccountsAndProducts, getProducts } = this.props;
    const { unsubscribe: unsubscribeUserAccountsAndProducts, data: userAccountsAndProducts } =
      await getUserAccountsAndProducts();
    const countryCode = user?.accountCountryCode?.toLowerCase();

    if (countryCode) {
      const { unsubscribe: unsubscribeProducts, data: products } = await getProducts({
        countryCode,
      });
      this.unsubscribeUserAccountsAndProducts = unsubscribeUserAccountsAndProducts;
      this.unsubscribeProducts = unsubscribeProducts;

      if (this.props.context.state.products.length === 0) {
        this.loaderRef?.current?.classList?.add('is-loading');
        await fetchUserAccountsAndProductSpecifications(this.props.context, userAccountsAndProducts, products);

        if (!this.props.context.state.generalError) {
          await fillAddressData(
            this.props.context.state.shipmentTypesImpOrExp.value,
            this.props.context,
            this.loaderRef.current,
          );
        } else {
          this.loaderRef.current.classList.remove('is-loading');
        }

        if (
          this.props.context.state.accounts.length === 1 &&
          this.props.context.state.shipmentRange &&
          this.props.context.state.shipmentPayer &&
          !(
            this.props.context.state.shipmentRange.value === DOMESTIC_SHIPMENT_RANGE &&
            this.props.context.state.shipmentPayer.value === RECEIVER_PAYER
          )
        ) {
          if (
            this.props.context.state.shipmentRange.value === DOMESTIC_SHIPMENT_RANGE ||
            (this.props.context.state.shipmentRange.value === INTERNATIONAL_SHIPMENT_RANGE &&
              this.props.context.state.shipmentTypesImpOrExp.value === EXPORT_SHIPMENT_TYPE)
          ) {
            this.props.context.updateState({
              accountNumber: {
                ...this.props.context.state.accountNumber,
                value: this.props.context.state.accounts[0].code,
              },
            });
          }
          if (
            this.props.context.state.shipmentRange.value === INTERNATIONAL_SHIPMENT_RANGE &&
            this.props.context.state.shipmentTypesImpOrExp.value === IMPORT_SHIPMENT_TYPE
          ) {
            this.props.context.updateState({
              receiverNumber: {
                ...this.props.context.state.receiverNumber,
                value: this.props.context.state.accounts[0].code,
              },
            });
          }
        }
      }
    }
    this.setState({ isLoading: false });
  };

  componentWillUnmount() {
    this.unsubscribeUserAccountsAndProducts?.();
    this.unsubscribeProducts?.();
  }

  resetPostalCodeSuggestionError = (context, cityName, differentCityName) => {
    afterCountry(context);

    if (context.state.shipmentRange.value === INTERNATIONAL_SHIPMENT_RANGE) {
      const cityValidationError = JSON.parse(JSON.stringify(this.state.cityValidationError));
      delete cityValidationError[cityName];
      delete cityValidationError[differentCityName];

      this.setState({ cityValidationError });
    }
  };

  isDomesticReceiverSenderAccount = () => {
    const { context } = this.props;
    return (
      context.state.shipmentRange.value === DOMESTIC_SHIPMENT_RANGE &&
      context.state.shipmentPayer.value === RECEIVER_PAYER &&
      context.state.accountNumber.isSelect &&
      context.state.accountNumber.value !== ''
    );
  };

  componentDidUpdate(prevProps) {
    const disabledDeliveryCountry =
      prevProps.context.state.shipmentRange.value !== INTERNATIONAL_SHIPMENT_RANGE ||
      (prevProps.context.state.shipmentRange.value === INTERNATIONAL_SHIPMENT_RANGE &&
        prevProps.context.state.shipmentTypesImpOrExp.value === IMPORT_SHIPMENT_TYPE);
    if (disabledDeliveryCountry) {
      const isImport =
        prevProps.context.state.shipmentRange.value === INTERNATIONAL_SHIPMENT_RANGE &&
        prevProps.context.state.shipmentTypesImpOrExp.value === IMPORT_SHIPMENT_TYPE;
      if (isImport && !prevProps.context.state.deliveryCountry.value) {
        const selectedCountry = config.domesticCountries.filter(
          item => item.code.toLowerCase() === i18n.language.split('-')[0],
        );
        if (selectedCountry && selectedCountry[0]) {
          this.props.context.updateState({
            deliveryCountry: {
              error: false,
              isRequired: true,
              value: selectedCountry[0].code,
            },
          });
        }
      }
    }

    const accountNumber = this.props.accountNumber;
    const prevAccountNumber = prevProps.accountNumber;

    const productRange = this.props.context.state.productRange;
    const prevProductRange = prevProps.context.state.productRange;

    const deliveryAddressResidential = this.props.context.state.deliveryAddressResidential?.value;
    const pickupAddressResidential = this.props.context.state.pickupAddressResidential?.value;
    const differentDeliveryAddressResidential = this.props.context.state.differentDeliveryAddressResidential?.value;
    const differentPickupAddressResidential = this.props.context.state.differentPickupAddressResidential?.value;

    const enableResidentialToAddressChanged =
      this.props.enableResidentialToAddress !== prevProps.enableResidentialToAddress;
    const enableResidentialFromAddressChanged =
      this.props.enableResidentialFromAddress !== prevProps.enableResidentialFromAddress;

    const accountsWithProductsChanged =
      JSON.stringify(this.props.accountsWithProducts) !== JSON.stringify(prevProps.accountsWithProducts);
    const shipmentRangeChanged =
      this.props.context?.state?.shipmentRange?.value !== prevProps.context?.state?.shipmentRange?.value;

    if (
      (enableResidentialToAddressChanged || enableResidentialFromAddressChanged) &&
      (deliveryAddressResidential ||
        differentDeliveryAddressResidential ||
        pickupAddressResidential ||
        differentPickupAddressResidential) &&
      !this.state.isLoading
    ) {
      this.props.context.updateState({
        deliveryAddressResidential: {
          ...this.props.context.state.deliveryAddressResidential,
          value: this.props.enableResidentialToAddress ? deliveryAddressResidential : false,
        },
        pickupAddressResidential: {
          ...this.props.context.state.pickupAddressResidential,
          value: this.props.enableResidentialFromAddress ? pickupAddressResidential : false,
        },
        differentDeliveryAddressResidential: {
          ...this.props.context.state.differentDeliveryAddressResidential,
          value: this.props.enableResidentialToAddress ? differentDeliveryAddressResidential : false,
        },
        differentPickupAddressResidential: {
          ...this.props.context.state.differentPickupAddressResidential,
          value: this.props.enableResidentialFromAddress ? differentPickupAddressResidential : false,
        },
      });
    }

    if (
      (accountNumber !== prevAccountNumber || productRange !== prevProductRange) &&
      this.props.userAccountsAndProducts?.data &&
      accountNumber
    ) {
      if (productRange === PRODUCTS_RANGE.ACCOUNT || productRange === PRODUCTS_RANGE.COUNTRY) {
        const enabledProductRangeOptions = this.props.accountsWithProducts
          .find(account => account.accountNumber === accountNumber)
          ?.productRangeOptions.filter(productRangeItem => !productRangeItem.disabled);
        if (enabledProductRangeOptions) {
          const enabledProductRangeOptionsWithSpecs = enabledProductRangeOptions.map(productRangeItem => {
            const product = this.props.context.state.products.find(
              product => product.code?.toLowerCase() === productRangeItem.value?.toLowerCase(),
            );
            if (product) {
              return {
                ...productRangeItem,
                marketAvailability: product.marketAvailability,
              };
            }
            return productRangeItem;
          });
          const atLeastOneProductB2C = enabledProductRangeOptionsWithSpecs.some(product =>
            product.marketAvailability?.some(market => market.name?.toUpperCase() === 'B2C'),
          );
          this.props.setEnableResidentialToAddress(atLeastOneProductB2C);
          const atLeastOneProductC2B = enabledProductRangeOptionsWithSpecs.some(product =>
            product.marketAvailability?.some(market => market.name?.toUpperCase() === 'C2B'),
          );
          this.props.setEnableResidentialFromAddress(atLeastOneProductC2B);
        }
      } else {
        const productHasB2C = this.props.context.state.products.some(
          product =>
            product.code?.toUpperCase() === productRange?.toUpperCase() &&
            product.marketAvailability?.some(market => market.name?.toUpperCase() === 'B2C'),
        );
        this.props.setEnableResidentialToAddress(productHasB2C);
        const productHasC2B = this.props.context.state.products.some(
          product =>
            product.code?.toUpperCase() === productRange?.toUpperCase() &&
            product.marketAvailability?.some(market => market.name?.toUpperCase() === 'C2B'),
        );
        this.props.setEnableResidentialFromAddress(productHasC2B);
      }
    }

    if (
      (accountsWithProductsChanged || shipmentRangeChanged) &&
      this.props.context?.state?.shipmentRange?.value === DOMESTIC_SHIPMENT_RANGE
    ) {
      const hasEnabledAccountWithDomesticProduct = this.props.accountsWithProducts
        .filter(account => !account.disabled)
        .some(account =>
          account.productRangeOptions.some(
            productRangeItem => !productRangeItem.disabled && productRangeItem.isDomestic,
          ),
        );
      this.props.setEnableResidentialToAddress(hasEnabledAccountWithDomesticProduct);
      this.props.setEnableResidentialFromAddress(hasEnabledAccountWithDomesticProduct);
    }
    if (
      (accountsWithProductsChanged || shipmentRangeChanged) &&
      this.props.context?.state?.shipmentRange?.value === INTERNATIONAL_SHIPMENT_RANGE
    ) {
      const hasEnabledAccountWithInternationalProduct = this.props.accountsWithProducts
        .filter(account => !account.disabled)
        .some(account =>
          account.productRangeOptions.some(
            productRangeItem => !productRangeItem.disabled && !productRangeItem.isDomestic,
          ),
        );
      this.props.setEnableResidentialToAddress(hasEnabledAccountWithInternationalProduct);
      this.props.setEnableResidentialFromAddress(hasEnabledAccountWithInternationalProduct);
    }

    const getProductHasResidentialRequired = ({ market }) => {
      const product = this.props.products.data?.find(
        product => product.code === this.props.context?.state?.selectedProduct?.code,
      );
      if (product) {
        if (
          product.marketAvailability.length === 1 &&
          product.marketAvailability.some(marketAvailability => marketAvailability.name.toUpperCase() === market)
        ) {
          return true;
        }
        return false;
      }
      return false;
    };

    const residentialAddressToRequired = getProductHasResidentialRequired({ market: 'B2C' });
    if (residentialAddressToRequired !== this.props.residentialAddressToRequired) {
      this.props.setResidentialAddressToRequired(residentialAddressToRequired);
    }

    const residentialAddressFromRequired = getProductHasResidentialRequired({ market: 'C2B' });
    if (residentialAddressFromRequired !== this.props.residentialAddressFromRequired) {
      this.props.setResidentialAddressFromRequired(residentialAddressFromRequired);
    }
  }

  render() {
    const { context } = this.props;
    const disableBtn = this.state.nextBtnLoader || this.state.saveForFutureLoader;

    return (
      <div className="frc__generic-section--wrapper">
        <Translation>
          {t => (
            <>
              <AddressPickup
                ref={this.state.addressPickupModal}
                className="color-secondary-green-1"
                context={context}
                checkAppropriateCity={this.checkAppropriateCity}
              />

              <div className="l-grid--w-50pc frc__generic-row--wrapper">
                <button
                  id="startFromTemplateBtn"
                  className={'base-button  base-button--pull-right l-grid--w-25pc-w '}
                  onClick={() => this.onStartFromTemplateClick(context)}
                >
                  <span>{t(`portalOrder|Start from Template`)}</span>
                </button>
                <SavedTemplates
                  ref={this.state.savedTemplatesModal}
                  isReceiver={
                    context.state.shipmentRange.value === INTERNATIONAL_SHIPMENT_RANGE &&
                    context.state.shipmentTypesImpOrExp.value === IMPORT_SHIPMENT_TYPE
                  }
                  className="color-secondary-green-1"
                  context={context}
                />
              </div>
              {!this.state.isLoading ? (
                <ImportExportSection context={context} resetCityValidationError={this.resetCityValidationError} />
              ) : (
                <div className=" frc__generic--field-wrapper ">
                  <h4 className="margin-bottom-0 frc__red-lined-title">{t('general|Route Type & Payment')}</h4>
                  <Box sx={{ mt: '16px' }} className="l-grid l-grid--between-s margin-bottom-2">
                    <div className="l-grid--w-100pc-s l-grid--w-48pc-m">
                      <Skeleton variant="text" width="50%" sx={{ fontSize: '16px' }} />
                      <Skeleton variant="rounded" width="100%" height="48px" />
                    </div>
                    <div className="l-grid--w-100pc-s l-grid--w-48pc-m">
                      <Skeleton variant="text" width="50%" sx={{ fontSize: '16px' }} />
                      <Skeleton variant="rounded" width="100%" height="48px" />

                      <div className="l-grid l-grid--between-s">
                        <Grid container spacing={3} sx={{ marginTop: '4px' }}>
                          <Grid item xs={6}>
                            <Skeleton variant="text" width="50%" sx={{ fontSize: '16px' }} />
                            <Skeleton variant="rounded" width="100%" height="48px" />
                          </Grid>
                          <Grid item xs={6}>
                            <Skeleton variant="text" width="50%" sx={{ fontSize: '16px' }} />
                            <Skeleton variant="rounded" width="100%" height="48px" />
                          </Grid>
                        </Grid>
                      </div>
                    </div>
                  </Box>
                </div>
              )}

              <div className="l-grid l-grid--between-s frc__push-bottom  frc__generic--field-wrapper ">
                <div className="l-grid--w-100pc-s l-grid--w-48pc-m margin-bottom-1">
                  <h4 className="margin-bottom-1 frc__red-lined-title">{t('general|From')}</h4>
                  <div className="l-grid c-form-step--item c-freightcalc--item-checkbox">
                    <Checkbox
                      label={t('general|labels|inputs|Pick up from a different address')}
                      labelAfter={
                        '&nbsp;<span class="has-tooltip"> ' +
                        '<div class="frc__tooltip-container has-icon icon-information color-DHL-red c-fcp-quote--info-container"><div class="frc__tooltip-container-flyout">' +
                        t('general|pickupFromDifferentAddressTooltip') +
                        '</div></div>' +
                        '</span>'
                      }
                      name="pickupFromDifferentAddress"
                      isRequired={false}
                      checked={context.state.pickupFromDifferentAddress.value}
                      context={context}
                      config={config}
                      CONST={CONST}
                      disabled={context.state.pickupAddressResidential.value}
                      afterUpdate={this.afterDifferentAddressCheckboxUpdate}
                    />
                  </div>
                  <div className="frc__generic--background-white frc__generic-section--wrapper">
                    <Checkbox
                      labelAfter={
                        '&nbsp;<span class="has-tooltip"> ' +
                        '<div class="frc__tooltip-container has-icon icon-information color-DHL-red c-fcp-quote--info-container"><div class="frc__tooltip-container-flyout">' +
                        t('general|Pickup residential address tooltip') +
                        '</div></div>' +
                        '</span>'
                      }
                      label={t('general|This is a residential address')}
                      name="pickupAddressResidential"
                      isRequired={false}
                      checked={context.state.pickupAddressResidential.value}
                      context={context}
                      config={config}
                      CONST={CONST}
                      disabled={
                        context.state.pickupFromDifferentAddress.value ||
                        context.state.shipmentPayer.value === SENDER_PAYER ||
                        (this.isDomesticReceiverSenderAccount() && !this.props.enableResidentialFromAddress) ||
                        !this.props.enableResidentialFromAddress
                      }
                      afterUpdate={afterResidentialAddressUpdate}
                    />
                    {this.props.residentialAddressFromRequired &&
                    !context.state.pickupAddressResidential.value &&
                    !context.state.pickupFromDifferentAddress?.value ? (
                      <Typography
                        variant="body1"
                        color={theme => theme.palette.error.main}
                        sx={{
                          ml: '35px',
                          fontSize: '15px',
                        }}
                      >
                        {t('general|labels|inputs|required')}
                      </Typography>
                    ) : null}
                    <div className="l-grid--w-100pc-s frc__generic-row--wrapper">
                      <div className="frc__address-book-btn l-grid--w-100pc-s">
                        <Button
                          id="addressBook"
                          dataTestId="senderAddressBookBtn"
                          submit={() => this.showAddressPickup(CONST.SENDER)}
                          className="base-button"
                          label={t(`general|Address Book`)}
                        />
                      </div>

                      <div className="frc__company-search-position">
                        <Input
                          context={context}
                          config={config}
                          CONST={CONST}
                          regEx={config.regEx.everything}
                          label={t('general|Sender')}
                          name="pickupSender"
                          dataTestId="pickupSenderInp"
                          isRequired={context.state.pickupSender.isRequired}
                          value={context.state.pickupSender.value}
                          lengthCheck={[RegExp(`^.{1,${config.maxDefaultInputLength}}$`)]}
                          updateOnParent={(params, contextC) =>
                            addressAutoComplete(params, contextC, 'showCompanySearchMainSender')
                          }
                          cutTextLimit={config.maxDefaultInputLength}
                          hideAutoComplete={true}
                        />

                        {context.state.showCompanySearchMainSender ? (
                          <CompanyNameSearch
                            party={CONST.SENDER}
                            searchTerm={context.state.pickupSender.value}
                            context={context}
                            checkAppropriateCity={this.checkAppropriateCity}
                          />
                        ) : (
                          ''
                        )}

                        {context.state.pickupSender.error ? (
                          <Error
                            name="pickupSender"
                            className="frc__input--error"
                            message={
                              context.state.pickupAddressResidential.value
                                ? t('general|errors|Invalid Length Error', {
                                    name: 'name',
                                    max: config.maxDefaultInputLength,
                                  })
                                : t('general|errors|Company Error', {
                                    max: config.maxDefaultInputLength,
                                  })
                            }
                          />
                        ) : (
                          ''
                        )}
                      </div>
                    </div>
                    <div className="l-grid l-grid--w-100pc-s frc__generic-row--wrapper">
                      <div className=" l-grid--w-100pc-s">
                        <Input
                          context={context}
                          config={config}
                          CONST={CONST}
                          regEx={config.regEx.everything}
                          label={t('general|labels|inputs|Street')}
                          name="pickupStreet"
                          dataTestId="pickupStreetInp"
                          isRequired={context.state.pickupStreet.isRequired}
                          value={context.state.pickupStreet.value}
                          lengthCheck={[RegExp(`^.{0,${config.maxDefaultInputLength}}$`)]}
                          cutTextLimit={config.maxDefaultInputLength}
                        />
                        {context.state.pickupStreet.error ? (
                          <Error
                            name="pickupStreet"
                            className="frc__input--error"
                            message={t('general|errors|Street Error', {
                              max: config.maxDefaultInputLength,
                            })}
                          />
                        ) : (
                          ''
                        )}
                      </div>
                    </div>
                    <div className="l-grid l-grid--w-100pc-s frc__generic-row--wrapper">
                      <div
                        className=" l-grid--w-100pc-s l-grid--w-50pc-m"
                        onBlur={
                          context.state.pickupPostalCode.value.length >= config.minPostalCodeToPerfomCitySearch
                            ? () =>
                                this.checkAppropriateCity(
                                  'pickupCountry',
                                  'pickupPostalCode',
                                  'pickupCity',
                                  'sourcePostalCodeError',
                                  'sourceCityError',
                                )
                            : null
                        }
                      >
                        <PostalCodeInput
                          value={context.state.pickupPostalCode.value}
                          name="pickupPostalCode"
                          isRequired={context.state.pickupPostalCode.isRequired}
                          countryCode={context.state.pickupCountry.value}
                          error={
                            context.state.pickupPostalCode.error
                              ? POSTALCODE_ERRORS.validationError
                              : this.state.cityValidationError?.pickupCity?.postalCodeError
                              ? POSTALCODE_ERRORS.cityPostalCodeError
                              : !context.state.pickupFromDifferentAddress?.value && this.state?.sourcePostalCodeError
                              ? POSTALCODE_ERRORS.apiError
                              : undefined
                          }
                          context={context}
                          config={config}
                          CONST={CONST}
                        />
                      </div>
                      <div className="l-grid--w-100pc-s l-grid--w-50pc-m">
                        <Input
                          context={context}
                          config={config}
                          CONST={CONST}
                          regEx={config.regEx.everything}
                          label={t('general|labels|inputs|City')}
                          name="pickupCity"
                          dataTestId="pickupCityInp"
                          isRequired={context.state.pickupCity.isRequired}
                          value={context.state.pickupCity.value}
                          lengthCheck={[RegExp(`^.{1,${config.maxDefaultInputLength}}$`)]}
                          cutTextLimit={config.maxDefaultInputLength}
                          afterUpdate={
                            this.state.cityValidationError.pickupCity
                              ? () => this.checkIfEnteredAndSuggestedCityIsValid('pickupCity')
                              : null
                          }
                          afterChangeOnBlur={
                            !(
                              this.state.cityValidationError.pickupCity &&
                              this.state.cityValidationError.pickupCity.mismatchError
                            ) &&
                            context.state.pickupPostalCode.value.length >= config.minPostalCodeToPerfomCitySearch &&
                            context.state.pickupCountry.value === context?.user?.accountCountryCode?.toUpperCase()
                              ? () =>
                                  this.checkAppropriateCity(
                                    'pickupCountry',
                                    'pickupPostalCode',
                                    'pickupCity',
                                    'sourcePostalCodeError',
                                    'sourceCityError',
                                  )
                              : null
                          }
                        />
                        {context.state.pickupCity.error ? (
                          <Error
                            name="pickupCity"
                            className="frc__input--error"
                            message={t('general|errors|City Error', {
                              max: config.maxDefaultInputLength,
                            })}
                          />
                        ) : this.state.cityValidationError.pickupCity &&
                          this.state.cityValidationError.pickupCity.mismatchError ? (
                          <div className="l-grid">
                            <Error
                              name="cityMismtachError"
                              className="frc__input--error"
                              message={t('general|errors|invalidCity')}
                            />
                            <span className="frc__dhl-red-color">
                              {'"'}
                              <span
                                className="frc__city-underline"
                                onClick={() => this.setAppropriateCity(false, 'pickupCity', 'sourceCityError')}
                              >
                                {this.state.cityValidationError.pickupCity.value}
                              </span>
                              {'" ?'}
                            </span>
                          </div>
                        ) : !context.state.pickupFromDifferentAddress.value ? (
                          this.state.sourceCityError && (
                            <Error
                              name="pickupFromDifferentAddresssourceCityError"
                              className="frc__input--error"
                              message={t('general|errors|City API Error', {
                                max: config.maxDefaultInputLength,
                              })}
                            />
                          )
                        ) : (
                          ''
                        )}
                      </div>
                    </div>
                    <div className=" l-grid--w-100pc-s frc__generic-row--wrapper">
                      <Select
                        selectClass="l-grid--w-100pc-s"
                        label={t(`general|Country`)}
                        name="pickupCountry"
                        isRequired={context.state.pickupCountry.isRequired}
                        values={
                          context.state.shipmentRange.value === INTERNATIONAL_SHIPMENT_RANGE &&
                          context.state.shipmentTypesImpOrExp.value === IMPORT_SHIPMENT_TYPE
                            ? sortCountries().filter(
                                // item => item.code.toLowerCase() !== context.state.user?.accountCountryCode,
                                item => item.code.toLowerCase() !== i18n.language.split('-')[0],
                              )
                            : config.domesticCountries.filter(
                                // item => item.code.toLowerCase() === context.state.user?.accountCountryCode,
                                item => item.code.toLowerCase() === i18n.language.split('-')[0],
                              )
                        }
                        disabled={
                          context.state.shipmentRange.value !== INTERNATIONAL_SHIPMENT_RANGE ||
                          (context.state.shipmentRange.value === INTERNATIONAL_SHIPMENT_RANGE &&
                            context.state.shipmentTypesImpOrExp.value === EXPORT_SHIPMENT_TYPE)
                        }
                        value={context.state.pickupCountry.value}
                        camparisonValue={{
                          value: context.state.deliveryCountry.value,
                          field: 'deliveryCountry',
                        }}
                        compare={context.state.shipmentRange.value === INTERNATIONAL_SHIPMENT_RANGE ? false : true}
                        context={context}
                        config={config}
                        CONST={{
                          API_DOMAIN: 'countries',
                        }}
                        afterUpdate={(contextC, params) => {
                          this.resetPostalCodeSuggestionError(contextC, 'pickupCity', 'differentPickupCity');
                          changeCountryCodePhonePrefix(contextC, params.value, 'pickupPhoneNumbers');
                        }}
                      />
                      {context.state.pickupCountry.error ? (
                        <Error
                          name="pickupCountry"
                          className="frc__input--error"
                          message={t(`general|Country Error`)}
                        />
                      ) : (
                        ''
                      )}
                      {context.state.pickupCountry.comparisonError ? (
                        <Error
                          name="pickupCountrycomparisonError"
                          className="frc__input--error"
                          message={t(`general|To From Country Error`)}
                        />
                      ) : (
                        ''
                      )}
                    </div>
                  </div>
                  <p className="frc__contact-details-label" />

                  <div className="frc__generic--background-white frc__generic-section--wrapper">
                    <div className="l-grid--w-100pc-s frc__generic-row--wrapper ">
                      <Input
                        context={context}
                        config={config}
                        CONST={CONST}
                        regEx={config.regEx.everything}
                        label={t(`general|Contact Name`)}
                        name="pickupName"
                        isRequired={context.state.pickupName.isRequired}
                        value={context.state.pickupName.value}
                        lengthCheck={[RegExp(`^.{1,${config.maxDefaultInputLength}}$`)]}
                        cutTextLimit={config.maxDefaultInputLength}
                      />
                      {context.state.pickupName.error ? (
                        <Error
                          name="pickupName"
                          className="frc__input--error"
                          message={t('general|errors|Contact Name Error', {
                            max: config.maxDefaultInputLength,
                          })}
                        />
                      ) : (
                        ''
                      )}
                    </div>

                    <div className="l-grid--w-100pc-s frc__generic-row--wrapper frc__generic-position-relative">
                      <Input
                        context={context}
                        config={config}
                        CONST={CONST}
                        label={t(`general|Contact E-mail`)}
                        name="pickupEmail"
                        isRequired={context.state.pickupEmail.isRequired}
                        value={context.state.pickupEmail.value}
                        lengthCheck={[RegExp(`^.{0,${config.maxEmailLength}}$`)]}
                        cutTextLimit={config.maxEmailLength}
                      />
                      {context.state.pickupEmail.error ? (
                        <Error
                          name="pickupEmail"
                          className="frc__input--error"
                          message={t('general|errors|E-mail Error', {
                            chars: config.maxEmailLength,
                          })}
                        />
                      ) : (
                        ''
                      )}
                      <Checkbox
                        context={context}
                        config={config}
                        CONST={CONST}
                        label={t('general|Send notification sender')}
                        name="pickupEmailConfirmation"
                        isRequired={false}
                        checked={context.state.pickupEmailConfirmation.value}
                        className="frc__email-checkbox-confirmation"
                      />
                    </div>

                    <div className="l-grid l-grid--w-100pc-s frc__generic-row--wrapper ">
                      {context.state.pickupPhoneNumbers.slice(0, 1).map((phoneNumber, ind) => (
                        <PhoneNumberV2
                          key={ind}
                          context={context}
                          config={config}
                          CONST={CONST}
                          index={ind}
                          number={phoneNumber.number}
                          code={phoneNumber.code}
                          error={phoneNumber.error}
                          groupName="pickupPhoneNumbers"
                          isRequired={phoneNumber.isRequired}
                        />
                      ))}
                    </div>
                  </div>
                  <div className="l-grid--w-100pc-s frc__generic-row--wrapper ">
                    <Input
                      context={context}
                      config={config}
                      CONST={CONST}
                      label={t('general|labels|inputs|Sender Reference')}
                      name="pickupSenderReference"
                      isRequired={false}
                      value={context.state.pickupSenderReference.value}
                      lengthCheck={[RegExp(`^.{0,${config.maxDefaultInputLength}}$`)]}
                      cutTextLimit={config.maxDefaultInputLength}
                    />
                    {context.state.pickupSenderReference.error ? (
                      <Error
                        name="pickupSenderReference"
                        className="frc__input--error"
                        message={t('general|errors|Invalid maxchar error', {
                          name: t('general|labels|inputs|Sender Reference'),
                          max: config.maxDefaultInputLength,
                        })}
                      />
                    ) : (
                      ''
                    )}
                  </div>

                  <div className="l-grid l-grid--w-100pc-s frc__generic-row--wrapper">
                    <div className=" l-grid--w-50pc-s l-grid--w-50pc-m-m">
                      <Checkbox
                        context={context}
                        config={config}
                        CONST={CONST}
                        label={t(`general|Add to address book`)}
                        name="addToAddressBookSender"
                        isRequired={false}
                        checked={context.state.addToAddressBookSender.value}
                        disabled={context.state.updateInAddressBookSender.value}
                      />
                    </div>
                    <div className="l-grid--w-50pc-s l-grid--w-50pc-m-m">
                      <Checkbox
                        context={context}
                        config={config}
                        CONST={CONST}
                        label={t('general|labels|inputs|Update in Address Book')}
                        name="updateInAddressBookSender"
                        isRequired={false}
                        checked={context.state.updateInAddressBookSender.value}
                        disabled={
                          !context.state.senderPickedFromAddressBook || context.state.addToAddressBookSender.value
                        }
                      />

                      {context.state.updateInAddressBookSender.value && (
                        <div className="frc__update-note">
                          {t(`general|Address`)} <strong>{context.state.pickupSender.value}</strong>{' '}
                          {t('general|will be updated')}
                        </div>
                      )}
                    </div>
                  </div>

                  {context.state.pickupFromDifferentAddress.value &&
                    !context.state.pickupAddressResidential.value &&
                    renderDifferentPickUpAddress(
                      context,
                      t,
                      this.state,
                      this.showAddressPickup,
                      this.checkAppropriateCity,
                      this.setAppropriateCity,
                      this.checkIfEnteredAndSuggestedCityIsValid,
                      this.props.enableResidentialFromAddress,
                      this.props.residentialAddressFromRequired,
                    )}
                </div>

                <div className="l-grid--w-100pc-s l-grid--w-48pc-m">
                  <h4 className="margin-bottom-1 frc__red-lined-title">{t('general|To')}</h4>
                  <div className="l-grid l-grid--w-100pc-s">
                    <div className="l-grid--w-100pc-s l-grid--hidden-m">
                      <p>{t(`general|Address Details`)}</p>
                    </div>
                    <div className="l-grid--w-100pc-s">
                      <Checkbox
                        label={t(`general|Deliver to a different address`)}
                        labelAfter={
                          '&nbsp;<span class="has-tooltip"> ' +
                          '<div class="frc__tooltip-container has-icon icon-information color-DHL-red c-fcp-quote--info-container"><div class="frc__tooltip-container-flyout">' +
                          t('general|deliverToDifferentAddressTooltip') +
                          '</div></div>' +
                          '</span>'
                        }
                        name="deliverToDifferentAddress"
                        isRequired={false}
                        checked={context.state.deliverToDifferentAddress.value}
                        context={context}
                        config={config}
                        CONST={CONST}
                        disabled={context.state.deliveryAddressResidential.value}
                        afterUpdate={this.afterDifferentAddressCheckboxUpdate}
                      />
                    </div>
                  </div>

                  <div className="frc__generic--background-white frc__generic-section--wrapper">
                    <Checkbox
                      labelAfter={
                        '&nbsp;<span class="has-tooltip"> ' +
                        '<div class="frc__tooltip-container has-icon icon-information color-DHL-red c-fcp-quote--info-container"><div class="frc__tooltip-container-flyout">' +
                        t(`general|Delivery residential address tooltip`) +
                        '</div></div>' +
                        '</span>'
                      }
                      label={t('general|This is a residential address')}
                      name="deliveryAddressResidential"
                      isRequired={false}
                      checked={context.state.deliveryAddressResidential.value}
                      context={context}
                      config={config}
                      CONST={CONST}
                      disabled={
                        context.state.deliverToDifferentAddress.value ||
                        context.state.shipmentPayer.value === RECEIVER_PAYER ||
                        this.isDomesticReceiverSenderAccount() ||
                        !this.props.enableResidentialToAddress
                      }
                      afterUpdate={afterResidentialAddressUpdate}
                    />
                    {this.props.residentialAddressToRequired &&
                    !context.state.deliveryAddressResidential.value &&
                    !context.state.deliverToDifferentAddress?.value ? (
                      <Typography
                        variant="body1"
                        color={theme => theme.palette.error.main}
                        sx={{
                          ml: '35px',
                          fontSize: '15px',
                        }}
                      >
                        {t('general|labels|inputs|required')}
                      </Typography>
                    ) : null}

                    <div className="l-grid--w-100pc-s frc__generic-row--wrapper">
                      <div className="frc__address-book-btn l-grid--w-100pc-s">
                        <Button
                          id="addressBook"
                          dataTestId="receiverAddressBookBtn"
                          submit={() => this.showAddressPickup(CONST.RECEIVER)}
                          className="base-button"
                          label={t(`general|Address Book`)}
                        />
                      </div>
                      <div className="frc__company-search-position">
                        <Input
                          context={context}
                          config={config}
                          CONST={CONST}
                          regEx={config.regEx.everything}
                          label={t('general|Receiver')}
                          name="deliveryReceiver"
                          isRequired={context.state.deliveryReceiver.isRequired}
                          value={context.state.deliveryReceiver.value}
                          lengthCheck={[RegExp(`^.{0,${config.maxDefaultInputLength}}$`)]}
                          updateOnParent={(params, contextC) =>
                            addressAutoComplete(params, contextC, 'showCompanySearchMainReceiver')
                          }
                          cutTextLimit={config.maxDefaultInputLength}
                          hideAutoComplete={true}
                        />

                        {context.state.showCompanySearchMainReceiver ? (
                          <CompanyNameSearch
                            party={CONST.RECEIVER}
                            searchTerm={context.state.deliveryReceiver.value}
                            context={context}
                            checkAppropriateCity={this.checkAppropriateCity}
                          />
                        ) : (
                          ''
                        )}
                        {context.state.deliveryReceiver.error ? (
                          <Error
                            name="deliveryReceiver"
                            className="frc__input--error"
                            message={
                              context.state.deliveryAddressResidential.value
                                ? t('general|errors|Invalid Length Error', {
                                    name: 'name',
                                    max: config.maxDefaultInputLength,
                                  })
                                : t('general|errors|Company Error', {
                                    max: config.maxDefaultInputLength,
                                  })
                            }
                          />
                        ) : (
                          ''
                        )}
                      </div>
                    </div>

                    <div className="l-grid l-grid--w-100pc-s frc__generic-row--wrapper">
                      <div className=" l-grid--w-100pc-s">
                        <Input
                          context={context}
                          config={config}
                          CONST={CONST}
                          regEx={config.regEx.everything}
                          label={t('general|labels|inputs|Street')}
                          name="deliveryStreet"
                          isRequired={context.state.deliveryStreet.isRequired}
                          value={context.state.deliveryStreet.value}
                          lengthCheck={[RegExp(`^.{0,${config.maxDefaultInputLength}}$`)]}
                          cutTextLimit={config.maxDefaultInputLength}
                        />
                        {context.state.deliveryStreet.error ? (
                          <Error
                            name="deliveryStreet"
                            className="frc__input--error"
                            message={t('general|errors|Street Error', {
                              max: config.maxDefaultInputLength,
                            })}
                          />
                        ) : (
                          ''
                        )}
                      </div>
                    </div>

                    <div className="l-grid l-grid--w-100pc-s frc__generic-row--wrapper">
                      <div
                        className=" l-grid--w-100pc-s l-grid--w-50pc-m"
                        onBlur={
                          context.state.deliveryPostalCode.value.length >= config.minPostalCodeToPerfomCitySearch
                            ? () =>
                                this.checkAppropriateCity(
                                  'deliveryCountry',
                                  'deliveryPostalCode',
                                  'deliveryCity',
                                  'destinationPostalCodeError',
                                  'destinationCityError',
                                )
                            : null
                        }
                      >
                        <PostalCodeInput
                          value={context.state.deliveryPostalCode.value}
                          name="deliveryPostalCode"
                          isRequired={context.state.deliveryPostalCode.isRequired}
                          countryCode={context.state.deliveryCountry.value}
                          error={
                            context.state.deliveryPostalCode.error
                              ? POSTALCODE_ERRORS.validationError
                              : this.state.cityValidationError?.deliveryCity?.postalCodeError
                              ? POSTALCODE_ERRORS.cityPostalCodeError
                              : !context.state.deliverToDifferentAddress?.value &&
                                this.state?.destinationPostalCodeError
                              ? POSTALCODE_ERRORS.apiError
                              : undefined
                          }
                          context={context}
                          config={config}
                          CONST={CONST}
                        />
                      </div>
                      <div className="l-grid--w-100pc-s l-grid--w-50pc-m">
                        <Input
                          context={context}
                          config={config}
                          CONST={CONST}
                          regEx={config.regEx.everything}
                          label={t('general|labels|inputs|City')}
                          name="deliveryCity"
                          isRequired={context.state.deliveryCity.isRequired}
                          value={context.state.deliveryCity.value}
                          lengthCheck={[RegExp(`^.{1,${config.maxDefaultInputLength}}$`)]}
                          cutTextLimit={config.maxDefaultInputLength}
                          afterUpdate={
                            this.state.cityValidationError.deliveryCity
                              ? () => this.checkIfEnteredAndSuggestedCityIsValid('deliveryCity')
                              : null
                          }
                          afterChangeOnBlur={
                            !(
                              this.state.cityValidationError.deliveryCity &&
                              this.state.cityValidationError.deliveryCity.mismatchError
                            ) &&
                            context.state.deliveryPostalCode.value.length >= config.minPostalCodeToPerfomCitySearch &&
                            context.state.deliveryCountry.value === context?.user?.accountCountryCode?.toUpperCase()
                              ? () =>
                                  this.checkAppropriateCity(
                                    'deliveryCountry',
                                    'deliveryPostalCode',
                                    'deliveryCity',
                                    'destinationPostalCodeError',
                                    'destinationCityError',
                                  )
                              : null
                          }
                        />
                        {context.state.deliveryCity.error ? (
                          <Error
                            name="deliveryCity"
                            className="frc__input--error"
                            message={t('general|errors|City Error', {
                              max: config.maxDefaultInputLength,
                            })}
                          />
                        ) : this.state.cityValidationError.deliveryCity &&
                          this.state.cityValidationError.deliveryCity.mismatchError ? (
                          <div className="l-grid">
                            <Error
                              name="cityMismtachError"
                              className="frc__input--error"
                              message={t('general|errors|invalidCity')}
                            />
                            <span className="frc__dhl-red-color">
                              {'"'}
                              <span
                                className="frc__city-underline"
                                onClick={() => this.setAppropriateCity(false, 'deliveryCity', 'destinationCityError')}
                              >
                                {this.state.cityValidationError.deliveryCity.value}
                              </span>
                              {'" ?'}
                            </span>
                          </div>
                        ) : !context.state.deliverToDifferentAddress.value ? (
                          this.state.destinationCityError && (
                            <Error
                              name="deliverToDifferentAddressdestinationCityError"
                              className="frc__input--error"
                              message={t('general|errors|City API Error', {
                                max: config.maxDefaultInputLength,
                              })}
                            />
                          )
                        ) : (
                          ''
                        )}
                      </div>
                    </div>

                    <div className=" l-grid--w-100pc-s frc__generic-row--wrapper ">
                      <Select
                        selectClass="l-grid--w-100pc-s"
                        label={t(`general|Country`)}
                        name="deliveryCountry"
                        isRequired={context.state.deliveryCountry.isRequired}
                        values={
                          context.state.shipmentRange.value === INTERNATIONAL_SHIPMENT_RANGE &&
                          context.state.shipmentTypesImpOrExp.value === EXPORT_SHIPMENT_TYPE
                            ? sortCountries().filter(
                                // item => item.code.toLowerCase() !== context.state.user?.accountCountryCode,
                                item => item.code.toLowerCase() !== i18n.language.split('-')[0],
                              )
                            : config.domesticCountries.filter(
                                item => item.code.toLowerCase() === i18n.language.split('-')[0],
                              )
                        }
                        disabled={
                          context.state.shipmentRange.value !== INTERNATIONAL_SHIPMENT_RANGE ||
                          (context.state.shipmentRange.value === INTERNATIONAL_SHIPMENT_RANGE &&
                            context.state.shipmentTypesImpOrExp.value === IMPORT_SHIPMENT_TYPE)
                        }
                        value={context.state.deliveryCountry.value}
                        camparisonValue={{
                          value: context.state.pickupCountry.value,
                          field: 'pickupCountry',
                        }}
                        compare={context.state.shipmentRange.value !== INTERNATIONAL_SHIPMENT_RANGE}
                        context={context}
                        config={config}
                        CONST={{
                          API_DOMAIN: 'countries',
                        }}
                        afterUpdate={(contextC, params) => {
                          this.resetPostalCodeSuggestionError(contextC, 'deliveryCity', 'differentDeliveryCity');
                          changeCountryCodePhonePrefix(contextC, params.value, 'deliveryPhoneNumbers');
                        }}
                      />
                      {context.state.deliveryCountry.error ? (
                        <Error
                          name="deliveryCountryError"
                          className="frc__input--error"
                          message={t(`general|Country Error`)}
                        />
                      ) : (
                        ''
                      )}
                      {context.state.deliveryCountry.comparisonError ? (
                        <Error
                          name="deliveryCountrycomparisonError"
                          className="frc__input--error"
                          message={t(`general|To From Country Error`)}
                        />
                      ) : (
                        ''
                      )}
                    </div>
                  </div>
                  <p className="frc__contact-details-label" />

                  <div className="frc__generic--background-white frc__generic-section--wrapper">
                    <div className="l-grid--w-100pc-s frc__generic-row--wrapper ">
                      <Input
                        context={context}
                        config={config}
                        CONST={CONST}
                        regEx={config.regEx.everything}
                        label={t(`general|Contact Name`)}
                        name="deliveryName"
                        isRequired={context.state.deliveryName.isRequired}
                        value={context.state.deliveryName.value}
                        lengthCheck={[RegExp(`^.{0,${config.maxDefaultInputLength}}$`)]}
                        cutTextLimit={config.maxDefaultInputLength}
                      />
                      {context.state.deliveryName.error ? (
                        <Error
                          name="deliveryName"
                          className="frc__input--error"
                          message={t('general|errors|Contact Name Error', {
                            max: config.maxDefaultInputLength,
                          })}
                        />
                      ) : (
                        ''
                      )}
                    </div>

                    <div className="l-grid--w-100pc-s frc__generic-row--wrapper frc__generic-position-relative">
                      <Input
                        context={context}
                        config={config}
                        CONST={CONST}
                        label={t(`general|Contact E-mail`)}
                        name="deliveryEmail"
                        isRequired={context.state.deliveryEmail.isRequired}
                        value={context.state.deliveryEmail.value}
                        lengthCheck={[RegExp(`^.{0,${config.maxEmailLength}}$`)]}
                        cutTextLimit={config.maxEmailLength}
                      />
                      {context.state.deliveryEmail.error ? (
                        <Error
                          name="deliveryEmail"
                          className="frc__input--error"
                          message={t('general|errors|E-mail Error', {
                            chars: config.maxEmailLength,
                          })}
                        />
                      ) : (
                        ''
                      )}
                      <Checkbox
                        context={context}
                        config={config}
                        CONST={CONST}
                        label={t('general|Send notification receiver')}
                        name="deliveryEmailConfirmation"
                        isRequired={false}
                        checked={context.state.deliveryEmailConfirmation.value}
                        className="frc__email-checkbox-confirmation"
                      />
                    </div>

                    <div className="l-grid l-grid--w-100pc-s frc__generic-row--wrapper ">
                      {context.state.deliveryPhoneNumbers.slice(0, 1).map((phoneNumber, ind) => (
                        <PhoneNumberV2
                          key={ind}
                          context={context}
                          config={config}
                          CONST={CONST}
                          index={ind}
                          number={phoneNumber.number}
                          code={phoneNumber.code}
                          error={phoneNumber.error}
                          groupName="deliveryPhoneNumbers"
                          isRequired={phoneNumber.isRequired}
                        />
                      ))}
                    </div>
                  </div>
                  <div className="l-grid--w-100pc-s frc__generic-row--wrapper">
                    <Input
                      context={context}
                      config={config}
                      CONST={CONST}
                      label={t('general|labels|inputs|Receiver Reference')}
                      name="deliveryReceiverReference"
                      isRequired={false}
                      value={context.state.deliveryReceiverReference.value}
                      lengthCheck={[RegExp(`^.{0,${config.maxDefaultInputLength}}$`)]}
                      cutTextLimit={config.maxDefaultInputLength}
                    />
                    {context.state.deliveryReceiverReference.error ? (
                      <Error
                        name="deliveryReceiverReference"
                        className="frc__input--error"
                        message={t('general|errors|Invalid maxchar error', {
                          name: t('general|labels|inputs|Receiver Reference'),
                          max: config.maxDefaultInputLength,
                        })}
                      />
                    ) : (
                      ''
                    )}
                  </div>

                  <div className="l-grid l-grid--w-100pc-s frc__generic-row--wrapper">
                    <div className=" l-grid--w-50pc-s l-grid--w-50pc-m-m">
                      <Checkbox
                        context={context}
                        config={config}
                        CONST={CONST}
                        label={t(`general|Add to address book`)}
                        name="addToAddressBookReceiver"
                        isRequired={false}
                        checked={context.state.addToAddressBookReceiver.value}
                        disabled={context.state.updateInAddressBookReceiver.value}
                      />
                    </div>
                    <div className="l-grid--w-50pc-s l-grid--w-50pc-m-m">
                      <Checkbox
                        context={context}
                        config={config}
                        CONST={CONST}
                        label={t('general|labels|inputs|Update in Address Book')}
                        name="updateInAddressBookReceiver"
                        isRequired={false}
                        checked={context.state.updateInAddressBookReceiver.value}
                        disabled={
                          !context.state.receiverPickedFromAddressBook || context.state.addToAddressBookReceiver.value
                        }
                      />

                      {context.state.updateInAddressBookReceiver.value && (
                        <div className="frc__update-note">
                          {t(`general|Address`)} <strong>{context.state.deliveryReceiver.value}</strong>{' '}
                          {t('general|will be updated')}
                        </div>
                      )}
                    </div>
                  </div>

                  {context.state.deliverToDifferentAddress.value &&
                    !context.state.deliveryAddressResidential.value &&
                    renderDifferentDeliveryAddress(
                      context,
                      t,
                      this.state,
                      this.showAddressPickup,
                      this.checkAppropriateCity,
                      this.setAppropriateCity,
                      this.checkIfEnteredAndSuggestedCityIsValid,
                      this.props.enableResidentialToAddress,
                      this.props.residentialAddressToRequired,
                    )}
                </div>
                {!context.state.generalError && (
                  <div className="l-grid--w-100pc-s frc__flexend">
                    <button
                      id="saveForFutureBtn"
                      className={
                        'base-button base-button--white l-grid--w-25pc-w frc__saveforfuturebtn ' +
                        (disableBtn ? ' disabled' : '') +
                        (this.state.saveForFutureLoader ? ' is-loading--right ' : '')
                      }
                      onClick={disableBtn ? null : () => this.handleSaveForFutureClick(t)}
                      disabled={disableBtn}
                    >
                      <span>{t(`portalOrder|Save for future`)}</span>
                    </button>
                    <button
                      id="goToShipmentDetailsBtn"
                      data-testid="next-button"
                      className={
                        'base-button base-button--wide l-grid--w-25pc-w' +
                        (disableBtn ? ' disabled' : '') +
                        (this.state.nextBtnLoader ? ' is-loading--right ' : '')
                      }
                      onClick={disableBtn ? null : () => this.onGoClick(context)}
                      disabled={disableBtn}
                    >
                      <span>{t('general|labels|buttons|Next Step')}</span>
                    </button>
                  </div>
                )}
                <div className="l-grid--w-100pc-s l-grid--right-s">
                  {this.state.addressBookError ||
                  this.state.addressBookMaxLimitReachedError ||
                  this.state.apiError ||
                  this.state.generalError ||
                  this.props.context.state.error ||
                  this.state.saveForFutureError ||
                  this.state.saveShipmentsError ? (
                    <Error
                      name="apiGeneralproductAPIerror"
                      message={
                        this.state.generalError
                          ? t('general|errors|Please fill properly all necessary fields')
                          : this.state.apiError
                          ? t(`general|Booking failed. Try again or call technical support.`)
                          : this.state.saveShipmentsError || this.state.saveForFutureError
                          ? t(`portalOrder|Shipment could not be saved`)
                          : this.state.addressBookMaxLimitReachedError
                          ? t(`general|Address book max limit error`)
                          : this.state.addressBookError
                          ? t(`general|Address book save update error`)
                          : ''
                      }
                    />
                  ) : (
                    ''
                  )}
                </div>
              </div>

              <div ref={this.loaderRef} className="frc__generic--loader-overlay" />
              {context.state.percentageText !== '' && (
                <ProgressBar percentage={context.state.percentage} percentageText={context.state.percentageText} />
              )}
            </>
          )}
        </Translation>
      </div>
    );
  }
}

export default connect(
  (state, ownProps) => {
    const {
      accountNumber,
      accountsWithProducts,
      enableResidentialFromAddress,
      enableResidentialToAddress,
      residentialAddressFromRequired,
      residentialAddressToRequired,
    } = state.portalOrder;
    const userAccountsAndProducts = rootApi.endpoints.getUserAccountsAndProducts.select()(state);
    const countryCode = ownProps.context.state.user?.countryCode?.toLowerCase();
    const products = rootApi.endpoints.getProducts.select({ countryCode })(state);
    return {
      accountNumber,
      accountsWithProducts,
      enableResidentialFromAddress,
      enableResidentialToAddress,
      products,
      residentialAddressFromRequired,
      residentialAddressToRequired,
      userAccountsAndProducts,
    };
  },
  {
    getUserAccountsAndProducts: rootApi.endpoints.getUserAccountsAndProducts.initiate,
    getProducts: rootApi.endpoints.getProducts.initiate,
    setAccountNumber,
    setEnableResidentialFromAddress,
    setEnableResidentialToAddress,
    setResidentialAddressFromRequired,
    setResidentialAddressToRequired,
  },
)(EnterPickupLocation);
