import { apiLinks } from 'config/api-config';
import { returnProperValueOrNull } from 'globals/utils/fieldValidations';
import { formatDate } from 'globals/utils/formatting';
import { apiRequest } from 'globals/utils/requests';
import { config, CONST } from '../../data-config';
import { validateTemplate } from './Template-validation/Template-validation';

import { INTERNATIONAL_SHIPMENT_RANGE, RECEIVER_PAYER, SENDER_PAYER } from '../../../globals/constants';
import { deleteTemplate } from '../CommonUtils';

export const toggleActiveTab = (currentTab, addClass) => {
  if (addClass) currentTab.current.classList.add('frc__app-tab--active');
  else currentTab.current.classList.remove('frc__app-tab--active');
};

export const performActionEvent = async (action, context) => {
  const { state } = context;

  switch (action) {
    case CONST.SAVE:
      const updateAction = await checkTemplateSubmit(context);
      updateAction.message = context.state.id ? 'Template Updated Successfully' : 'Template Created Successfully';

      return updateAction;

    case CONST.DELETE:
      const deleteAction = { message: 'Template Deleted Successfully' };
      const deleteCall = await deleteTemplate([state.id]);
      deleteAction.apiError = deleteCall;
      deleteAction.atLeastOneFieldFilled = true;

      return deleteAction;

    default:
      return;
  }
};

const checkTemplateSubmit = async context => {
  const { hasErrors, stateToSet, atLeastOneFieldFilled } = validateTemplate(context.state);

  let saveOrUpdateStatusError = false;

  // submit Add -> AtLeast one field filled; Edit -> Template Name + Atleast one filed filled
  if (!hasErrors && atLeastOneFieldFilled) {
    saveOrUpdateStatusError = await saveOrUpdateTemplate(context.state);
  } else {
    context.updateState(stateToSet);
  }

  return {
    validationError: hasErrors,
    apiError: saveOrUpdateStatusError.error || false,
    atLeastOneFieldFilled,
  };
};

export const saveOrUpdateTemplate = async state => {
  const resultMap = {};
  let url = apiLinks.saveTemplate;
  let method = 'POST';

  if (state.id) {
    url = apiLinks.updateTemplate.replace('{id}', state.id);
    method = 'PUT';
  }

  await apiRequest(url, method, {
    body: prepareTemplateData(state),
  })
    .then(result => {
      if (result.status === CONST.STATUS_OK) {
        resultMap.error = false;
      } else {
        resultMap.error = true;
      }
    })
    .catch(() => {
      resultMap.error = true;
    });

  return resultMap;
};

const prepareTemplateData = state => {
  const template = {
    status: CONST.IN_COMPLETE,
    templateName: state.templateName.value || getDefaultTemplateName(),
    templateOrder: '',
    templateType: config.templateType,

    termsOfDelivery: state.termsOfDelivery.value,
    importExport: state.shipmentTypesImpOrExp.value,
    productID: state.product.value,
    route: state.shipmentRange.value,
    whoPay: state.shipmentPayer.value,

    schedulePickup: state.pickupOption.value === 'schedulePickup',
    dropAtDHL: state.pickupOption.value === 'dropOffPackage',
    useExistingPickup: state.pickupOption.value === 'useAlreadyScheduled',

    pickupInstruction: state.pickupInstructions.value,
    deliveryInstruction: state.deliveryInstructions.value,

    sreference: state.pickupSenderReference.value,
    rreference: state.deliveryReceiverReference.value,

    // party address
    savedShipmentParties: formPartyData(state),
    // shipment details
    savedShipmentPieces: formShipmentData(state),
    // VAS
    savedShipmentVass: formAdditionalServicesData(state.additionalServices),

    totalLDM: state?.totals?.shipmentDetailsRows?.loadingMeter?.value || '',
  };

  // residential address and differnt pickup combination
  if (state.pickupFromDifferentAddress.value) {
    template.sresidential = state.pickupAddressResidential.value;
    template.sdifferent = state.pickupFromDifferentAddress.value;
  } else {
    template.sresidential = state.pickupAddressResidential.value;
    template.sdifferent = false;
  }

  if (state.deliverToDifferentAddress.value) {
    template.rresidential = state.deliveryAddressResidential.value;
    template.rdifferent = state.deliverToDifferentAddress.value;
  } else {
    template.rresidential = state.deliveryAddressResidential.value;
    template.rdifferent = false;
  }

  // sender account , other account
  if (state.accountNumber.display) {
    template.senderAccount = state.accountNumber.value || '';
    template.otherAccount = state.receiverNumber.value || state.thirdPartyNumber.value || '';
  } else if (state.receiverNumber.display) {
    template.senderAccount = state.receiverNumber.value || '';
    template.otherAccount = state.thirdPartyNumber.value || '';
  }

  template.isSenderAccSelect = state.accountNumber.isSelect || false;
  template.isOtherAccSelect = state.receiverNumber.isSelect || state.thirdPartyNumber.isSelect || false;

  if (state.id) {
    template.id = state.id;
  }

  return template;
};

const getDefaultTemplateName = () => `template ${formatDate(new Date())}`;

const formAdditionalServicesData = additionalServices => {
  const savedShipmentVass = [];

  additionalServices
    .filter(vas => vas.value)
    .forEach(additionalService => {
      if (additionalService.groups) {
        additionalService.groups.forEach((group, groupIndex) => {
          if (group.options) {
            group.options.forEach((optionList, optionIndex) => {
              optionList.forEach(option => {
                if (
                  config.OptionFieldTypes.inputTypes[option.type] ||
                  option.type === 'LTCdate' ||
                  config.OptionFieldTypes[option.type]
                ) {
                  savedShipmentVass.push({
                    id: option.id || '',
                    vas: additionalService.code,
                    vasField: option.optionCode,
                    vasFieldValue:
                      config.OptionFieldTypes.Telephone === option.type
                        ? option.value[0].code + '-' + option.value[0].number
                        : option.value,
                    groupIndex: groupIndex,
                    optionIndex: optionIndex,
                  });
                }
              });
            });
          }
        });
      } else {
        savedShipmentVass.push({
          id: additionalService.id || '',
          vas: additionalService.code,
          vasField: '',
        });
      }
    });

  return savedShipmentVass;
};

const formShipmentData = state => {
  const savedShipmentPieces = [];

  state.shipmentDetailsRows.forEach(shipmentRow => {
    const savedShipmentPiece = {
      id: shipmentRow.id || '',
      height: shipmentRow.height?.value || null,
      length: shipmentRow.length?.value || null,
      loadMeter: returnProperValueOrNull(shipmentRow.loadingMeter.value),
      quantity: shipmentRow.quantity?.value || null,
      type: shipmentRow.shipmentType.value,
      nonStackable: shipmentRow.nonStackable.value,
      volume: returnProperValueOrNull(shipmentRow.volume.value),
      weight: returnProperValueOrNull(shipmentRow.weight.value),
      width: shipmentRow.width?.value || null,
      goodsDescription: shipmentRow.goodsDescription?.value || null,
    };

    if (state.shipmentRange.value === INTERNATIONAL_SHIPMENT_RANGE) {
      savedShipmentPiece.shipmentMark = shipmentRow.shippingMark.value;
    }

    // dangerous goods sub group
    if (shipmentRow.dangerousGoods.value) {
      savedShipmentPiece.dangerousGoods = shipmentRow.dangerousGoodGroup.map(item => {
        return {
          adrClass: item.adrClass.value,
          unNumber: item.unNumber.value,
          flamePoint: item.flamePoint.value,
          packagingGroup: item.packagingGroup.value,
          tunnelDescriptionCode: item.tunnelDescriptionCode.value,
          properShippingName: item.properShippingName.value,
          technicalDescription: item.technicalDescription.value,
          limitedQuantity: item.limitedQuantity.value,
          grossWeight: item.grossWeight.value,
          netQuantity: item.netQuantity.value,
          numberOfUnits: item.numberOfUnits.value,
          packageCode: item.packageCode.value,
          quantityMeasurementUnitQualifier: item.quantityMeasurementUnitQualifier.value,
          environmentHazardous: item.environmentHazardous.value,
          marinePollutant: item.marinePollutant.value,
          marinePollutantName: item.marinePollutantName.value,
          exceptedQuantity: item.exceptedQuantity.value,
          emptyContainer: item.emptyContainer.value,
          waste: item.waste.value,
          dgmId: item.dgmId.value,
        };
      });
    }

    savedShipmentPieces.push(savedShipmentPiece);
  });

  return savedShipmentPieces;
};

const formPartyData = state => {
  const parties = [];

  parties.push(formData(state, 'pickup', SENDER_PAYER, 'sender'));
  parties.push(formData(state, 'delivery', RECEIVER_PAYER, 'receiver'));

  if (state.pickupFromDifferentAddress.value && !state.pickupAddressResidential.value) {
    parties.push(formData(state, 'differentPickup', SENDER_PAYER, 'differentSender'));
  }
  if (state.deliverToDifferentAddress.value && !state.deliveryAddressResidential.value) {
    parties.push(formData(state, 'differentDelivery', RECEIVER_PAYER, 'differentReceiver'));
  }

  return parties;
};

const formData = (state, type, name, customerType) => {
  return {
    id: state[type + 'Id'] || '',
    city: state[type + 'City']?.value || null,
    companyName: state[type + name].value || null,
    countryCode: state[type + 'Country'].value || null,
    postalCode: state[type + 'PostalCode'].value || null,
    street: state[type + 'Street'].value || null,

    email: state[type + 'Email'].value || null,
    emailConfirmation: state[type + 'EmailConfirmation'].value || null,
    name: state[type + 'Name'].value || null,
    phone: state[type + 'PhoneNumbers'][0].code + '-' + state[type + 'PhoneNumbers'][0].number,
    type: customerType,
  };
};
