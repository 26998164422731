const BE_ENDPOINT = process.env.REACT_APP_BACKEND_ENDPOINT_URL ? process.env.REACT_APP_BACKEND_ENDPOINT_URL : '';

const FCP_USER_API_ENDPOINT = BE_ENDPOINT + '/fcp-user-api';
const FCP_BOOKING_API_ENDPOINT = BE_ENDPOINT + '/fcp-booking-api';
const FCP_SAVED_SHIPMENT_API_ENDPOINT = BE_ENDPOINT + '/fcp-saved-shipment-api';
const FCP_PRODUCT_API_ENDPOINT = BE_ENDPOINT + '/fcp-product-api';
const FCP_QUOTE_API_ENDPOINT = BE_ENDPOINT + '/fcp-quote-api';
const FCP_POSTAL_CODE_API_ENDPOINT = BE_ENDPOINT + '/fcp-se-postalcode-api';
const FCP_LOCATION_API_ENDPOINT = BE_ENDPOINT + '/fcp-location-api';
const FCP_CMS_API_ENDPOINT = BE_ENDPOINT + '/fcp-cms-api';
const FCP_CONFIG_API_ENDPOINT = BE_ENDPOINT + '/fcp-config-api';

export const apiLinks = {
  getCollitypes: FCP_USER_API_ENDPOINT + '/user/collitypes/v1',
  putResetPassword: FCP_USER_API_ENDPOINT + '/security/password/v1',
  getUserData: FCP_USER_API_ENDPOINT + '/user/v1',
  updateUserData: FCP_USER_API_ENDPOINT + '/user/props/v1',
  updateUserLanguage: FCP_USER_API_ENDPOINT + '/user/v1/language',
  updateUserPrintSettings: FCP_USER_API_ENDPOINT + '/user/print-settings/v1',
  getUserPrintSettings: FCP_USER_API_ENDPOINT + '/user/print-settings/v1',
  deleteTMSAccount: FCP_USER_API_ENDPOINT + '/user/tmsaccount/v1',
  addTMSAccount: FCP_USER_API_ENDPOINT + '/user/tmsaccount/v1',
  deletePalletAccount: FCP_USER_API_ENDPOINT + '/user/pallet/v1',
  addPalletAccount: FCP_USER_API_ENDPOINT + '/user/pallet/v1',
  postResetPassword: FCP_USER_API_ENDPOINT + '/public/security/password/v1',
  postUser: FCP_USER_API_ENDPOINT + '/public/user/v1',
  getCaptcha: FCP_USER_API_ENDPOINT + '/public/security/captcha',
  postForgotPassword: FCP_USER_API_ENDPOINT + '/public/security/password/reset/v1',
  getBasicUserData: FCP_USER_API_ENDPOINT + '/user/accounts/allapproved/v1',
  logIn: FCP_USER_API_ENDPOINT + '/public/security/login/v1',
  refreshTokens: FCP_USER_API_ENDPOINT + '/public/security/refresh/v1',
  logOut: FCP_USER_API_ENDPOINT + '/security/logout/v1',
  login: FCP_USER_API_ENDPOINT + '/public/security/login/v1',
  rememberme: FCP_USER_API_ENDPOINT + '/rememberme',
  saveRememberme: FCP_USER_API_ENDPOINT + '/public/rememberme/{uuid}',
  getUsersAccountsAndProductsData: FCP_USER_API_ENDPOINT + '/user/accounts/v1',

  palletBalances: FCP_BOOKING_API_ENDPOINT + '/pallet/accountbalances/v1',
  postSaveAddress: FCP_BOOKING_API_ENDPOINT + '/portal/addressbook/',
  putUpdateAddress: FCP_BOOKING_API_ENDPOINT + '/portal/addressbook/{id}',
  getTransactionsData:
    FCP_BOOKING_API_ENDPOINT + '/pallet/txn/v1?palletAccNo={accountNumber}&maxNoOfRows={maxNoOfRows}',
  getHistoryCSV: FCP_BOOKING_API_ENDPOINT + '/pallet/gethistorycsv/v1?palletAccNo={accountNumber}&maxNoOfRows=150',
  addressSearch: FCP_BOOKING_API_ENDPOINT + '/portal/addressbook/search',
  exportCSV: FCP_BOOKING_API_ENDPOINT + '/portal/addressbook/download',
  countriesForFilter: FCP_BOOKING_API_ENDPOINT + '/portal/addressbook/countries',
  deleteContact: FCP_BOOKING_API_ENDPOINT + '/portal/addressbook/',
  saveContact: FCP_BOOKING_API_ENDPOINT + '/portal/addressbook/*',
  updateContact: FCP_BOOKING_API_ENDPOINT + '/portal/addressbook/{id}',
  downloadTemplate: FCP_BOOKING_API_ENDPOINT + '/portal/addressbook/download/template',
  uploadExcel: FCP_BOOKING_API_ENDPOINT + '/portal/addressbook/upload',
  createReturnShipment: FCP_BOOKING_API_ENDPOINT + '/portal/order/return-shipment/v1',
  downloadDocumentPortal:
    FCP_BOOKING_API_ENDPOINT + '/portal/order/document/v1?UniqueCode={UniqueCode}&fileName={fileName}',
  downloadDocumentPublic:
    FCP_BOOKING_API_ENDPOINT + '/public/order/document/v1?UniqueCode={UniqueCode}&fileName={fileName}',
  downloadDocuments: FCP_BOOKING_API_ENDPOINT + '/portal/order/documents/v1',
  downloadWaybillPDF: FCP_BOOKING_API_ENDPOINT + '/portal/pickup/pallet/document/v1',
  postOrderPickup: FCP_BOOKING_API_ENDPOINT + '/portal/pickup/pallet/empty/v1',
  uploadCustomDocumentsPortal: FCP_BOOKING_API_ENDPOINT + '/portal/order/document/upload/v1',
  uploadCustomDocumentsPublic: FCP_BOOKING_API_ENDPOINT + '/public/order/document/upload/v1',
  postPortalOrder: FCP_BOOKING_API_ENDPOINT + '/portal/order/shipment/v1',
  searchUpdateAddress: FCP_BOOKING_API_ENDPOINT + '/portal/addressbook/{id}',
  getCountryList: FCP_BOOKING_API_ENDPOINT + '/portal/addressbook/countries',
  getPaginatedAddressBook: FCP_BOOKING_API_ENDPOINT + '/portal/addressbook/paginated',
  postPortalPickupBooking: FCP_BOOKING_API_ENDPOINT + '/portal/pickup/v1',
  postPublicOrder: FCP_BOOKING_API_ENDPOINT + '/public/order/shipment/v1',
  postPublicPickupBooking: FCP_BOOKING_API_ENDPOINT + '/public/pickup/v1',

  savedShipmentData: FCP_SAVED_SHIPMENT_API_ENDPOINT + '/portal/savedshipment/search/paginated/v1',
  getShipmentDataByTemplateId: FCP_SAVED_SHIPMENT_API_ENDPOINT + '/portal/savedshipment/{templateType}/ids/v1',
  deleteSavedShipment: FCP_SAVED_SHIPMENT_API_ENDPOINT + '/portal/savedshipment/v1',
  getOneSavedShipment: FCP_SAVED_SHIPMENT_API_ENDPOINT + '/portal/savedshipment/{id}/v1',
  fetchTemplateList: FCP_SAVED_SHIPMENT_API_ENDPOINT + '/portal/savedshipment/search/paginated/v1',
  postAddSavedShipmentPortal: FCP_SAVED_SHIPMENT_API_ENDPOINT + '/portal/savedshipment/v1',
  postAddSavedShipmentPublic: FCP_SAVED_SHIPMENT_API_ENDPOINT + '/public/savedshipment/v1',
  favoritesShipment: FCP_SAVED_SHIPMENT_API_ENDPOINT + '/portal/savedshipment/favorites/v1',
  useTemplate: FCP_SAVED_SHIPMENT_API_ENDPOINT + '/portal/savedshipment/{id}/use/v1',
  getTemplate: FCP_SAVED_SHIPMENT_API_ENDPOINT + '/portal/savedshipment/{id}/v1',
  incrementTemplateStatictics: FCP_SAVED_SHIPMENT_API_ENDPOINT + '/portal/savedshipment/{id}/increment/usage/v1',
  downloadShipmentHistory: FCP_SAVED_SHIPMENT_API_ENDPOINT + '/portal/savedshipment/search/download/v1',
  saveTemplate: FCP_SAVED_SHIPMENT_API_ENDPOINT + '/portal/savedshipment/v1',
  updateTemplate: FCP_SAVED_SHIPMENT_API_ENDPOINT + '/portal/savedshipment/{id}/v1',
  savedShipments: FCP_SAVED_SHIPMENT_API_ENDPOINT + '/portal/savedshipment/v1',
  activeTracing: FCP_SAVED_SHIPMENT_API_ENDPOINT + '/portal/savedshipment/active-tracing',
  dangerousGoods: FCP_SAVED_SHIPMENT_API_ENDPOINT + '/public/dangerous-goods',

  emailQuote: FCP_QUOTE_API_ENDPOINT + '/email/emailQuote',
  printQuote: FCP_QUOTE_API_ENDPOINT + '/print/printquote',
  postQuoteToolProductMatches: FCP_QUOTE_API_ENDPOINT + '/productapi/productmatches',
  pricequote: FCP_QUOTE_API_ENDPOINT + '/pricequote/quoteforprice',
  deliveryDates: FCP_QUOTE_API_ENDPOINT + '/delivery-dates',

  fetchCityName: FCP_POSTAL_CODE_API_ENDPOINT + '/public/postalcodeapi/validate/{countryCode}/{postalCode}',
  postalCodeValidation: FCP_POSTAL_CODE_API_ENDPOINT + '/public/postalcodeapi/postalCodes/validate',

  getFCPAPIProducts: FCP_PRODUCT_API_ENDPOINT + '/public/product/v1',
  getPortalOrderVAS: FCP_PRODUCT_API_ENDPOINT + '/portal/order/{productCode}/vas/v1',
  getPortalQuoteVAS: FCP_PRODUCT_API_ENDPOINT + '/portal/quote/{productCode}/vas/v1',
  getPublicOrderVAS: FCP_PRODUCT_API_ENDPOINT + '/public/order/{productCode}/vas/v1',
  getPortalPickupVAS: FCP_PRODUCT_API_ENDPOINT + '/portal/pickup/{productCode}/vas/v1',
  getPublicPickupVAS: FCP_PRODUCT_API_ENDPOINT + '/public/pickup/{productCode}/vas/v1',

  getServicePoints: FCP_LOCATION_API_ENDPOINT + '/public/location/servicepoint/v2',
  collectionAtTerminal: FCP_LOCATION_API_ENDPOINT + '/public/location/terminal/v1',

  // FARM API
  getChargeableWeight: FCP_PRODUCT_API_ENDPOINT + '/public/farmapi/chargeableweight/calculationresults',
  postPublicOrderProductMatches: FCP_PRODUCT_API_ENDPOINT + '/public/farmapi/productmatches',

  getValidationResults:
    FCP_PRODUCT_API_ENDPOINT + '/public/farmapi/products/{productCode}/additionalservices/validationresults',
  getAdditionalServices: FCP_PRODUCT_API_ENDPOINT + '/public/farmapi/products/{productCode}/additionalservices',
  getAdditionalServicesExtended:
    FCP_PRODUCT_API_ENDPOINT +
    '/public/farmapi/products/{productCode}/additionalservices?fromCountryCode={pickupCode}&toCountryCode={deliveryCode}&payerCode={payerCode}',

  postGeoLocation: FCP_LOCATION_API_ENDPOINT + '/public/farmapi/homedeliverylocator/getadditionalservices',
  postGeoLocationValidator:
    FCP_LOCATION_API_ENDPOINT + '/public/farmapi/homedeliverylocator/validateadditionalservices',

  postTimeTable: FCP_PRODUCT_API_ENDPOINT + '/public/farmapi/timetable/gettimetable',

  postPublicPickupBookingMockup: 'http://www.mocky.io/v2/5d23003e2e00005200c3ed75',
  postPublicPickupBookingMockup400: 'http://www.mocky.io/v2/5d2303be2e0000d65fc3ed86',

  getAdditionalServicesMockup: '/mockups/vas-options.json',

  ltc: {
    zipvalidator: '/api/zip-validator-eurapid.json',
    precalc: '/api/leadtimes-precalc.json',
    commitedLeadtimes: '/api/v2/leadtimes-committed-delivery.json',
  },

  getPageCmsData: FCP_CMS_API_ENDPOINT + '/public/phrases?pageCode={pageCode}&lang={lang}&countryCode={country}',
  getCmsPhrases: FCP_CMS_API_ENDPOINT + '/public/phrases',
  getApplicationConfigValue: FCP_CMS_API_ENDPOINT + '/app-configs/key/{key}',
  getSystemUnderMaintenance: FCP_CONFIG_API_ENDPOINT + '/public/status/maintenance',
  getScheduledMaintenance: FCP_CONFIG_API_ENDPOINT + '/status/scheduled-maintenance',

  getDisabledVasForProducts: FCP_CONFIG_API_ENDPOINT + '/public/products-disabled-vas',
  getCountryConfig: FCP_CONFIG_API_ENDPOINT + '/public/country',
  getConfigCountries: FCP_CONFIG_API_ENDPOINT + '/public/countries',
};
