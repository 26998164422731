import { configureStore } from '@reduxjs/toolkit';
import { rootApi } from './api/apiSlice';
import { rtkErrorLogger } from './middleware/rtkErrorLogger';
import { quoteReducer } from './quoteSlice';
import { portalOrderReducer } from './portalOrderSlice';
import { templatesReducer } from './templatesSlice';

export const store = configureStore({
  reducer: {
    [rootApi.reducerPath]: rootApi.reducer,
    portalOrder: portalOrderReducer,
    quote: quoteReducer,
    templates: templatesReducer,
  },
  middleware: getDefaultMiddleware => getDefaultMiddleware().concat(rootApi.middleware).concat(rtkErrorLogger),
});
