import React from 'react';
import { Checkbox } from 'components';

import './DataTable.css';

export default function Cell({
  context,
  id,
  hasCheckBox,
  content,
  fixed,
  header,
  column,
  requestSort,
  sortConfig,
  updateCheckbox,
  index,
  allRowsSelected,
  width,
  selector,
}) {
  const getSortIcon = name => {
    if (!sortConfig) {
      return;
    }
    return sortConfig.key === name ? sortConfig.direction : undefined;
  };

  const fixedClass = fixed ? 'frc__cell-fixed' : '';
  const selectorClass = selector ? 'frc__cell-' + selector : '';
  const className = `frc__data-cell ${fixedClass} ${selectorClass}`;
  const sortDirection = column ? getSortIcon(column.selector) : undefined;

  return header ? (
    <th key={id} className={className + `${fixed ? ' frc__fixed-th-first-column' : ' frc__th-column'}`}>
      <>
        {hasCheckBox && (
          <Checkbox
            inputClassName="c-form--element-checkbox"
            labelClassName=" c-form--element-label-checkbox"
            context={context}
            name={column.selector}
            checked={allRowsSelected}
            updateOnParent={params => updateCheckbox(params, true)}
          />
        )}
        <div
          className={`${hasCheckBox ? 'frc__table-content' : ''}`}
          onClick={column.sortable ? () => requestSort(column.selector) : null}
        >
          <span style={column.selector && sortDirection && column.headerInfo && { paddingRight: '2rem' }}>
            {content}

            {column.selector && (
              <span
                key={'sortIcon' + id}
                className={`frc__sort-icon-color ${
                  sortDirection ? (sortDirection === 'asc' ? 'icon-arrow-up' : 'icon-arrow-down') : ''
                }`}
              ></span>
            )}
          </span>

          {column.headerInfo && (
            <span className="frc__info-icon-margin-left  has-icon icon-information color-DHL-red c-fcp-quote--info-container">
              <div
                className="frc__tooltip-container-flyout frc__status-tooltip "
                dangerouslySetInnerHTML={{
                  __html: column.headerInfo,
                }}
              ></div>
            </span>
          )}
        </div>
      </>
    </th>
  ) : fixed ? (
    <th key={id} className={className + `${index !== 0 ? ' frc_table-fixed-column-border' : ''}`}>
      {hasCheckBox && (
        <Checkbox
          inputClassName="c-form--element-checkbox"
          labelClassName="frc__data-table-check-box-label c-form--element-label-checkbox"
          context={context}
          isRequired={false}
          name={column.id}
          index={index}
          checked={column.value}
          updateOnParent={params => updateCheckbox(params, false)}
        />
      )}
      <div className={`${hasCheckBox ? ' frc__table-content ' : ''}`}>{content}</div>
    </th>
  ) : (
    <td
      key={id}
      className={className + `${index !== 0 ? ' frc_table-fixed-column-border' : ''}`}
      style={width ? { width: width } : null}
    >
      <div>{content}</div>

      {hasCheckBox && (
        <Checkbox
          inputClassName="c-form--element-checkbox"
          labelClassName="frc__data-table-check-box-label c-form--element-label-checkbox"
          context={context}
          isRequired={false}
          name={column.id}
          index={index}
          checked={column.value}
          updateOnParent={params => updateCheckbox(params, false)}
        />
      )}
    </td>
  );
}
