import { globalConfig, GLOBAL_CONST } from 'globals/data-config-global';
import {
  DOMESTIC_SHIPMENT_RANGE,
  EXPORT_SHIPMENT_TYPE,
  IMPORT_SHIPMENT_TYPE,
  INTERNATIONAL_SHIPMENT_RANGE,
  RECEIVER_PAYER,
  SENDER_PAYER,
  SERVICE_POINT_PRODUCT_CODE,
  THIRD_PARTY_PAYER,
} from '../globals/constants';

const componentConfig = {
  tabs: {
    tab1: 'tab1',
    tab2: 'tab2',
    tab3: 'tab3',
    tab4: 'tab4',
  },
  resetOptionSchemaFieldTypes: ['Number', 'Decimal', 'NumericText'],
  OptionFieldTypes: {
    inputTypes: {
      Number: 'Number',
      Decimal: 'Decimal',
      NumericText: 'NumericText',
      Alphanumerical: 'Alphanumerical',
      Email: 'Email',
    },
    Currency: 'Currency',
    Select: 'Select',
    LtcDate: 'LTCdate',
    collectionAtTerminal: 'collectionAtTerminal',
    Telephone: 'Telephone',
    SubHeading: 'SubHeading',
    ListCOT: 'ListCOT',
    DateTime: 'DateTime',
    Date: 'Date',
    TickBox: 'TickBox',
    Hidden: 'Hidden',
  },
  surchargesToExcludeFromDisplayingOnSummary: [
    'TotalPriceIncVAT',
    'VAT',
    'TotalPrice',
    'FreightCost',
    'TotalPriceNoVATNOMarkup',
    'ChargedWeight',
    'Distance',
    'VolWeight',
    'OwnSurChargePerc',
    'OwnSurChargeAmount',
    'additionalServices',
  ],
  additionalServicesTransferredToPortalOrderWithoutData: ['fixedDeliveryDate', 'thermoWarm', 'thermoCold'],
  homeDeliveryCode: '401',
  agreementNo: '871368',
  homeDeliveryProductCodes: ['401', '402', '501', '502'],
  homeDeliveryMapping: {
    401: {
      code: '401',
      secondaryCode: '501',
      deliveryType: 'Delivery',
      name: 'dhlHomeDelivery',
    },
    402: {
      code: '402',
      secondaryCode: '502',
      deliveryType: 'Return',
      name: 'dhlHomeDeliveryReturn',
    },
    501: {
      code: '501',
      primaryCode: '401',
      deliveryType: 'Delivery',
      name: 'dhlHomeDelivery',
    },
    502: {
      code: '502',
      primaryCode: '402',
      deliveryType: 'Return',
      name: 'dhlHomeDeliveryReturn',
    },
  },
  homeDeliveryProducts: ['dhlHomeDelivery', 'dhlHomeDeliveryReturn'],
  shipmentRanges: [INTERNATIONAL_SHIPMENT_RANGE, DOMESTIC_SHIPMENT_RANGE],
  shipmentTypesImpOrExp: [IMPORT_SHIPMENT_TYPE, EXPORT_SHIPMENT_TYPE],
  ltcProducts: { ECE: [202, 232, 'ECI', 'ELI'], ERA: [233, 'ERI'], DOM: ['ECD', 'ELD', 'HDI', 'HDL', 'SPD', 'PPD'] },
  ltcEurapidProductCodes: ['233', 'ERI', 'PPD'],
  ltcNonEurapidProductCodes: ['202', '232', 'ECI', 'ECD', 'ELI', 'ELD', 'HDI', 'HDL', 'SPD'],
  shipmentPayers: [SENDER_PAYER, RECEIVER_PAYER, THIRD_PARTY_PAYER],
  shipmentPayersImport: [RECEIVER_PAYER, THIRD_PARTY_PAYER],
  serviceDescriptions: {
    211: {
      header: 'DHL Stycke',
    },
    212: {
      header: 'DHL Parti',
    },
    210: {
      header: 'DHL Pall',
    },
    102: {
      header: 'DHL Paket',
    },
    112: {
      header: 'DHL Parcel Connect Plus',
    },
    103: {
      header: 'DHL Service Point',
    },
    109: {
      header: 'DHL Parcel Connect',
    },
    401: {
      header: 'DHL Home Delivery',
    },
    501: {
      header: 'DHL Home Delivery',
    },
    402: {
      header: 'DHL Home Delivery Return',
    },
    502: {
      header: 'DHL Home Delivery Return',
    },
    202: {
      header: 'DHL Euroconnect',
    },
    205: {
      header: 'DHL Euroline',
    },
    232: {
      header: 'DHL EuroConnect Plus',
    },
    233: {
      header: 'DHL Eurapid',
    },
    104: {
      header: 'DHL Service Point Return',
    },
    107: {
      header: 'DHL Parcel Return Connect',
    },
    ECD: {
      header: 'DHL Freight Euroconnect Domestic',
    },
    ECI: {
      header: 'DHL Freight Euroconnect International',
    },
    ELD: {
      header: 'DHL Freight Euroline Domestic',
    },
    ELI: {
      header: 'DHL Freight Euroline International',
    },
    ERI: {
      header: 'DHL Freight Eurapid International',
    },
    HDL: {
      header: 'DHL Home Delivery Domestic',
    },
    SPD: {
      header: 'DHL Standard Pallet Domestic',
    },
    PPD: {
      header: 'DHL Premium Pallet Domestic',
    },
    HDI: {
      header: 'DHL Home Delivery International',
    },
  },
  ltcProductCodes: ['202', '205', '232', '233', 'ECI', 'ECD', 'ELI', 'ELD', 'ERI', 'HDI', 'HDL', 'SPD', 'PPD'],
  desiredPickupDateMainProductCode: [
    '202',
    '205',
    '232',
    'ECI',
    'ELI',
    'ECD',
    'ELD',
    'ERI',
    'HDI',
    'HDL',
    'SPD',
    'PPD',
  ],
  vasExcludedForFailedLTC: [
    'fixedDeliveryDate',
    // 'timeDefiniteLoading',
    // 'timeDefiniteUnloading',
    'priorityServiceG7',
    'priorityServiceG10',
    'priorityServiceG12',
  ],
  // VAS which will ignore the value in the options and call quote for price
  //    (e.g. fixed delivery Date - date is not available for user in quote, so we must ignore the value:
  vasQuoteForPriceCallIgnoringOptionsValue: ['fixedDeliveryDate'],
  vasSpecialCaseForPickupDate: ['timeDefiniteLoading'],
  vasSpecialCaseForDeliveryDate: ['timeDefiniteUnloading'],
  useResidentialAddressDisableProducts: [SERVICE_POINT_PRODUCT_CODE],
  circumferrenceProducts: [SERVICE_POINT_PRODUCT_CODE, '104', '109'],
  dhlPALL: '210',
  // MAPPING of the products
  allowedProducts: [
    { code: '212', mapName: 'dhlParti', product: 'DHL Parti' },
    { code: '210', mapName: 'dhlPall', product: 'DHL Pall' },
    { code: '211', mapName: 'dhlStycke', product: 'DHL Stycke' },
    { code: '102', mapName: 'dhlPaket', product: 'DHL Paket' },
    { code: '112', mapName: 'dhlParcelConnectPlus', product: 'DHL Parcel Connect Plus' },
    { code: SERVICE_POINT_PRODUCT_CODE, mapName: 'dhlServicePoint', product: 'DHL Service Point' },
    { code: '109', mapName: 'dhlParcelConnect', product: 'DHL Parcel Connect' },
    // { code: '107', mapName: 'dhlParcelReturnConnect', product: 'DHL Parcel Return Connect' }, // Disabled due to 1136, 1252
    { code: '104', mapName: 'dhlServicePointReturn', product: 'DHL Service Point Return' },
    { code: '401', mapName: 'dhlHomeDelivery', product: 'DHL Home Delivery' },
    { code: '402', mapName: 'dhlHomeDeliveryReturn', product: 'DHL Home Delivery Return' },
    //{ code: "501", mapName: "dhlHomeDelivery", product: "DHL Home Delivery" },
    //{ code: "502", mapName: "dhlHomeDeliveryReturn", product: "DHL Home Delivery Return" },
    { code: '202', mapName: 'dhlEuroConnect', product: 'DHL Euroconnect', alias: 'ECE' },
    { code: '205', mapName: 'dhlEuroLine', product: 'DHL Euroline', alias: 'ELI' },
    { code: '232', mapName: 'dhlEuroConnectPlus', product: 'DHL Euroconnect Plus' },
    { code: '233', mapName: 'dhlEurapid', product: 'DHL Eurapid', alias: 'ERA' },
    {
      code: 'ERI',
      mapName: 'ERI',
      product: 'DHL Freight Eurapid International',
      alias: 'ERA',
      range: INTERNATIONAL_SHIPMENT_RANGE,
    },
    {
      code: 'ECD',
      mapName: 'ECD',
      product: 'DHL Freight Euroconnect Domestic',
      alias: 'DOM',
      range: DOMESTIC_SHIPMENT_RANGE,
    },
    {
      code: 'ECI',
      mapName: 'ECI',
      product: 'DHL Freight Euroconnect International',
      alias: 'ECE',
      range: INTERNATIONAL_SHIPMENT_RANGE,
    },
    {
      code: 'ELD',
      mapName: 'ELD',
      product: 'DHL Freight Euroline Domestic',
      alias: 'DOM',
      range: DOMESTIC_SHIPMENT_RANGE,
    },
    {
      code: 'ELI',
      mapName: 'ELI',
      product: 'DHL Freight Euroline International',
      alias: 'ECE',
      range: INTERNATIONAL_SHIPMENT_RANGE,
    },
    {
      code: 'HDI',
      mapName: 'HDI',
      product: 'DHL Home Delivery International',
      alias: 'DOM',
      range: INTERNATIONAL_SHIPMENT_RANGE,
    },
    {
      code: 'HDL',
      mapName: 'HDL',
      product: 'DHL Home Delivery Domestic',
      alias: 'DOM',
      range: DOMESTIC_SHIPMENT_RANGE,
    },
    {
      code: 'SPD',
      mapName: 'SPD',
      product: 'DHL Standard Pallet Domestic',
      alias: 'DOM',
      range: DOMESTIC_SHIPMENT_RANGE,
    },
    {
      code: 'PPD',
      mapName: 'PPD',
      product: 'DHL Premium Pallet Domestic',
      alias: 'DOM',
      range: DOMESTIC_SHIPMENT_RANGE,
    },
  ],
  domesticProducts: [
    '401',
    '402',
    '501',
    '502',
    '212',
    '211',
    '210',
    '102',
    SERVICE_POINT_PRODUCT_CODE,
    '104',
    'ECD',
    'ELD',
  ],

  productsWithLocalValidationPerInput: ['401', '211'], // Home Delivery, Stycke
  shipmentHomeDeliveryStyckeValidation: {
    max_item_length: 299,
    max_weight_when_length_300_to_599: 50,
  },
  shipmentTypesFields: {
    'full pallet': {
      shipmentType: { value: 'full pallet', error: false },
      quantity: { value: 1, error: false, required: true },
      length: { value: '120', error: false, disabled: true, required: true, hidden: false },
      width: { value: '80', error: false, disabled: true, required: true, hidden: false },
      height: { value: '', error: false, required: true, hidden: false },
      weight: { value: '', error: false, required: true },
      volume: { value: '', error: false, disabled: true, hidden: true },
      loadingMeter: { value: '', error: false, hidden: false },
      longShipment: { value: false, error: false, hidden: true },
      nonStackable: { value: false, error: false, hidden: false },
    },
    'half pallet': {
      shipmentType: { value: 'half pallet', error: false },
      quantity: { value: 1, error: false, required: true },
      length: { value: '60', error: false, disabled: true, required: true, hidden: false },
      width: { value: '80', error: false, disabled: true, required: true, hidden: false },
      height: { value: '', error: false, required: true, hidden: false },
      weight: { value: '', error: false, required: true },
      volume: { value: '', error: false, hidden: true },
      loadingMeter: { value: '', error: false, hidden: false },
      longShipment: { value: false, error: false, hidden: true },
      nonStackable: { value: false, error: false, hidden: false },
    },
    parcel: {
      shipmentType: { value: 'parcel', error: false },
      quantity: { value: 1, error: false, required: true },
      length: { value: '', error: false, hidden: false },
      width: { value: '', error: false, hidden: false },
      height: { value: '', error: false, hidden: false },
      volume: { value: '', error: false },
      weight: { value: '', error: false, required: true },
      loadingMeter: { value: '', error: false, hidden: true },
      longShipment: { value: false, error: false, hidden: true },
      nonStackable: { value: false, error: false, hidden: true },
    },
    pallet: {
      shipmentType: { value: 'pallet', error: false },
      quantity: { value: 1, error: false, required: true },
      length: { value: '', error: false },
      width: { value: '', error: false },
      height: { value: '', error: false },
      volume: { value: '', error: false },
      weight: { value: '', error: false, required: true },
      loadingMeter: { value: '', error: false },
      longShipment: { value: false, error: false, hidden: true },
      nonStackable: { value: false, error: false, hidden: false },
    },
    unspecified: {
      shipmentType: { value: 'unspecified', error: false },
      quantity: { value: 1, error: false, required: true },
      length: { value: '', error: false },
      width: { value: '', error: false },
      height: { value: '', error: false },
      volume: { value: '', error: false },
      weight: { value: '', error: false, required: true },
      loadingMeter: { value: '', error: false },
      longShipment: { value: false, error: false, hidden: true },
      nonStackable: { value: false, error: false, hidden: false },
    },
    tanktainer: {
      shipmentType: { value: 'tanktainer', error: false },
      quantity: { value: 1, error: false, required: true },
      length: { value: '', error: false },
      width: { value: '', error: false },
      height: { value: '', error: false },
      volume: { value: '', error: false },
      weight: { value: '', error: false, required: true },
      loadingMeter: { value: '', error: false },
      longShipment: { value: false, error: false, hidden: true },
      nonStackable: { value: false, error: false, hidden: false },
    },
    'ro/bobbin': {
      shipmentType: { value: 'ro/bobbin', error: false },
      quantity: { value: 1, error: false, required: true },
      length: { value: '', error: false },
      width: { value: '', error: false },
      height: { value: '', error: false },
      volume: { value: '', error: false },
      weight: { value: '', error: false, required: true },
      loadingMeter: { value: '', error: false },
      longShipment: { value: false, error: false, hidden: true },
      nonStackable: { value: false, error: false, hidden: false },
    },
    bundle: {
      shipmentType: { value: 'bundle', error: false },
      quantity: { value: 1, error: false, required: true },
      length: { value: '', error: false },
      width: { value: '', error: false },
      height: { value: '', error: false },
      volume: { value: '', error: false },
      weight: { value: '', error: false, required: true },
      loadingMeter: { value: '', error: false },
      longShipment: { value: false, error: false, hidden: true },
      nonStackable: { value: false, error: false, hidden: false },
    },
    'bag/sack': {
      shipmentType: { value: 'bag/sack', error: false },
      quantity: { value: 1, error: false, required: true },
      length: { value: '', error: false },
      width: { value: '', error: false },
      height: { value: '', error: false },
      volume: { value: '', error: false },
      weight: { value: '', error: false, required: true },
      loadingMeter: { value: '', error: false },
      longShipment: { value: false, error: false, hidden: true },
      nonStackable: { value: false, error: false, hidden: false },
    },
    bale: {
      shipmentType: { value: 'bale', error: false },
      quantity: { value: 1, error: false, required: true },
      length: { value: '', error: false },
      width: { value: '', error: false },
      height: { value: '', error: false },
      volume: { value: '', error: false },
      weight: { value: '', error: false, required: true },
      loadingMeter: { value: '', error: false },
      longShipment: { value: false, error: false, hidden: true },
      nonStackable: { value: false, error: false, hidden: false },
    },
    'box/case': {
      shipmentType: { value: 'box/case', error: false },
      quantity: { value: 1, error: false, required: true },
      length: { value: '', error: false },
      width: { value: '', error: false },
      height: { value: '', error: false },
      volume: { value: '', error: false },
      weight: { value: '', error: false, required: true },
      loadingMeter: { value: '', error: false },
      longShipment: { value: false, error: false, hidden: true },
      nonStackable: { value: false, error: false, hidden: false },
    },
    can: {
      shipmentType: { value: 'can', error: false },
      quantity: { value: 1, error: false, required: true },
      length: { value: '', error: false },
      width: { value: '', error: false },
      height: { value: '', error: false },
      volume: { value: '', error: false },
      weight: { value: '', error: false, required: true },
      loadingMeter: { value: '', error: false },
      longShipment: { value: false, error: false, hidden: true },
      nonStackable: { value: false, error: false, hidden: false },
    },
    cage: {
      shipmentType: { value: 'cage', error: false },
      quantity: { value: 1, error: false, required: true },
      length: { value: '', error: false },
      width: { value: '', error: false },
      height: { value: '', error: false },
      volume: { value: '', error: false },
      weight: { value: '', error: false, required: true },
      loadingMeter: { value: '', error: false },
      longShipment: { value: false, error: false, hidden: true },
      nonStackable: { value: false, error: false, hidden: false },
    },
    crate: {
      shipmentType: { value: 'crate', error: false },
      quantity: { value: 1, error: false, required: true },
      length: { value: '', error: false },
      width: { value: '', error: false },
      height: { value: '', error: false },
      volume: { value: '', error: false },
      weight: { value: '', error: false, required: true },
      loadingMeter: { value: '', error: false },
      longShipment: { value: false, error: false, hidden: true },
      nonStackable: { value: false, error: false, hidden: false },
    },
    'carton/package': {
      shipmentType: { value: 'carton/package', error: false },
      quantity: { value: 1, error: false, required: true },
      length: { value: '', error: false },
      width: { value: '', error: false },
      height: { value: '', error: false },
      volume: { value: '', error: false },
      weight: { value: '', error: false, required: true },
      loadingMeter: { value: '', error: false },
      longShipment: { value: false, error: false, hidden: true },
      nonStackable: { value: false, error: false, hidden: false },
    },
    dolly: {
      shipmentType: { value: 'dolly', error: false },
      quantity: { value: 1, error: false, required: true },
      length: { value: '', error: false },
      width: { value: '', error: false },
      height: { value: '', error: false },
      volume: { value: '', error: false },
      weight: { value: '', error: false, required: true },
      loadingMeter: { value: '', error: false },
      longShipment: { value: false, error: false, hidden: true },
      nonStackable: { value: false, error: false, hidden: false },
    },
    'drum/barrel': {
      shipmentType: { value: 'drum/barrel', error: false },
      quantity: { value: 1, error: false, required: true },
      length: { value: '', error: false },
      width: { value: '', error: false },
      height: { value: '', error: false },
      volume: { value: '', error: false },
      weight: { value: '', error: false, required: true },
      loadingMeter: { value: '', error: false },
      longShipment: { value: false, error: false, hidden: true },
      nonStackable: { value: false, error: false, hidden: false },
    },
    ibc: {
      shipmentType: { value: 'ibc', error: false },
      quantity: { value: 1, error: false, required: true },
      length: { value: '', error: false },
      width: { value: '', error: false },
      height: { value: '', error: false },
      volume: { value: '', error: false },
      weight: { value: '', error: false, required: true },
      loadingMeter: { value: '', error: false },
      longShipment: { value: false, error: false, hidden: true },
      nonStackable: { value: false, error: false, hidden: false },
    },
    octabin: {
      shipmentType: { value: 'octabin', error: false },
      quantity: { value: 1, error: false, required: true },
      length: { value: '', error: false },
      width: { value: '', error: false },
      height: { value: '', error: false },
      volume: { value: '', error: false },
      weight: { value: '', error: false, required: true },
      loadingMeter: { value: '', error: false },
      longShipment: { value: false, error: false, hidden: true },
      nonStackable: { value: false, error: false, hidden: false },
    },
    'pallet gl': {
      shipmentType: { value: 'pallet gl', error: false },
      quantity: { value: 1, error: false, required: true },
      length: { value: '', error: false },
      width: { value: '', error: false },
      height: { value: '', error: false },
      volume: { value: '', error: false },
      weight: { value: '', error: false, required: true },
      loadingMeter: { value: '', error: false },
      longShipment: { value: false, error: false, hidden: true },
      nonStackable: { value: false, error: false, hidden: false },
    },
    'pallet - double extra large': {
      shipmentType: { value: 'pallet - double extra large', error: false },
      quantity: { value: 1, error: false, required: true },
      length: { value: '', error: false },
      width: { value: '', error: false },
      height: { value: '', error: false },
      volume: { value: '', error: false },
      weight: { value: '', error: false, required: true },
      loadingMeter: { value: '', error: false },
      longShipment: { value: false, error: false, hidden: true },
      nonStackable: { value: false, error: false, hidden: false },
    },
    'euro-pallet': {
      shipmentType: { value: 'euro-pallet', error: false },
      quantity: { value: 1, error: false, required: true },
      length: { value: '120', error: false, disabled: false, required: false, hidden: false },
      width: { value: '80', error: false, disabled: false, required: false, hidden: false },
      height: { value: '', error: false },
      volume: { value: '', error: false },
      weight: { value: '', error: false, required: true },
      loadingMeter: { value: '', error: false },
      longShipment: { value: false, error: false, hidden: true },
      nonStackable: { value: false, error: false, hidden: false },
    },
    'half pallet gl': {
      shipmentType: { value: 'half pallet gl', error: false },
      quantity: { value: 1, error: false, required: true },
      length: { value: '60', error: false, disabled: false, required: false, hidden: false },
      width: { value: '80', error: false, disabled: false, required: false, hidden: false },
      height: { value: '', error: false },
      volume: { value: '', error: false },
      weight: { value: '', error: false, required: true },
      loadingMeter: { value: '', error: false },
      longShipment: { value: false, error: false, hidden: true },
      nonStackable: { value: false, error: false, hidden: false },
    },
    'pallet - large': {
      shipmentType: { value: 'pallet - large', error: false },
      quantity: { value: 1, error: false, required: true },
      length: { value: '', error: false },
      width: { value: '', error: false },
      height: { value: '', error: false },
      volume: { value: '', error: false },
      weight: { value: '', error: false, required: true },
      loadingMeter: { value: '', error: false },
      longShipment: { value: false, error: false, hidden: true },
      nonStackable: { value: false, error: false, hidden: false },
    },
    'pallet - oversized': {
      shipmentType: { value: 'pallet - oversized', error: false },
      quantity: { value: 1, error: false, required: true },
      length: { value: '', error: false },
      width: { value: '', error: false },
      height: { value: '', error: false },
      volume: { value: '', error: false },
      weight: { value: '', error: false, required: true },
      loadingMeter: { value: '', error: false },
      longShipment: { value: false, error: false, hidden: true },
      nonStackable: { value: false, error: false, hidden: false },
    },
    'pallet - extra large': {
      shipmentType: { value: 'pallet - extra large', error: false },
      quantity: { value: 1, error: false, required: true },
      length: { value: '', error: false },
      width: { value: '', error: false },
      height: { value: '', error: false },
      volume: { value: '', error: false },
      weight: { value: '', error: false, required: true },
      loadingMeter: { value: '', error: false },
      longShipment: { value: false, error: false, hidden: true },
      nonStackable: { value: false, error: false, hidden: false },
    },
    'pallet euro size': {
      shipmentType: { value: 'pallet euro size', error: false },
      quantity: { value: 1, error: false, required: true },
      length: { value: '', error: false },
      width: { value: '', error: false },
      height: { value: '', error: false },
      volume: { value: '', error: false },
      weight: { value: '', error: false, required: true },
      loadingMeter: { value: '', error: false },
      longShipment: { value: false, error: false, hidden: true },
      nonStackable: { value: false, error: false, hidden: false },
    },
    'skeleton box': {
      shipmentType: { value: 'skeleton box', error: false },
      quantity: { value: 1, error: false, required: true },
      length: { value: '', error: false },
      width: { value: '', error: false },
      height: { value: '', error: false },
      volume: { value: '', error: false },
      weight: { value: '', error: false, required: true },
      loadingMeter: { value: '', error: false },
      longShipment: { value: false, error: false, hidden: true },
      nonStackable: { value: false, error: false, hidden: false },
    },
    'garment roller': {
      shipmentType: { value: 'garment roller', error: false },
      quantity: { value: 1, error: false, required: true },
      length: { value: '', error: false },
      width: { value: '', error: false },
      height: { value: '', error: false },
      volume: { value: '', error: false },
      weight: { value: '', error: false, required: true },
      loadingMeter: { value: '', error: false },
      longShipment: { value: false, error: false, hidden: true },
      nonStackable: { value: false, error: false, hidden: false },
    },
  },
  minChargeableWeightCheckProductCodes: ['212'],
  getAdditionalServicesFromAPI: true,
  PayerCodeBasedAdditionalServicesFiltering: ['3', '4'],

  maxAllowedPhoneNumbers: 1,
  maxServicesPerPage: 3,
  maxAllowedAccountNumbers: 10,
  maxAllowedPalletAccountNumbers: 99999,
  maxAllowedShipmentDetailsRows: 40,
  maxInstructionsLength: 140,

  dimensions: {
    min_chargeable_weight_check_product_codes: ['212'],
    insurance_enabled: 'yes',
    min_insurance_limit: 1,
    max_item_weight: 38000,
    min_item_weight: 0.1,
    max_shipment_weight: 38000,
    min_shipment_weight: 0.1,
    max_item_quantity: 999,
    min_item_quantity: 1,
    max_total_quantity: 999,
    min_total_quantity: 1,
    max_item_length: 1360,
    min_item_length: 15,
    max_item_width: 240,
    min_item_width: 11,
    max_item_height: 290,
    min_item_height: 1,
    max_item_volume: 150,
    min_item_volume: 0.001,
    min_item_loading_meter: 0.01,
    max_item_loading_meter: 20.8,
    max_item_height_stack_limit: 120,
    min_total_volume: 0,
    max_total_volume: 150,
    min_total_loading_meter: 0,
    max_total_loading_meter: 20.8,
    min_total_palletPlace: 0,
    max_total_palletPlace: 3,
    min_item_palletPlace: 0,
    max_item_palletPlace: 3,
    non_stackable_height: 125,
    loading_meter_const: 2.4,
    min_item_chargeableWeight: 0,
    max_item_chargeableWeight: 999999,
    min_total_chargeableWeight: 0,
    max_total_chargeableWeight: 999999,
    shipmentMark_length: 17,
    goodsDesc_length: 70,
  },

  // Metric constants
  // ==========================================================================
  volumeMetricDivider: 1000000,
  loadingMeterMetricDivider: 10000,

  payers: [SENDER_PAYER, RECEIVER_PAYER, THIRD_PARTY_PAYER],
};

const COMPONENT_CONST = {
  API_DOMAIN: 'quoteTool',
  ANALYTICS: 'quote tool',
  PUBLIC_BOOKING_ORDER: 'PBO',
  QUOTE_TOOL_PREFIX: 'LQT',
  CITY_MULTIPLE_ADDRESS: 'VARIOUS',
  DEFAULT_MAP_LAT: 61.24602,
  DEFAULT_MAP_LNG: 12.86366,
  DEFAULT_MAP_ZOOM: 14,
  SERVICE_POINTS: 5,
  MAPS_APP_ID: 'I8wx9067CrXN68UZzbaP',
  MAPS_APP_CODE: 'rK-rvUMmfhXsgKr-Volc4A',
  EMAIL_LENGTH: 65,
  CURRENCY_LENGTH: 3,
  TAIL_LIFT_LOADING_MAX_PIECE_WEIGHT: 999.9,
  MIN_MARKUP_VALUE: 0,
  MAX_MARKUP_PERCENTAGE: 99.9,
  MAX_MARKUP_AMOUNT: 9999,
  ONE_MONTH: 720,
};

export const config = Object.assign({}, globalConfig, componentConfig);
export const CONST = Object.assign({}, GLOBAL_CONST, COMPONENT_CONST);
