import { createSelector, createSlice } from '@reduxjs/toolkit';
import { findFirstCommonDate } from '../utils/dates';

const initialState = {
  // residential address:
  enableResidentialAddressInitially: true,
  enableResidentialAddress: false,
  enableResidentialToAddress: true,

  // pickup dates:
  pickupDates: [],
  productPickupDates: {},
  selectedPickupDate: undefined,
};

export const quoteSlice = createSlice({
  name: 'quote',
  initialState,
  reducers: {
    setEnableResidentialAddress: (state, action) => {
      state.enableResidentialAddress = action.payload;
    },
    setEnableResidentialToAddress: (state, action) => {
      state.enableResidentialToAddress = action.payload;
    },
    setEnableResidentialAddressInitially: (state, action) => {
      state.enableResidentialAddressInitially = action.payload;
    },
    setProductPickupDates: (state, action) => {
      state.productPickupDates = action.payload;
      state.pickupDates = [];
      state.selectedPickupDate = undefined;
    },
    setPickupDates: (state, action) => {
      state.pickupDates = action.payload;
    },
    setSelectedPickupDate: (state, action) => {
      state.selectedPickupDate = action.payload;
    },
    resetQuoteSlice: () => initialState,
  },
});

export const selectQuote = state => state.quote;
export const selectedPickupDateSelector = createSelector([selectQuote], ({ selectedPickupDate }) => ({
  selectedPickupDate,
}));
export const pickupDatesSelector = createSelector([selectQuote], ({ pickupDates }) => ({
  pickupDates,
}));
export const productPickupDatesSelector = createSelector([selectQuote], ({ productPickupDates }) => {
  const defaultPickupDate = findFirstCommonDate(productPickupDates);
  return {
    productPickupDates,
    defaultPickupDate,
  };
});

export const {
  setEnableResidentialAddress,
  setEnableResidentialAddressInitially,
  setEnableResidentialToAddress,
  setProductPickupDates,
  setPickupDates,
  setSelectedPickupDate,
  resetQuoteSlice,
} = quoteSlice.actions;

export const quoteReducer = quoteSlice.reducer;
