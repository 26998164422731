export const getServicePointOrDoorStepDeliveryAddress = (state, originalServicePoint) => {
  let servicePoint = {};
  const doorstepDeliveryVASchecked = state.additionalServices?.find(
    vas => vas.code === 'doorstepDelivery' && vas.value === true,
  );

  if (doorstepDeliveryVASchecked && state?.doorStepDeliveryAvailable) {
    servicePoint.address = state?.doorStepDelivery?.address || {};
    servicePoint.additionalAddressInfo = state?.doorStepDelivery?.address?.additionalAddressInfo;
    servicePoint.cityName = state?.doorStepDelivery?.address?.cityName;
    servicePoint.countryCode = state?.doorStepDelivery?.address?.countryCode;
    servicePoint.locationType = state?.doorStepDelivery?.address?.locationType;
    servicePoint.postalCode = state?.doorStepDelivery?.address?.postalCode;
    servicePoint.street = state?.doorStepDelivery?.address?.street;
    servicePoint.id = state?.doorStepDelivery?.id;
    servicePoint.email = state?.doorStepDelivery?.email;
    servicePoint.name = state?.doorStepDelivery?.name;
    servicePoint.phone = state?.doorStepDelivery?.phone;
  } else {
    servicePoint = JSON.parse(JSON.stringify(originalServicePoint));
  }

  return servicePoint;
};
