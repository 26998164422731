import { config, CONST } from './../../../data-config';
import getLanguage from 'globals/utils/getLanguage';
import { patchPALLPriceToFreightCost } from '../../EnterShipmentDetails/EnterShipmentDetails-helpers';

export const formQuoteSummaryPrice = (quotePriceMap, additionalServices, selectedProduct) => {
  // get language for description

  const language = getLanguage(config.regEx.urlValidation);
  const additionalServicesPrice = [];
  let selectedAdditionalServices = [];

  selectedAdditionalServices =
    additionalServices.length > 0 ? additionalServices.filter(additionalService => additionalService.value) : [];

  selectedAdditionalServices &&
    selectedAdditionalServices.forEach(({ code, translatedName, calculatedResult }) => {
      additionalServicesPrice.push({
        name: translatedName,
        code: code,
        price: quotePriceMap[code] ? quotePriceMap[code].calculatedResult : calculatedResult,
      });
    });

  quotePriceMap.TotalPriceNoVATNOMarkup = quotePriceMap.TotalPrice.calculatedResult;

  quotePriceMap.TotalPrice = quotePriceMap.TotalPrice.calculatedResult;
  quotePriceMap.additionalServices = additionalServicesPrice;

  if (selectedProduct.code === config.dhlPALL) {
    quotePriceMap.FreightCost = {};

    quotePriceMap.FreightCost.calculatedResult = `${patchPALLPriceToFreightCost(quotePriceMap)} ${CONST.CURRENCY_CODE}`;
  }

  quotePriceMap.surcharges = getSurcharges(quotePriceMap, language);

  return quotePriceMap;
};

const getSurcharges = (quotePriceMap, language) => {
  const surcharges = [];
  const isDefaultLanguage = config.locale.defaultLanguage === language;
  const exclusionList = excludeSurchargesListWithSelectedAS(quotePriceMap);
  let newSurchargeItem;

  // exclude items from final quote summary
  const surchargeItems = getItemsWhichAreNotPresentinSecondArray(Object.keys(quotePriceMap), exclusionList);

  surchargeItems &&
    surchargeItems.forEach(surchargeItem => {
      if (quotePriceMap[surchargeItem].value) {
        newSurchargeItem = JSON.parse(JSON.stringify(quotePriceMap[surchargeItem]));
        newSurchargeItem.label = isDefaultLanguage ? newSurchargeItem.descriptionEng : newSurchargeItem.description;
        surcharges.push(newSurchargeItem);
      }
    });

  return surcharges;
};

const excludeSurchargesListWithSelectedAS = quotePriceMap => {
  const exclusionList = config.surchargesToExcludeFromDisplayingOnSummary;

  quotePriceMap.additionalServices && quotePriceMap.additionalServices.forEach(AD => exclusionList.push(AD.code));

  return exclusionList;
};

const getItemsWhichAreNotPresentinSecondArray = (array1, array2) => {
  return array1.filter(item => array2.indexOf(item) === -1);
};
