import { apiLinks } from '../../config/api-config';
import { rootApi } from './apiSlice';

const cmsApi = rootApi.injectEndpoints({
  endpoints: builder => ({
    getCmsPhrases: builder.query({
      query: ({ pageCode, lang, countryCode, isPreview = false }) => ({
        url: apiLinks.getCmsPhrases,
        params: { pageCode, lang, countryCode, isPreview },
      }),
      keepUnusedDataFor: 60 * 30,
    }),
  }),
});

export const { useGetCmsPhrasesQuery } = cmsApi;
