import React, { Component } from 'react';
import { Translation } from 'react-i18next';
import { RouteContext, RouteProvider } from '../../context/route-context';
import { ShipmentDetailsProvider, ShipmentDetailsContext } from '../../context/shipmentDetails-context';
import { DeliveryOptionsProvider, DeliveryOptionsContext } from '../../context/deliveryOptions-context';
import EnterShipmentDetails from '../EnterShipmentDetails/EnterShipmentDetails';
import DeliveryOptions from '../DeliveryOptions/DeliveryOptions';
import ReviewQuote from '../ReviewQuote/ReviewQuote';
import SideSummaryPanel from '../SideSummaryPanel/SideSummaryPanel';

import './AppTabs.css';
import { QuoteRoute } from '../EnterPickupLocation/QuoteRoute';
import { resetQuoteSlice } from '../../../store/quoteSlice';
import { connect } from 'react-redux';

class AppTabs extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tab1: { active: true, enabled: true, disabled: false, completed: true },
      tab2: { active: false, enabled: true, disabled: false, completed: false },
      tab3: { active: false, enabled: true, disabled: false, completed: false },
      tab4: { active: false, enabled: true, disabled: false, completed: false },
      currentTabIndex: 1,
    };
  }

  componentDidMount() {
    this.props.resetQuoteSlice();
  }

  deActiveAllTabs(disabled = false, exclude = []) {
    let stateObject = {};
    let tab1 = this.state.tab1;
    tab1.active = false;
    tab1.disabled = disabled;
    stateObject.tab1 = tab1;

    if (exclude.indexOf(2) === -1) {
      let tab2 = this.state.tab2;
      tab2.active = false;
      tab2.disabled = disabled;
      stateObject.tab2 = tab2;
    }

    if (exclude.indexOf(3) === -1) {
      let tab3 = this.state.tab3;
      tab3.active = false;
      tab3.disabled = disabled;
      stateObject.tab3 = tab3;
    }

    if (exclude.indexOf(4) === -1) {
      let tab4 = this.state.tab4;
      tab4.active = false;
      tab4.disabled = disabled;
      stateObject.tab4 = tab4;
    }

    this.setState(stateObject);
  }

  enableAndActiveTab = tabName => {
    let tabToEnable = this.state[tabName];
    tabToEnable.enabled = true;
    this.setState({ [tabName]: tabToEnable });

    this.activateTab(tabName);
  };

  getTabClassName = (tabName, currentIndex, completedIndex) => {
    let className = 'frc__app-tab';

    if (this.state[tabName].active) {
      className += ' frc__app-tab--active';
    }
    if (this.state[tabName].completed || currentIndex <= completedIndex) {
      className += ' frc__app-tab--completed';
    }

    return className;
  };

  activateTab = tabName => {
    if (!this.state[tabName].enabled) {
      return;
    }

    this.deActiveAllTabs();

    let tabToActivate = this.state[tabName];
    tabToActivate.active = true;
    this.setState({ [tabName]: tabToActivate });
  };

  previousTab = (tabName, index) => {
    if (!this.state[tabName].enabled || this.state.currentTabIndex <= index) {
      return;
    }

    let stateObj = JSON.parse(JSON.stringify(this.state));

    for (let i = 1; i <= 4; i++) {
      if (index < i) {
        stateObj['tab' + i].completed = false;
        stateObj['tab' + i].active = false;
      }
    }

    stateObj[tabName].active = true;
    stateObj[tabName].completed = true;
    stateObj.currentTabIndex = index;

    this.setState(stateObj);
  };

  currentTab = tabName => {
    if (!this.state[tabName].enabled) {
      return;
    }

    this.deActiveAllTabs();

    let tabToActivate = this.state[tabName];
    tabToActivate.active = true;
    tabToActivate.completed = true;
    let currentTabIndex = parseInt(tabName.split('tab')[1]);
    this.setState({ [tabName]: tabToActivate, currentTabIndex });
  };

  currentTabActive = tabName => {
    let tabToActivate = this.state[tabName];
    tabToActivate.active = true;
    let currentTabIndex = parseInt(tabName.split('tab')[1]);
    this.setState({ [tabName]: tabToActivate, currentTabIndex });
  };

  disableAllOtherTabs = (status, exclude, activeTab) => {
    this.deActiveAllTabs(status, exclude);
    this.currentTabActive(activeTab);
  };

  displayTabs = () => {
    let tabs = ['Route', 'Shipment Details', 'Delivery Options', 'Review quote'];
    let displayTabs = [];
    tabs.forEach((tab, index) => {
      let newIndex = index + 1;
      let currentTabIndex = this.state.currentTabIndex;

      displayTabs.push(
        <div
          key={newIndex}
          id={'fcp__app-title-' + tab}
          className={this.getTabClassName('tab' + newIndex, newIndex, currentTabIndex)}
          onClick={
            this.state['tab' + newIndex].disabled
              ? null
              : currentTabIndex >= newIndex
              ? () => {
                  this.previousTab('tab' + newIndex, newIndex);
                }
              : null
          }
        >
          <h3 className="frc__app-tab__title">
            <Translation>
              {t => (
                <>
                  {newIndex} &nbsp;{t(`general|${tab}`)}
                </>
              )}
            </Translation>
          </h3>
        </div>,
      );
    });

    return displayTabs;
  };

  render() {
    return (
      <>
        <RouteProvider>
          <DeliveryOptionsProvider>
            <ShipmentDetailsProvider>
              <div className="l-grid">
                <div className="l-grid--w-100pc-s l-grid--w-75pc-m">
                  <div className="frc__app-tabs">{this.displayTabs()}</div>
                  {this.state.tab1.active && (
                    <RouteContext.Consumer>
                      {routeContext => (
                        <ShipmentDetailsContext.Consumer>
                          {shipmentContext => (
                            <DeliveryOptionsContext.Consumer>
                              {deliveryContext => (
                                <QuoteRoute
                                  context={routeContext}
                                  shipmentContext={shipmentContext}
                                  deliveryContext={deliveryContext}
                                  nextTab={this.currentTab}
                                />
                              )}
                            </DeliveryOptionsContext.Consumer>
                          )}
                        </ShipmentDetailsContext.Consumer>
                      )}
                    </RouteContext.Consumer>
                  )}
                  {this.state.tab2.active && (
                    <RouteContext.Consumer>
                      {routeContext => (
                        <ShipmentDetailsContext.Consumer>
                          {context => (
                            <DeliveryOptionsContext.Consumer>
                              {deliveryContext => (
                                <EnterShipmentDetails
                                  context={context}
                                  routeContext={routeContext}
                                  deliveryContext={deliveryContext}
                                  previousTab={(tab, index) => this.previousTab(tab, index)}
                                  disableAllOtherTabs={this.disableAllOtherTabs}
                                  nextTab={this.currentTab}
                                />
                              )}
                            </DeliveryOptionsContext.Consumer>
                          )}
                        </ShipmentDetailsContext.Consumer>
                      )}
                    </RouteContext.Consumer>
                  )}

                  {this.state.tab3.active && (
                    <RouteContext.Consumer>
                      {routeContext => (
                        <ShipmentDetailsContext.Consumer>
                          {shipmentContext => (
                            <DeliveryOptionsContext.Consumer>
                              {context => (
                                <DeliveryOptions
                                  routeContext={routeContext}
                                  context={context}
                                  shipmentContext={shipmentContext}
                                  previousTab={(tab, index) => this.previousTab(tab, index)}
                                  disableAllOtherTabs={this.disableAllOtherTabs}
                                  nextTab={this.currentTab}
                                />
                              )}
                            </DeliveryOptionsContext.Consumer>
                          )}
                        </ShipmentDetailsContext.Consumer>
                      )}
                    </RouteContext.Consumer>
                  )}

                  {this.state.tab4.active && (
                    <RouteContext.Consumer>
                      {routeContext => (
                        <ShipmentDetailsContext.Consumer>
                          {shipmentContext => (
                            <DeliveryOptionsContext.Consumer>
                              {deliveryContext => (
                                <ReviewQuote
                                  routeContext={routeContext}
                                  shipmentContext={shipmentContext}
                                  deliveryContext={deliveryContext}
                                  activateTab={this.enableAndActiveTab}
                                  previousTab={(tab, index) => this.previousTab(tab, index)}
                                  disableAllOtherTabs={this.disableAllOtherTabs}
                                />
                              )}
                            </DeliveryOptionsContext.Consumer>
                          )}
                        </ShipmentDetailsContext.Consumer>
                      )}
                    </RouteContext.Consumer>
                  )}
                </div>

                <div className="l-grid--w-100pc-s l-grid--w-25pc-m frc__hide-summary-panel">
                  <RouteContext.Consumer>
                    {routeContext => (
                      <ShipmentDetailsContext.Consumer>
                        {shipmentContext => (
                          <DeliveryOptionsContext.Consumer>
                            {deliveryContext => (
                              <SideSummaryPanel
                                routeContext={routeContext}
                                shipmentContext={shipmentContext}
                                deliveryContext={deliveryContext}
                              />
                            )}
                          </DeliveryOptionsContext.Consumer>
                        )}
                      </ShipmentDetailsContext.Consumer>
                    )}
                  </RouteContext.Consumer>
                </div>
              </div>
            </ShipmentDetailsProvider>
          </DeliveryOptionsProvider>
        </RouteProvider>
      </>
    );
  }
}

const mapDispatchToProps = {
  resetQuoteSlice,
};

export default connect(null, mapDispatchToProps)(AppTabs);
