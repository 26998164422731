import { config, CONST } from '../../../data-config';
import { apiLinks } from 'config/api-config';
import { apiRequest } from 'globals/utils/requests';
import { getPickupPhoneNumber } from '../../EnterPickupLocation/EnterPickupLocation-helpers';
import { runPostalCodesValidationForGServices } from '../../../context/postal-codes-g-services-logic';

export const setSearchResultsAndPagination = (searchResults, state, pagination) => {
  const stateToSet = {};
  stateToSet.pagination = JSON.parse(JSON.stringify(state.paginationMaster));
  const properAddressResults = searchResults.error
    ? []
    : addCountryNameToAddressBook(searchResults.data.addressBookList);

  stateToSet.searchResults = properAddressResults;
  stateToSet.searchResultsMaster = properAddressResults;
  stateToSet.data = properAddressResults;
  stateToSet.apiError = searchResults.error;
  stateToSet.loader = false;

  stateToSet.pagination.total = searchResults.error ? 0 : searchResults.data.total;
  stateToSet.pagination.pageSize = pagination.pageSize;
  stateToSet.pagination.lastPage = searchResults.error ? 0 : searchResults.data.lastPage;
  stateToSet.pagination.page = searchResults.error ? 1 : searchResults.data.page;

  stateToSet.paginationMaster = JSON.parse(JSON.stringify(stateToSet.pagination));

  return stateToSet;
};

const addCountryNameToAddressBook = addresBookList => {
  addresBookList.forEach(addressBook => {
    addressBook.countryName = addressBook.countryCode;
  });

  return addresBookList;
};

export const resetPagination = () => {
  return {
    page: 1,
    total: 0,
    pageSize: config.paginationInitialNumberOfItems,
    lastPage: 1,
  };
};

export const getCountryList = async () => {
  const resultMap = {};

  await apiRequest(apiLinks.getCountryList)
    .then(result => {
      if (result.status === CONST.STATUS_OK) {
        resultMap.data = result.data;
      } else {
        resultMap.error = true;
      }
    })
    .catch(() => {
      resultMap.error = true;
    });

  return resultMap;
};

export const fetchCriteriaCountry = (party, ctxState) => {
  const isDefaultCountry =
    (party === CONST.RECEIVER && ctxState.deliveryCountry.value === config.defaultCountry) || party === CONST.SENDER;
  const isCriteriaCountry = ctxState.internationalProductsSelected && party === CONST.RECEIVER;
  const toCountries = ctxState.toCountries
    .filter(country => country.value !== '')
    .map(filteredCountry => filteredCountry.value);

  return { isDefaultCountry, isCriteriaCountry, toCountries };
};

export const postSearchResults = async (searchTerm, pagination, party, ctxState) => {
  const { isDefaultCountry, isCriteriaCountry, toCountries } = fetchCriteriaCountry(party, ctxState);
  const body = {
    countryCriteria: isDefaultCountry || isCriteriaCountry ? 'INCLUDE' : 'EXCLUDE',
    criteriaCountries: isDefaultCountry ? [config.defaultCountry] : toCountries,
    page: pagination && pagination.page ? pagination.page : 1,
    pageSize: pagination && pagination.pageSize ? pagination.pageSize : config.paginationInitialNumberOfItems,
    searchTerm: searchTerm,
  };

  return await addressSearchBackendCall(body);
};

export const addressSearchBackendCall = async body => {
  const resultMap = {};

  await apiRequest(apiLinks.addressSearch, 'POST', {
    body: body,
  })
    .then(result => {
      if (result.status === CONST.STATUS_OK) {
        resultMap.data = result.data;
      } else {
        resultMap.error = true;
      }
    })
    .catch(() => {
      resultMap.error = true;
    });

  return resultMap;
};

export const fillAddress = (context, address, party) => {
  let state = {};

  if (party === CONST.SENDER) {
    const phoneNumber = getPickupPhoneNumber(address.phone);

    state = {
      ...context.state,
      pickupCity: { error: false, value: address.city || '', isRequired: true },
      pickupCountry: {
        error: false,
        value: address.countryCode || '',
        comparisonError: false,
        isRequired: true,
      },
      pickupEmail: { error: false, value: address.email || '', isRequired: false },
      pickupName: { error: false, value: address.name || '', isRequired: true },
      pickupPostalCode: { error: false, value: address.postalCode || '', isRequired: true },
      pickupPhoneNumbers: phoneNumber,
      pickupSender: { error: false, value: address.companyName || '', isRequired: true },
      pickupStreet: { error: false, value: address.street || '', isRequired: true },
      confirmationEmail: { error: false, value: address.email || '', isRequired: true },
      pickupSenderReference: { value: address.reference || '', error: false },
      senderPickedFromAddressBook: true,
      senderAddressID: { value: address.id },
    };
  } else if (party === CONST.RECEIVER) {
    const phoneNumber = getPickupPhoneNumber(address.phone);
    state = {
      ...context.state,
      deliveryCity: { error: false, value: address.city || '', isRequired: true },
      deliveryCountry: {
        error: false,
        value: address.countryCode || '',
        comparisonError: false,
        isRequired: true,
      },
      deliveryEmail: { error: false, value: address.email || '', isRequired: false },
      deliveryName: { error: false, value: address.name || '', isRequired: false },
      deliveryPostalCode: { error: false, value: address.postalCode || '', isRequired: true },
      deliveryReceiver: { error: false, value: address.companyName || '', isRequired: false },
      deliveryStreet: { error: false, value: address.street || '', isRequired: false },
      deliveryReceiverReference: { value: address.reference || '', error: false },
      receiverPickedFromAddressBook: true,
      receiverAddressID: { value: address.id },
    };

    state.deliveryPhoneNumbers = phoneNumber;
    state.deliveryPhoneNumbers.isRequired = false;
  }

  state.showCompanySearchSender = false;
  state.showCompanySearchReceiver = false;

  context.extendedSetState(state, { afterUpdate: runPostalCodesValidationForGServices });
};

export const shipmentRangeIsAllowed = (selectedAddress, context, party) => {
  if (party === 'receiver' && context.state.internationalProductsSelected && selectedAddress.countryCode === 'SE')
    return false;
  return true;
};
