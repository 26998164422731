import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { formatDate, DATE_FORMAT } from 'globals/utils/formatting';
import { locales } from 'locale/datePicker';
import { useTranslation } from 'react-i18next';
import { getTimeTable } from '../../DeliveryOptions-helpers';
import DatePicker from 'react-datepicker';
import { Error } from 'components';
import { resetDateForLoadingUnloadingTimeDefiniteVas } from '../../../ServiceDetails/ServiceDetails-helpers/ServiceDetails-helpers';
import { useDispatch, useSelector } from 'react-redux';
import {
  pickupDatesSelector,
  selectedPickupDateSelector,
  setSelectedPickupDate,
} from '../../../../../store/portalOrderSlice';

const CheckboxDateTime = ({
  option,
  context,
  index,
  groupIndex,
  errorId,
  serviceIndex,
  optionParentIndex,
  config,
  loaderRef,
}) => {
  const { t } = useTranslation();
  const now = new Date();
  const dispatch = useDispatch();
  const [minDate, setMinDate] = useState(new Date(now.valueOf()));
  const [selectedDate, setSelectedDate] = useState(new Date(now.valueOf()));
  const [minTime, setMinTime] = useState(new Date(now.valueOf()));
  const [selectedTime, setSelectedTime] = useState(new Date(now.valueOf()));

  const { pickupDates } = useSelector(pickupDatesSelector);
  const { selectedPickupDate } = useSelector(selectedPickupDateSelector);

  const isTimeDefiniteLoading =
    config.vasSpecialCaseForPickupDate.indexOf(context.state.additionalServices[serviceIndex].code) !== -1;
  const isTimeDefiniteUnloading =
    config.vasSpecialCaseForDeliveryDate.indexOf(context.state.additionalServices[serviceIndex].code) !== -1;

  const timeDefiniteLoadingService = context.state.additionalServices.find(item => {
    return item.code === 'timeDefiniteLoading';
  });
  const timeDefiniteUnloadingService = context.state.additionalServices.find(item => {
    return item.code === 'timeDefiniteUnloading';
  });

  const timeDefiniteLoadingDate = useMemo(
    () => timeDefiniteLoadingService?.value && new Date(timeDefiniteLoadingService.groups[0].options[0][0].value),
    [timeDefiniteLoadingService],
  );

  const language = localStorage.getItem(config.locale.localStorageVariable) || config.locale.defaultLanguage;

  // when changed pickupMinDate => setup minDate + minTime
  useEffect(() => {
    const now = new Date();

    const pickupMinDate =
      context.state.pickupMinDate instanceof Date ? context.state.pickupMinDate : new Date(context.state.pickupMinDate);

    // when pickup Min Date > now => set minDate and min Time
    if (
      (isTimeDefiniteLoading || isTimeDefiniteUnloading) &&
      pickupMinDate &&
      now.getTime() < pickupMinDate.getTime()
    ) {
      const newMinDate = pickupMinDate;
      newMinDate.setHours(now.getHours());
      newMinDate.setMinutes(now.getMinutes());
      setMinDate(newMinDate);
      setMinTime(new Date(0, 0, 0, 0, 0));

      context.updateState({
        additionalServices: resetDateForLoadingUnloadingTimeDefiniteVas(
          context.state.additionalServices,
          context.state.pickupMinDate,
        ),
      });
    }
    // eslint-disable-next-line
  }, [isTimeDefiniteLoading, isTimeDefiniteUnloading, context.state.pickupMinDate]);

  // when changed pickupDate -> setup selected date and time
  useEffect(() => {
    const options = context.state.additionalServices[serviceIndex].groups[groupIndex].options[optionParentIndex];
    const optionDateTime = new Date(isTimeDefiniteLoading ? selectedPickupDate : options[index].value);
    const nowDateTime = new Date();
    const pickupDateTime = new Date(context.state.pickupDate.value);
    if (optionDateTime && optionDateTime.getTime() >= nowDateTime.getTime()) {
      setSelectedTime(optionDateTime);
    } else {
      setSelectedTime(nowDateTime);
    }
    if (isTimeDefiniteLoading) {
      setSelectedDate(pickupDateTime);
    } else if (isTimeDefiniteUnloading) {
      if (minDate.toDateString() !== pickupDateTime.toDateString()) {
        setMinDate(pickupDateTime);
      }
      if (selectedDate.getTime() < optionDateTime.getTime()) {
        setSelectedDate(optionDateTime);
      }
    } else {
      setSelectedDate(optionDateTime);
    }

    if (nowDateTime.toDateString() === optionDateTime.toDateString()) {
      // today
      setMinTime(new Date());
    } else {
      // future
      const minTime = new Date();
      minTime.setHours(0);
      minTime.setMinutes(0);
      setMinTime(minTime);
    }
    // eslint-disable-next-line
  }, [context.state.additionalServices]);

  const resetUnloadingDateIfNotLogicalToLoadingDate = useCallback(
    date => {
      let newDate = date;
      const pickupDateVas = context.state.additionalServices.find(
        vas => config.vasSpecialCaseForPickupDate.indexOf(vas.code) !== -1,
      );

      if (pickupDateVas && pickupDateVas.groups && pickupDateVas.groups.length > 0) {
        pickupDateVas.groups.forEach(group => {
          if (group && group.options) {
            group.options.forEach(optionList => {
              optionList.forEach(eachOption => {
                const optionSchema = eachOption.type === config.OptionFieldTypes.DateTime;

                if (optionSchema && optionSchema.value) {
                  const loadingDate = new Date(optionSchema.value);
                  const newUnloadingDate = new Date(date);

                  if (loadingDate.getTime() > newUnloadingDate.getTime()) {
                    newDate = loadingDate;
                  }
                }
              });
            });
          }
        });
      }

      return newDate;
    },
    [config.OptionFieldTypes.DateTime, config.vasSpecialCaseForPickupDate, context.state.additionalServices],
  );

  const makeTimeTableCallForSpecialCase = useCallback(
    (date, additionalService, stateObj) => {
      if (config.vasSpecialCaseForPickupDate.indexOf(additionalService.code) !== -1) {
        const pickupDate = formatDate(context.state.pickupDate.value);
        const newDate = formatDate(date);

        if (pickupDate !== newDate) {
          // when change date => call timeTable API to get new EDT or CDT
          getTimeTable(date, context, loaderRef.current, true, true, setSelectedPickupDate);
        } else {
          // date is same, but time was changed
          stateObj.additionalServices = resetDateForLoadingUnloadingTimeDefiniteVas(
            context.state.additionalServices,
            date,
          );
          dispatch(setSelectedPickupDate(new Date(date)?.toISOString()));
          stateObj.pickupDate = { value: new Date(date), error: false };
        }
      }
    },
    [config.vasSpecialCaseForPickupDate, context, loaderRef, dispatch],
  );

  const handleChange = useCallback(
    (date, time = false) => {
      // set Time Datepicker => same day => time is now, future day => time is 00.00
      const now = new Date();
      if (!time) {
        if (now.toDateString() === date.toDateString()) {
          // today
          setMinTime(now);
        } else {
          // future
          setMinTime(new Date(0, 0, 0, 0, 0));
        }
      }
      const stateObj = {};
      const additionalServices = context.state.additionalServices;
      let additionalService = {};

      additionalService = JSON.parse(JSON.stringify(additionalServices[serviceIndex]));
      const additionalServiceParentOption = additionalService.groups[groupIndex].options[optionParentIndex];
      const additionalServiceOption = additionalServiceParentOption[index];

      // set time
      if (time && date && additionalServiceOption.value) {
        const newDate = new Date(additionalServiceOption.value);
        newDate.setHours(date.getHours(), date.getMinutes(), 0);
        date = new Date(newDate);
        setSelectedTime(date);
      }

      if (config.vasSpecialCaseForDeliveryDate.indexOf(additionalService.code) !== -1) {
        date = resetUnloadingDateIfNotLogicalToLoadingDate(date);
      }

      // when there is changed date only and some time is already selected => set this time to new date
      if (date && !time && selectedTime) {
        date.setHours(selectedTime.getHours(), selectedTime.getMinutes(), 0);
      }

      if (date) {
        additionalServiceOption.value = date;
        setSelectedDate(date);
      } else {
        additionalServiceOption.value = now;
        setSelectedDate(now);
        setSelectedTime(now);
      }

      additionalServices[serviceIndex] = additionalService;
      stateObj.additionalServices = additionalServices;
      makeTimeTableCallForSpecialCase(date, additionalService, stateObj);
      context.updateState(stateObj);
    },
    [
      config.vasSpecialCaseForDeliveryDate,
      context,
      groupIndex,
      index,
      makeTimeTableCallForSpecialCase,
      optionParentIndex,
      resetUnloadingDateIfNotLogicalToLoadingDate,
      serviceIndex,
    ],
  );

  const getErrorMessage = () => {
    let errorMessage = '';

    if (option.error) {
      errorMessage = t('general|errors|Please select date');
    }

    return <Error name={errorId ? errorId : 'OptionDateTime'} className="frc__input--error" message={errorMessage} />;
  };

  const maxDate = useMemo(() => {
    new Date(new Date().setMonth(new Date().getMonth() + 1));
  }, []);

  /**
   * Compare time-definite-loading and time-definite-unloading dates
   * and set the correct time-definite-unloading
   */
  useEffect(() => {
    if (!timeDefiniteLoadingService || !timeDefiniteUnloadingService || !isTimeDefiniteUnloading) {
      return;
    }

    if (
      isTimeDefiniteUnloading &&
      timeDefiniteLoadingService.value &&
      timeDefiniteUnloadingService.value &&
      timeDefiniteLoadingDate.getTime() > selectedDate.getTime()
    ) {
      handleChange(timeDefiniteLoadingDate);
    }
  }, [
    selectedDate,
    context.state.additionalServices,
    groupIndex,
    optionParentIndex,
    serviceIndex,
    handleChange,
    isTimeDefiniteUnloading,
    timeDefiniteLoadingService,
    timeDefiniteUnloadingService,
    timeDefiniteLoadingDate,
  ]);

  /**
   * Compare time-definite-unloading and pickup date
   */
  useEffect(() => {
    if (!timeDefiniteUnloadingService || !isTimeDefiniteUnloading) {
      return;
    }

    if (
      isTimeDefiniteUnloading &&
      timeDefiniteUnloadingService.value &&
      new Date(context.state.pickupDate.value).getTime() > selectedDate.getTime()
    ) {
      /**
       * Time definite unloading is in the past, the pickup date is in the future,
       * so set the time definite unloading to the same value as pickup date
       */
      handleChange(new Date(context.state.pickupDate.value));
    }
  }, [
    selectedDate,
    handleChange,
    isTimeDefiniteUnloading,
    context.state.pickupDate.value,
    timeDefiniteUnloadingService,
  ]);

  return (
    <div className="l-grid--w-100pc-s frc__as-option--wrapper">
      {t(`additionalServices|${option.optionCode}`)}
      <div className="l-grid frc__as-option--wrapper l-grid--middle-s l-grid--between-s">
        <div className="l-grid l-grid--w-100pc-s l-grid--w-60pc-s-m l-grid--w-50pc-s-l l-grid--w-70pc-m l-grid--w-100pc-m-s">
          <div className="l-grid--w-50pc-s l-grid--w-100pc-s-s frc__datepicker">
            <DatePicker
              selected={selectedDate}
              className="c-form--element-base c-form--element-input c-calculator--input-field"
              showDisabledMonthNavigation
              minDate={minDate}
              maxDate={maxDate}
              onChange={date => handleChange(date)}
              locale={locales[language]}
              dateFormat={DATE_FORMAT}
              includeDates={pickupDates.map(item => new Date(item))}
            />
          </div>
          <div className="l-grid--w-50pc-s l-grid--w-100pc-s-s">
            <DatePicker
              selected={selectedTime}
              onChange={date => handleChange(date, true)}
              className="c-form--element-base c-form--element-input c-calculator--input-field"
              showTimeSelect
              showTimeSelectOnly
              timeIntervals={5}
              locale={locales[language]}
              dateFormat="HH:mm"
              timeFormat="HH:mm"
              timeCaption={t('general|labels|inputs|Time')}
              minTime={minTime}
              maxTime={new Date(0, 0, 0, 23, 59)}
            />
          </div>
        </div>
      </div>
      {option.error && option.errorMessage ? getErrorMessage() : ''}
    </div>
  );
};

export default CheckboxDateTime;
