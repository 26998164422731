import { v4 as uuidv4 } from 'uuid';
import { COUNTRY_SELECT_OPTIONS, isCountryEnabled, LANGUAGES } from '../locale/config';
import { IMPORT_SHIPMENT_TYPE_CODE } from './constants';
import { allCountries } from './data-countries';

const getDefaultCountryPhoneCode = () => {
  const country = COUNTRY_SELECT_OPTIONS.find(item => {
    return item.code.toLowerCase() === localStorage.getItem('country')?.toLowerCase();
  });
  if (country) {
    return `+${country.phone}`;
  }
  return '+46';
};

const globalConfig = {
  // Localization
  // ===========================================================================
  locale: {
    defaultLanguage: LANGUAGES.en,
    localStorageVariable: 'language',
  },
  defaultCountry: localStorage.getItem('country')?.toUpperCase(),
  defaultPostalCode: '26234',
  domesticCountries: [
    {
      value: '',
      name: 'Select country',
    },
  ].concat(
    allCountries.filter(country => {
      if (country.code && isCountryEnabled(country.code)) {
        return { value: country.code, example: country.example };
      }
      return false;
    }),
  ),
  currency: 'SEK',
  defaultCountryPhoneCode: getDefaultCountryPhoneCode(),
  minPostalCodeToPerfomCitySearch: 3,
  // prettier-ignore
  numberPostalCodeCountries: ['AL','AT','BY','BE','BA','BG','HR','CZ','CY','DK','EE','FI','FR','DE','GR','HU','IS','IT','LV','LI','LT','LU','MK','MC','NO','PL','PT','RO','RU','SM','RS','SK','SI','ES','SE','CH','TN','TR','UA','VA'],
  alphaNumericPostalCodeCountries: ['NL', 'IE'],
  ldm_calculation_countries: ['SE', 'FI'],
  ldm_modified_calculation_countries: ['DK', 'NO', 'FI', 'EE', 'LV', 'LT'],
  no_visible_city_for_postal_code_countries: ['BE'],
  productsFilteredAtProductSelection: ['501', '502', '99999'],
  phoneCountryCodes: [
    '+7',
    '+30',
    '+31',
    '+32',
    '+33',
    '+34',
    '+36',
    '+39',
    '+40',
    '+41',
    '+43',
    '+44',
    '+45',
    '+46',
    '+47',
    '+48',
    '+49',
    '+90',
    '+212',
    '+350',
    '+351',
    '+352',
    '+353',
    '+355',
    '+356',
    '+357',
    '+358',
    '+359',
    '+370',
    '+371',
    '+372',
    '+373',
    '+374',
    '+375',
    '+376',
    '+377',
    '+378',
    '+379',
    '+380',
    '+381',
    '+382',
    '+383',
    '+385',
    '+386',
    '+387',
    '+389',
    '+420',
    '+421',
    '+992',
    '+994',
    '+995',
    '+996',
    '+998',
  ],

  // RegEx limitations
  // ===========================================================================
  regEx: {
    currency: /^[a-zA-Z ]*$/,
    text: /^[\w ]*$/,
    alphaNumericWithDot: /^[a-zA-Z0-9\\.]*$/,
    number: /^\d*-?\d*$/,
    phoneNumber: /^\d*\s*-?\s*\d*$/,
    pureNumber: /^\d*$/,
    currencyAmount: /^\d*$/,
    float: /^\d*[.,]?\d*$/,
    textNumber: /^[a-zA-Z \d-_/]*$/,
    everything: /.*$/,
    email:
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    emailV2: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
    numberOfPallets: /^[-0-9]*$/,
    urlValidation: /^[A-Za-z]{2}-[A-Za-z]{2}/g,
    quantity: /^\d{0,3}$/,
    weight: /(^[^.,\D])\d{0,5}([.,]\d{0,2})?$|^$/,
    volume: /(^[^.,\D])\d{0,3}([.,]\d{0,4})?$|^$/,
    loadingMeter: /(^[^.,\D])\d{0,2}([.,]\d{0,2})?$|^$/,
    palletPlace: /(^[^.,\D])\d{0,2}(|[.,]|[.,]\d)$|^$/,
    length: /(^[^.,\D])\d{0,4}$|^$/,
    width: /(^[^.,\D])\d{0,3}$|^$/,
    height: /(^[^.,\D])\d{0,3}$|^$/,
    decimalPoint: '^\\d{{minLength},{maxLength}}.\\d{0,{decimalPoint}}$',
    password: /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*.])(?=.{10,})/,
    postalCodeSoft: /^[a-zA-Z0-9]*[-\s]?[a-zA-Z0-9]*$/, // used in input field to allow continous typing
    postalCodeStrict: /^[a-zA-Z0-9]+([-\s][a-zA-Z0-9]+)?$/, // used in final validation after submit
    postalCodeSoftSE: /^\d*-?\d*$/, // account country code SE does not allow whitespace
    postalCodeStrictSE: /^\d+(-\d+)?$/, // account country code SE does not allow whitespace
  },

  // MinMax input lengths
  // ===========================================================================

  // Route
  maxEmailLength: 64,
  maxDefaultInputLength: 35,
  maxPostalCode: 9,
  maxPhoneNumberDigits: 17,
  maxInstructionsLength: 140,
  maxDescriptionLength: 70,
  maxCaptcha: 5,
  maxVATNumberLength: 11,
  maxPalletDigits: 3,
  minAccountNumberLength: 5,
  maxAccountNumberLength: 15,
  defaultAccountNumberLength: 6,
  minCountryLength: 2,
  maxCountryLength: 3,
  maxShippingMarkLength: 17,
  maxMarkupDigits: 4,
  maxPrinterMilimeterBoundaries: 2,

  // Shipment details
  maxLengthDigits: 4,
  maxWidthDigits: 3,
  maxHeightDigits: 3,
  maxPalletPlaceDigits: 4,
  shipmentInputs: {
    weight: {
      maxIntegers: 5,
      maxDecimals: 1,
    },
    volume: {
      maxIntegers: 3,
      maxDecimals: 3,
    },
    palletPlace: {
      maxIntegers: 2,
      maxDecimals: 1,
      maxDecimalsMax: 1,
      fullPallet: 1,
      halfPallet: 0.5,
    },
    loadingMeter: {
      maxIntegers: 2,
      maxDecimals: 2,
    },
    sentInformationLength: 20,
    ekaerInformationLength: 15,
  },
  dhlPallEURHalfPallet: {
    maxWeight: 400,
    maxHeight: 150,
  },
  price: {
    maxDecimals: 2,
  },
  chargeableWeight: {
    maxDecimals: 2,
  },
  distance: {
    maxDecimals: 2,
  },

  portalOrderCookie: 'portalOrderTemplateCookie',
  bookingCookieName: 'startABooking',

  paginationNumberOfButtons: 3,
  paginationInitialNumberOfItems: 10, // IMP: paginationInitialNumberOfItems and paginationItemsPerRow (1st item) are interdependent; should have same value
  paginationItemsPerRow: [10, 25, 50, 100],
  toasterTimeoutInMilliSeconds: 3500,
  standardSearchDebounceTimeout: 500,
  standardDebounceTimeout: 250,
  smallDebounceTimeout: 100,
  dangerousGoodDimensions: {
    max_adr_class: 7,
    max_un_number: 4,
    max_flame_point: 8,
    max_tunnel_description_code: 6,
    max_gross_weight_or_net_quantity: 18,
    max_no_of_units: 15,
    max_package_code: 3,
    max_marine_pollutant_name: 70,
    max_dgm_id: 4,
  },

  dangerousGoodGroup: [
    {
      id: uuidv4(),
      adrClass: { value: '', error: false, required: true },
      unNumber: { value: '', error: false, required: true },
      flamePoint: { value: '', error: false },
      packagingGroup: { value: '', error: false },
      tunnelDescriptionCode: { value: '', error: false, required: true },
      properShippingName: { value: '', error: false, required: true },
      technicalDescription: { value: '', error: false },
      limitedQuantity: { value: false, error: false },
      grossWeight: { value: '', error: false, required: true },
      netQuantity: { value: '', error: false },
      numberOfUnits: { value: '', error: false, required: true },
      packageCode: { value: '', error: false },
      quantityMeasurementUnitQualifier: { value: 'KGM', error: false },
      environmentHazardous: { value: false, error: false, required: false },
      marinePollutant: { value: false, error: false, required: false },
      marinePollutantName: { value: '', error: false, required: true },
      exceptedQuantity: { value: false, error: false, required: false },
      emptyContainer: { value: false, error: false, required: false },
      waste: { value: false, error: false, required: false },
      dgmId: { value: '', error: false, required: false },
    },
  ],

  dangerousGoodsPackagingGroupValues: [
    {
      value: '',
      name: '',
    },
    {
      value: '1',
      name: IMPORT_SHIPMENT_TYPE_CODE,
    },
    {
      value: '2',
      name: 'II',
    },
    {
      value: '3',
      name: ' III',
    },
  ],

  dangerousGoodsUnitValues: [
    {
      value: 'KGM',
      name: 'kgm',
    },
    {
      value: 'LTR',
      name: 'ltr',
    },
  ],

  // VAS options
  conditionalGroupOptionsTypes: [
    { type: 1, delimiter: ',' }, // at least one options must be selected
    { type: 2, delimiter: '|' }, // only one option can be selectiong
  ],

  // Links
  // ===========================================================================
  pageLinks: {
    homeFooter: 'home/footer',
  },

  products_require_ldm_calculation: ['401', '402', '211', '212'],
  products_require_ppl_calculation: ['401', '402', '211', '212'],

  //products with return
  returnProducts: ['104', '107', '402', '502'],

  printDocumentsCategory: {
    shipmentDocuments: 'shipmentdocuments',
  },
  applicationConfigKeys: {
    systemMaintenance: 'IS_SYSTEM_MAINTENANCE',
  },
  checkApplicationMaintenanceInterval: 300000,

  // SHIPMENT / COLLI TYPES
  shipmentTypes: [
    {
      value: 'full pallet',
      dataRel: '701',
      name: '701',
      swedenOnly: true,
    },
    {
      value: 'half pallet',
      dataRel: '702',
      name: '702',
      swedenOnly: true,
    },
    {
      value: 'parcel',
      dataRel: 'PK',
      name: 'PK',
      swedenOnly: true,
    },
    {
      value: 'pallet',
      dataRel: 'PL',
      name: 'PL',
      swedenOnly: true,
    },
    {
      value: 'unspecified',
      dataRel: 'CLL',
      name: 'CLL',
      swedenOnly: true,
    },
    {
      value: 'tanktainer',
      dataRel: 'TK',
      name: 'TK',
      swedenOnly: false,
    },
    {
      value: 'ro/bobbin',
      dataRel: 'BB',
      name: 'BB',
      swedenOnly: false,
    },
    {
      value: 'bundle',
      dataRel: 'BE',
      name: 'BE',
      swedenOnly: false,
    },
    {
      value: 'bag/sack',
      dataRel: 'BG',
      name: 'BG',
      swedenOnly: false,
    },
    {
      value: 'bale',
      dataRel: 'BL',
      name: 'BL',
      swedenOnly: false,
    },
    {
      value: 'box/case',
      dataRel: 'BX',
      name: 'BX',
      swedenOnly: false,
    },
    {
      value: 'can',
      dataRel: 'CA',
      name: 'CA',
      swedenOnly: false,
    },
    {
      value: 'cage',
      dataRel: 'CG',
      name: 'CG',
      swedenOnly: false,
    },
    {
      value: 'crate',
      dataRel: 'CR',
      name: 'CR',
      swedenOnly: false,
    },
    {
      value: 'carton/package',
      dataRel: 'CT',
      name: 'CT',
      swedenOnly: false,
    },
    {
      value: 'dolly',
      dataRel: 'DL',
      name: 'DL',
      swedenOnly: false,
    },
    {
      value: 'drum/barrel',
      dataRel: 'DR',
      name: 'DR',
      swedenOnly: false,
    },
    {
      value: 'garment roller',
      dataRel: 'GR',
      name: 'GR',
      swedenOnly: false,
    },
    {
      value: 'ibc',
      dataRel: 'IBC',
      name: 'IBC',
      swedenOnly: false,
    },
    {
      value: 'octabin',
      dataRel: 'OC',
      name: 'OC',
      swedenOnly: false,
    },
    {
      value: 'pallet gl',
      dataRel: 'PAL',
      name: 'PAL',
      swedenOnly: false,
    },
    {
      value: 'pallet - double extra large',
      dataRel: 'PLD',
      name: 'PLD',
      swedenOnly: false,
    },
    {
      value: 'euro-pallet',
      dataRel: 'PLE',
      name: 'PLE',
      swedenOnly: false,
    },
    {
      value: 'half pallet gl',
      dataRel: 'PLH',
      name: 'PLH',
      swedenOnly: false,
    },
    {
      value: 'pallet - large',
      dataRel: 'PLL',
      name: 'PLL',
      swedenOnly: false,
    },
    {
      value: 'pallet - oversized',
      dataRel: 'PLO',
      name: 'PLO',
      swedenOnly: false,
    },
    {
      value: 'pallet - extra large',
      dataRel: 'PLX',
      name: 'PLX',
      swedenOnly: false,
    },
    {
      value: 'pallet euro size',
      dataRel: 'PLZ',
      name: 'PLZ',
      swedenOnly: false,
    },
    {
      value: 'skeleton box',
      dataRel: 'SK',
      name: 'SK',
      swedenOnly: false,
    },
  ],

  packageType: {
    'full pallet': '701',
    'half pallet': '702',
    parcel: 'PK',
    pallet: 'PL',
    unspecified: 'CLL',
    tanktainer: 'TK',
    'ro/bobbin': 'BB',
    bundle: 'BE',
    'bag/sack': 'BG',
    bale: 'BL',
    'box/case': 'BX',
    can: 'CA',
    cage: 'CG',
    crate: 'CR',
    'carton/package': 'CT',
    dolly: 'DL',
    'drum/barrel': 'DR',
    'garment roller': 'GR',
    ibc: 'IBC',
    octabin: 'OC',
    'pallet gl': 'PAL',
    'pallet - double extra large': 'PLD',
    'euro-pallet': 'PLE',
    'half pallet gl': 'PLH',
    'pallet - large': 'PLL',
    'pallet - oversized': 'PLO',
    'pallet - extra large': 'PLX',
    'pallet euro size': 'PLZ',
    'skeleton box': 'SK',
  },

  estimatedDeliveryNotVisibleForProducts: ['205', 'ELI', 'ELD'],
  disableCityFetchCountryList: ['BE', 'IE'],
  VASfixedDateOption: ['fixedDeliveryDate', 'timeDefiniteLoading', 'timeDefiniteUnloading'],
};

const GLOBAL_CONST = {
  STATUS_OK: 200,
  STATUS_ACCEPTED: 202,
  STATUS_NO_CONTENT: 204,
  LOADING_METER_PER_PALLET_PLACE: 0.4,
  HALF_LOADING_METER_PER_PALLET_PLACE: 0.2,
  CHARGEABLE_WEIGHT_DIVISOR: 1950,
  PER_PALLET_PLACE_DIVISOR: 780,
  BAD_REQUEST_ERROR: 400,
  NOT_FOUND: 404,
  UNPROCESSABLE_CONTENT: 422,
  TOO_MANY_REQUESTS_ERROR: 429,
  UNAUTHORIZED_ERROR: 401,
  NOT_ACCEPTABLE_ERROR: 406,
  CONFLICT_ERROR: 409,
  PASSWORD_MIN_LENGTH: 10,
  EDIT: 'edit',
  VIEW: 'view',
  CANCEL: 'cancel',
  DELETE: 'delete',
  SAVE: 'save',
  SAVE_NEW: 'saveNew',
  CONTINUE: 'continue',
  COMPLETED: 'COMPLETED',
  IN_COMPLETE: 'INCOMPLETE',
  BOOKED: 'BOOKED',
  IN_TRANSIT: 'INTRANSIT',
  ORDER_SENT: 'ORDERSENT',
  CONTINUE_BOOKING: 'continuebooking',
  DELIVERED: 'DELIVERED',
  HOURS_PER_MONTH: 720,
  SECONDS_PER_MINUTE: 60,
  CURRENCY_CODE: 'SEK',
  INLINE_TOOLTIP_PLACEHOLDER: '*',
  NO_INDEX: 'noIndex',
  PAGE_CODES: {
    LOGIN: 'login',
    LAYOUT: 'layout',
    REGISTRATION: 'registration',
  },
};

// Precalcs
globalConfig.min_nonstackable_loading_meter = 1 / Math.pow(10, globalConfig.shipmentInputs.loadingMeter.maxDecimals);

globalConfig.domesticCountries.forEach(country => (country.name = country.name || country.value));

export { GLOBAL_CONST, globalConfig };
