import { Box, Button, Grid, Stack, TextField, Typography } from '@mui/material';
import { apiLinks } from 'config/api-config';
import { debounce } from 'globals';
import getBaseUrl from 'globals/utils/getUrlBase';
import { apiRequest } from 'globals/utils/requests';
import React, { useEffect, useRef, useState } from 'react';
import {
  ManifestReceivedIcon,
  PalletIcon,
  ParcelIcon,
  ParcelTrackingIcon,
  TruckRoadFreightIcon,
} from '../../../assets/icons';
import { DashboardTile } from '../../../components/fcp-components/DashboardTile';
import { Section } from '../../../components/fcp-components/Section';
import { useGetUser } from '../../../hooks/api/useGetUser';
import { useCountryLanguage } from '../../../hooks/useCountryLanguage';
import { useQuote } from '../../../quote/useQuote';
import { CONST, config } from '../../data-config';
import RecentShipments from '../RecentShipments/RecentShipments';
import ShipmentDetails from '../RecentShipments/ShipmentDetails/ShipmentDetails';

import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import './AppDashboard.css';

import { ChevronRight } from '@mui/icons-material';
import { ShipmentsTable } from '../../../components/fcp-components/shipment-table';
import { ROUTES, useRouting } from '../../../router';

export default function Dashboard(props) {
  const { navigate } = useRouting();
  const { context, url } = props;
  const { enableQuote } = useQuote();

  const { t } = useTranslation();
  const { language } = useCountryLanguage();
  const { data: userAccountCountryCode } = useGetUser({ select: data => data?.user?.accountCountryCode });
  const [searchParams] = useSearchParams();

  const [savedShipment, setSavedShipment] = useState(null);
  const [loader, setLoader] = useState(null);

  const ENV_DEV = process.env.NODE_ENV === 'development';
  const shipmentId = searchParams.get('shipmentId');

  const isSweden = userAccountCountryCode?.toLowerCase() === 'se';

  const removeShipmentIdIfShipmentNotFound = () => {
    if (config.regEx.urlValidation.test(window.location.pathname.split('/')[1]) || ENV_DEV) {
      const newurl = window.location.protocol + '//' + window.location.host + window.location.pathname;
      window.history.pushState({ path: newurl }, '', newurl);
    }
  };

  const updatePrintList = debounce(shipmentsListToPrint => {
    props.context.updateState({
      shipmentsPrintList: shipmentsListToPrint,
    });
  }, config.standardDebounceTimeout);

  useEffect(() => {
    async function getShipmentDetailById(id) {
      setLoader(true);

      const perShipmentUrl = apiLinks.getOneSavedShipment.replace('{id}', id);

      await apiRequest(perShipmentUrl, 'GET')
        .then(result => {
          if (result.status === CONST.STATUS_OK) {
            setSavedShipment(result.data);
          } else {
            removeShipmentIdIfShipmentNotFound();
          }
        })
        .catch(() => {
          removeShipmentIdIfShipmentNotFound();
        });

      setLoader(false);
    }

    if (shipmentId) {
      getShipmentDetailById(shipmentId);
    } else {
      setSavedShipment(null);
    }

    // eslint-disable-next-line
  }, [shipmentId]);

  if (savedShipment) {
    return <ShipmentDetails languageCode={language} savedShipment={savedShipment} />;
  } else {
    return (
      <Box display="flex" flexDirection="column" pt={{ xs: 0, sm: 2 }} px={{ xs: 2, sm: 4 }}>
        {loader && <div className="frc__generic--loader-overlay is-loading" />}
        <Typography variant="h1">{t('general|pageTitles|dashboard')}</Typography>
        <Grid container spacing={3} mb={8} columns={15}>
          {enableQuote ? (
            <DashboardTile
              linkTo="../quote-tool"
              title={t('mainMenu|Get a Quote')}
              subtitle={t('dashboard|See price and transit times')}
              Icon={ManifestReceivedIcon}
              data-testid="calculatePriceBtn"
              dense={isSweden}
            />
          ) : null}
          <DashboardTile
            linkTo="../portal-order"
            title={t('dashboard|Order Entry')}
            subtitle={t('general|Create Shipment and Print')}
            Icon={TruckRoadFreightIcon}
            data-testid="orderEntryBtn"
            dense={isSweden}
          />
          <TrackShipment dense={isSweden} />
          {isSweden && (
            <>
              <DashboardTile
                linkTo="../portal-pickup"
                title={t('mainMenu|Schedule a Pickup')}
                subtitle={t('dashboard|Submit a Pickup Request')}
                Icon={ParcelIcon}
                data-testid="startPickupBtn"
                dense={isSweden}
              />
              <DashboardTile
                linkTo="../edit-profile#palletAccounts"
                title={t('dashboard|Pallet Accounts')}
                subtitle={t('dashboard|View your Pallet Account transactions')}
                Icon={PalletIcon}
                data-testid="managePalletAccount"
                dense={isSweden}
              />
            </>
          )}
        </Grid>

        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            gap: '16px',
          }}
        >
          <Typography variant="h2" mb={0}>
            {t(`dashboard|Recent Saved Shipments`)} ({context.state.savedShipments?.length || 0})
          </Typography>
          <Button variant="text" onClick={() => navigate(ROUTES.savedShipments)} endIcon={<ChevronRight />}  data-testid="viewAllShipmentsBtn2">
            {t(`dashboard|View All Shipments`)}
          </Button>
        </Box>
        <ShipmentsTable
          shipments={context.state.savedShipments}
          loading={context.state.isLoading}
          error={context.state.apiError}
          tableProps={{
            hideFooter: true,
          }}
          context={context}
          summaryMinWidth={250}
        />

        {context.state.savedShipments?.length ? (
          <Box textAlign="center" my="16px">
            <Button variant="outlined" onClick={() => navigate(ROUTES.savedShipments)} data-testid="viewAllShipmentsBtn">
              {t(`dashboard|View All Shipments`)}
            </Button>
          </Box>
        ) : null}

        <div className="l-grid l-grid--w-100pc-s">
          <div className="l-grid--w-100pc-s">
            <RecentShipments
              t={t}
              url={url}
              context={context}
              languageCode={language}
              baseUrl={getBaseUrl(ROUTES.portal)}
              onPrintCheckBoxSelected={updatePrintList}
            />
          </div>
        </div>
      </Box>
    );
  }
}

const TrackShipment = ({ dense }) => {
  const trackRef = useRef();
  const { t } = useTranslation();
  const { country, language } = useCountryLanguage();

  return (
    <Grid item xs={15} sm={dense ? 15 : 5} md={dense ? 3 : 5}>
      <Section
        display="flex"
        flexDirection="column"
        justifyContent="space-between"
        sx={{ height: '100%', bgcolor: '#f9f9f9', padding: { xs: '20px', lg: '32px' } }}
      >
        <Box display="flex" alignItems="center" gap={1}>
          <ParcelTrackingIcon sx={{ fontSize: 34, alignSelf: 'flex-start' }} />
          <Typography
            variant="h4"
            sx={{
              wordBreak: 'break-word',
            }}
          >
            {t('general|labels|buttons|Track Shipment')}
          </Typography>
        </Box>
        <Stack>
          <Typography
            variant="body1"
            sx={{
              wordBreak: 'break-word',
            }}
          >
            {t('dashboard|Enter your tracking number')}
          </Typography>
          <Box display="flex" flexDirection="row" gap={1} mt={0.5} flexWrap="wrap">
            <TextField
              id="tracking-number"
              variant="outlined"
              color="primary"
              placeholder="Tracking number"
              size="small"
              inputRef={trackRef}
              sx={{
                flexBasis: '90px',
                flexGrow: 5,
                '& .MuiOutlinedInput-root': {
                  '&.Mui-focused fieldset': {
                    borderColor: 'text.primary',
                  },
                },
              }}
            />
            <Button
              variant="contained"
              type="submit"
              color="primary"
              size="medium"
              disableElevation
              data-testid="trackBtn"
              sx={{
                flexGrow: 1,
              }}
              onClick={() =>
                window.open(
                  encodeURI(
                    `https://www.logistics.dhl/${country}-${language}/${ROUTES.tracking}?submit=1&tracking-id=${trackRef?.current?.value}`,
                  ),
                  '_blank',
                  'noopener, noreferrer',
                )
              }
            >
              {t('general|labels|buttons|Track')}
            </Button>
          </Box>
        </Stack>
      </Section>
    </Grid>
  );
};
