import { apiLinks } from 'config/api-config';
import { apiRequest, getCookie } from 'globals/utils/requests';
import { config, CONST } from '../../data-config';

const ENV_DEV = process.env.NODE_ENV === 'development';

export const setPickupAddressFromCookie = () => {
  if (getCookie('pickupAddress')) {
    try {
      const pickupAddress = JSON.parse(decodeURIComponent(getCookie('pickupAddress')));
      const phoneNumber = getPickupPhoneNumber(pickupAddress.phone);

      const stateObj = {
        pickupCity: { error: false, value: pickupAddress.address.cityName || '', isRequired: true },
        pickupCountry: {
          error: false,
          value: pickupAddress.address.countryCode?.toUpperCase() || '',
          comparisonError: false,
          isRequired: true,
        },
        pickupEmail: { error: false, value: pickupAddress.email || '', isRequired: false },
        pickupName: { error: false, value: pickupAddress.contactName || '', isRequired: true },
        pickupPostalCode: { error: false, value: pickupAddress.address.postalCode || '', isRequired: true },
        pickupPhoneNumbers: phoneNumber,
        pickupSender: { error: false, value: pickupAddress.name || '', isRequired: true },
        pickupStreet: { error: false, value: pickupAddress.address.street || '', isRequired: true },
        confirmationEmail: { error: false, value: pickupAddress.confirmationEmail || '', isRequired: true },
      };

      return stateObj;
    } catch (e) {
      ENV_DEV && console.log(e);
      return;
    }
  }
};

export const getCityName = async (countryCode, postalCode, cityName = false) => {
  const resultMap = {};

  let url = apiLinks.fetchCityName;
  url = url.replace('{countryCode}', countryCode);
  url = url.replace('{postalCode}', postalCode);

  if (cityName) {
    url = `${url}/${cityName}`;
  }

  await apiRequest(url, 'GET')
    .then(result => {
      if (result.status === CONST.STATUS_OK) {
        resultMap.data = result.data;
      } else {
        resultMap.error = true;
      }
    })
    .catch(() => {
      resultMap.error = true;
    });

  return resultMap;
};

export const clearDeliveryLocation = (context, returnClearedObject) => {
  const cityValidationError = JSON.parse(JSON.stringify(context.state.cityValidationError));
  delete cityValidationError['deliveryCity'];

  const obj = {
    deliveryReceiver: { value: '', error: false },
    deliveryEmail: { value: '', error: false },
    deliveryCountry: { value: config.defaultCountry, error: false },
    deliveryCompany: { value: '', error: false },
    deliveryVatNumber: { value: '', error: false },
    deliveryName: { value: '', error: false },
    deliveryStreet: { value: '', error: false },
    deliveryPostalCode: { value: '', error: false },
    deliveryCity: { value: '', error: false },
    deliveryPhoneNumbers: [{ code: config.defaultCountryPhoneCode, number: '', error: '' }],
    deliveryReceiverReference: { value: '', error: false },
    cityValidationError: cityValidationError,
  };

  if (returnClearedObject) {
    return obj;
  } else {
    context.updateState(obj);
  }
};

export const postalCodeValidationAPIRequest = async state => {
  let urlValidation = apiLinks.postalCodeValidation;
  let body = {
    destinationAllowDeviating: true,
    destinationCity: state.deliveryCity.value.trim(),
    destinationCountryCode: state.deliveryCountry.value,
    destinationPostalCode: state.deliveryPostalCode.value,
    sourceAllowDeviating: true,
    sourceCity: state.pickupCity.value.trim(),
    sourceCountryCode: state.pickupCountry.value,
    sourcePostalCode: state.pickupPostalCode.value,
  };
  let resultMap = {
    source: false,
    destination: false,
  };

  await apiRequest(urlValidation, 'POST', {
    body: body,
  })
    .then(result => {
      if (result.status === CONST.STATUS_OK) {
        resultMap.source = result.data.source;
        resultMap.destination = result.data.destination;
      } else {
        resultMap.error = true;
      }
    })
    .catch(() => {
      resultMap.error = true;
    });

  return resultMap;
};

export const getPickupPhoneNumber = phoneNumber =>
  phoneNumber
    ? [
        {
          code: phoneNumber.split('-')[0],
          error: false,
          isRequired: true,
          number: phoneNumber.split('-')[1] || '',
        },
      ]
    : [
        {
          code: config.defaultCountryPhoneCode,
          error: false,
          isRequired: true,
          number: '',
        },
      ];
