import { Box } from '@mui/material';
import React, { useState } from 'react';
import { DgItemDetail } from './DgItemDetail';
import { DgSearch } from './DgSearch';

export const dangerousGoodsExclusions = products => {
  return products?.find(item => item.code === '202' || item.code?.toUpperCase() === 'ECI')?.dangerousGoodsExclusions;
};

export const isExcludedDangerousItem = (dangerousItem, dangerousGoodsExclusions) => {
  let dgItemClassExclusion = undefined;
  const dgItemClassExclusionExact = dangerousGoodsExclusions?.find(item => item.class === dangerousItem.class);
  if (dgItemClassExclusionExact) {
    // exist exact match class
    dgItemClassExclusion = dgItemClassExclusionExact;
  } else {
    // if does not exist exact match class => find some parent
    let dgItemClass = dangerousItem.class;
    while (dgItemClass) {
      dgItemClassExclusion = dangerousGoodsExclusions?.find(item => item.class.startsWith(dgItemClass));
      if (dgItemClassExclusion) {
        break;
      }
      // if not found => remove last character and search again
      dgItemClass = dgItemClass.slice(0, -1);
    }
  }
  if (dgItemClassExclusion === undefined) {
    return false;
  }
  if (dgItemClassExclusion.allowed === true) {
    const exceptionExist = dgItemClassExclusion.exceptions.find(
      item =>
        (item.type === 'unNumber' && item.value === dangerousItem.unNumber) ||
        (item.type === 'packageGroup' && item.value === dangerousItem.packingGroup),
    );
    return !!exceptionExist;
  } else {
    const exceptionExist = dgItemClassExclusion.exceptions.find(
      item =>
        (item.type === 'unNumber' && item.value === dangerousItem.unNumber) ||
        (item.type === 'packageGroup' && item.value === dangerousItem.packingGroup),
    );
    return !exceptionExist;
  }
};

export const DangerousGoods = ({
  onDetailSubmit,
  onDetailError,
  initialValues,
  validateRequired = true,
  onDeleteItem,
  disableDelete,
  dangerousGoodsExclusions,
  dgWeightError,
  hideErrors,
}) => {
  const [dgmId, setDgmId] = useState(initialValues?.dgmId?.value ?? null);

  const isInitialDgmId = dgmId === initialValues?.dgmId?.value;

  return (
    <Box px={1} data-testid="dgWrapper">
      <Box
        display="flex"
        flexDirection="column"
        p={1}
        gap={2}
        sx={{ border: '.1rem solid #949494', borderRadius: '.4rem' }}
      >
        {dgmId ? (
          <DgItemDetail
            dgmId={dgmId}
            setDgmId={setDgmId}
            onDetailSubmit={onDetailSubmit}
            onDetailError={onDetailError}
            // if different dgmId is selected, we cannot use the initial values
            initialValues={isInitialDgmId ? initialValues : {}}
            validateRequired={validateRequired}
            onDeleteItem={onDeleteItem}
            disableDelete={disableDelete}
            dgWeightError={dgWeightError}
            hideErrors={hideErrors}
          />
        ) : (
          <DgSearch
            setDgmId={setDgmId}
            onDetailSubmit={onDetailSubmit}
            onDeleteItem={onDeleteItem}
            disableDelete={disableDelete}
            dangerousGoodsExclusions={dangerousGoodsExclusions}
          />
        )}
      </Box>
    </Box>
  );
};
