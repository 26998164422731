import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  hasDangerousGoods: false,
};

export const templatesSlice = createSlice({
  name: 'templates',
  initialState,
  reducers: {
    setHasDangerousGoods: (state, action) => {
      state.hasDangerousGoods = action.payload;
    },
  },
});

export const { setHasDangerousGoods } = templatesSlice.actions;

export const templatesReducer = templatesSlice.reducer;
