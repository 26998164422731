import React, { Component } from 'react';
import { Translation } from 'react-i18next';
import { config, CONST } from '../../data-config';
import { apiLinks } from 'config/api-config';
import { MyContext } from '../../context/context';
import { Error } from 'components';
import { checkSubmitDetails, prepareUserData } from './TermsAndSubmit-helpers';
import { postalCodeValidationAPIRequest } from '../EnterPickupLocation/EnterPickupLocation-helpers';
import { apiRequest, setCookie, handleAddressBookCalls } from 'globals/utils/requests';
import getBaseUrl from 'globals/utils/getUrlBase';
import { analytics } from 'globals/utils/analytics';
import { getTransactionId } from 'globals/utils/generators';
import { ROUTES, withRouter } from 'router';
import { postalCodeValidation } from '../../../globals/helpers/pickup-helpers';

const ENV_DEV = process.env.NODE_ENV === 'development';

class TermsAndSubmit extends Component {
  constructor(props) {
    super(props);

    this.state = {
      submitError: false,
      isLoading: false,
      apiError: false,
      addressBookError: false,
      addressBookMaxLimitReachedError: false,
      transactionId: getTransactionId(CONST.PUBLIC_BOOKING_PICKUP),
    };

    this.HOURS_PER_MONTH = 720;
  }

  proceedToSubmit = context => {
    const params = {
      body: prepareUserData(context.state),
      headers: {
        Referer: getBaseUrl(null, 1),
        transactionId: this.state.transactionId,
      },
    };

    ENV_DEV && console.message('Submit Request started ...');

    setCookie(
      'pickupAddress',
      encodeURIComponent(
        JSON.stringify({
          confirmationEmail: params.body.confirmationEmail,
          ...params.body.pickupRequestInputData.parties[0],
        }),
      ),
      this.HOURS_PER_MONTH,
      'h',
    );

    this.setState({ isLoading: true, apiError: false });

    apiRequest(apiLinks.postPortalPickupBooking, 'POST', params).then(async result => {
      if (result.status === CONST.STATUS_OK) {
        context.updateState({ isLoading: false, apiError: false });
        ENV_DEV && console.success('API Request successful ...');

        analytics('order', CONST.ANALYTICS);

        if (result.data.status === 0 || result.data.status === 2) {
          if (result.data.status === 2) {
            context.updateState({
              publicPickupBookingSubmited: true,
              pickupOrderResult: result.data,
              pickupOrderMovedToNextDay: true,
            });
            analytics('Thank You Moved', CONST.ANALYTICS);
          } else {
            context.updateState({
              publicPickupBookingSubmited: true,
              pickupOrderResult: result.data,
            });
            analytics('Thank You Accepted', CONST.ANALYTICS);
          }
        } else if (result.data.status === 1) {
          context.updateState({
            redThankYouPage: true,
            redThankYouErrorMessage: result.data.message,
            pickupOrderResult: result.data,
          });
          analytics('Thank You Rejected', CONST.ANALYTICS);
        }
      } else {
        ENV_DEV && console.alert('API Request failed ...');
        this.setState({ isLoading: false, apiError: true });
      }
    });
  };

  checkCityError = (map, stateToSet) => {
    stateToSet.sourceCityError = false;
    stateToSet.destinationCityError = false;

    if (config.numberPostalCodeCountries.indexOf(stateToSet.pickupCountry.value) !== -1 && !map.source)
      stateToSet.sourceCityError = true;
    if (config.numberPostalCodeCountries.indexOf(stateToSet.deliveryCountry.value) !== -1 && !map.destination)
      stateToSet.destinationCityError = true;
  };

  cityValidation = ({ cityValidationError }) => {
    return Object.keys(cityValidationError).length > 0;
  };

  checkAndSubmit = async context => {
    let { hasErrors, stateToSet } = await checkSubmitDetails(context);
    const cityNotValid = this.cityValidation(context.state);

    if (cityNotValid) {
      hasErrors = true;
    }

    const newState = JSON.parse(JSON.stringify(this.state));
    let result = {};

    stateToSet.sourcePostalCodeError = false;
    stateToSet.sourceCityError = false;
    stateToSet.destinationPostalCodeError = false;

    newState.apiError = false;
    newState.submitError = false;
    newState.isLoading = true;
    newState.addressBookError = false;
    newState.addressBookMaxLimitReachedError = false;

    this.setState(newState, async () => {
      if (!hasErrors) {
        if (context.state.selectedProducts.length === 1 && !context.state.shippingToMultipleAddresses.value) {
          const product = context.state[context.state.selectedProducts[0]];

          result = await postalCodeValidation(context.state);

          if (result.hasOwnProperty('error') && result.error) {
            hasErrors = true;
            newState.apiError = true;
          } else if ((!result.sourceBookable || !result.destinationBookable) && product.domestic) {
            hasErrors = true;
            newState.submitError = true;

            stateToSet.sourcePostalCodeError = !result.sourceBookable;
            stateToSet.destinationPostalCodeError = !result.destinationBookable;
            // this.scrollToTheCityAndZipCodeSection();
          } else if (!result.error && result.destinationBookable && result.sourceBookable) {
            let postalCodeValidationRequest = await postalCodeValidationAPIRequest(context.state);
            if (postalCodeValidationRequest.hasOwnProperty('error') && postalCodeValidationRequest.error) {
              newState.apiError = true;
              newState.submitError = true;
            } else {
              this.checkCityError(postalCodeValidationRequest, stateToSet);
            }

            if (
              stateToSet.sourceCityError ||
              stateToSet.sourcePostalCodeError ||
              stateToSet.destinationCityError ||
              stateToSet.destinationPostalCodeError
            ) {
              newState.submitError = true;
            }
          } else if (!result.error && product.international) {
            stateToSet.sourcePostalCodeError = result.internationalSourcePostalCode;
            stateToSet.destinationPostalCodeError = result.internationalDestinationPostalCode;

            if (stateToSet.sourcePostalCodeError || stateToSet.destinationPostalCodeError) {
              newState.submitError = true;
            }
          }
        } else {
          // If multiple products or shipping to multiple addresses, check sender postal code validity
          ENV_DEV && console.log('Multiple products or receivers selected');

          // Single point postal code/city validation
          result = await postalCodeValidation(context.state, true);

          if (!result.data.source.postalCode) {
            hasErrors = true;
            newState.submitError = true;
            stateToSet.sourcePostalCodeError = true;
          } else if (context.state.pickupCity.value.trim().toUpperCase() !== result.data.source.city) {
            hasErrors = true;
            newState.submitError = true;
            stateToSet.sourceCityError = true;
          }
        }
      } else {
        newState.submitError = true;
      }

      context.updateState(stateToSet, async () => {
        if (!hasErrors && !newState.apiError && !newState.submitError) {
          const { hasAddressBookErrors, hasMaxLimitReached } = await handleAddressBookCalls(context);
          // proceed only when address book call is successful
          if (!hasAddressBookErrors && !hasMaxLimitReached) {
            this.proceedToSubmit(context);
          } else {
            newState.isLoading = false;
            newState.addressBookError = hasAddressBookErrors || false;
            newState.addressBookMaxLimitReachedError = hasMaxLimitReached || false;
          }
        } else {
          newState.isLoading = false;
        }
        this.setState(newState);
      });
    });
  };

  render() {
    return (
      <div className="frc__generic-section--wrapper">
        <Translation>
          {t => (
            <MyContext.Consumer>
              {context => (
                <>
                  <p
                    className="margin-bottom-1"
                    dangerouslySetInnerHTML={{
                      __html:
                        t('general|If you would like to learn more about DHL Freight') +
                        ' <strong><a href="' +
                        t('pageLinks|terms-and-conditions') +
                        '/' +
                        ROUTES.termsOfUse +
                        '"  target="_blank" rel="noopener noreferrer">' +
                        t('general|Terms and Conditions') +
                        '.</a></strong>',
                    }}
                  />

                  <p
                    className="margin-bottom-2"
                    dangerouslySetInnerHTML={{
                      __html:
                        t(
                          'general|If you would like to learn more about how DHL uses your personal data, please read our',
                        ) +
                        ' <strong><a href="' +
                        this.props.linkTo(ROUTES.privacyPolicy) +
                        '" target="_blank" rel="noopener noreferrer">' +
                        t('general|Privacy Notice') +
                        '.</a></strong>',
                    }}
                  />

                  <div>
                    <div className="l-grid--w-100pc-s">
                      <div className={' l-grid--w-100pc-s ' + (this.state.isLoading ? ' is-loading ' : '')} />
                    </div>
                    <div
                      className="l-grid--w-100pc-s l-grid--w-40pc-s-l l-grid--w-30pc-m"
                      style={{ marginLeft: 'auto' }}
                    >
                      <button
                        id="placeOrderBtn"
                        className={
                          'base-button base-button--wide l-grid--w-100pc-s ' +
                          (this.state.isLoading ? ' hidden ' : '') +
                          (!context.state.pickupDate.value ? ' disabled ' : '')
                        }
                        onClick={() => this.checkAndSubmit(context)}
                        disabled={this.state.isLoading || !context.state.pickupDate.value}
                        data-tracking="fcp-public-booking-submit"
                      >
                        <span>{t('general|labels|buttons|Place Order')}</span>
                      </button>
                      {this.state.addressBookError ||
                      this.state.addressBookMaxLimitReachedError ||
                      this.state.apiError ||
                      this.state.submitError ||
                      !context.state.pickupDate.value ? (
                        <Error
                          name="FillFormAndBookingFailed"
                          className="text-center"
                          message={
                            this.state.submitError || !context.state.pickupDate.value
                              ? t('general|errors|Please fill properly all necessary fields')
                              : this.state.addressBookMaxLimitReachedError
                              ? t(`general|Address book max limit error`)
                              : this.state.addressBookError
                              ? t(`general|Address book save update error`)
                              : t(`general|Booking failed. Try again or call technical support.`) +
                                ' ' +
                                t('general|or') +
                                ' ' +
                                `<strong><a href="${t('pageLinks|chat')}" 
                                                class ="frc__chat-with-us"
                                                target="_blank" rel="noopener noreferrer">${t(
                                                  `general|Chat with us`,
                                                )}</a></strong>` +
                                ' <br>' +
                                t('general|ID') +
                                ': ' +
                                this.state.transactionId
                          }
                        />
                      ) : (
                        ''
                      )}
                    </div>
                  </div>
                </>
              )}
            </MyContext.Consumer>
          )}
        </Translation>
      </div>
    );
  }
}

export default withRouter(TermsAndSubmit);
