import React from 'react';
import { useTranslation } from 'react-i18next';
import { getDisplayedPrice } from 'globals';
import { useGetCountryConfig } from '../../../../hooks/api/useGetCountryConfig';

export const ModalViewEditList = ({ shipment }) => {
  const { t } = useTranslation();
  const { data: countryConfig } = useGetCountryConfig();

  let sender;
  let receiver;
  let displayedPrice = 'N/A';

  if (shipment) {
    sender = shipment.sender;
    receiver = shipment.receiver;
    displayedPrice = getDisplayedPrice(t, shipment.calculatedPrice, {
      CURRENCY_CODE: shipment.calculatedPriceCurrency
        ? shipment.calculatedPriceCurrency
        : countryConfig?.defaultCurrency,
    });
  }

  return (
    <div className="margin-bottom-1 frc__modal-view-edit-list ">
      <div
        key={shipment.id}
        className="frc__modal-padding frc__modal-content l-grid--w-100pc-s l-grid--w-100pc-m-m l-grid--w-100pc-m-s"
      >
        <div className="l-grid l-grid--w-100pc-s margin-bottom-1">
          <div className=" l-grid--w-50pc-s l-grid--w-50pc-m-s l-grid--w-50pc-m-m">
            <div className="frc__modal-attribute-color">{t('general|From')}</div>
            <div className="frc__modal-word-wrap">
              <strong> {sender.companyName} </strong>
              <div>{sender.street}</div>
              <div>
                {sender.postalCode} {sender.city}
              </div>
              {sender.country}
            </div>
          </div>
          <div className=" l-grid--w-50pc-s l-grid--w-50pc-m-s l-grid--w-50pc-m-m ">
            <div className="frc__modal-attribute-color">{t('general|To')}</div>
            <div className="frc__modal-word-wrap">
              <strong> {receiver.companyName} </strong>
              <div>{receiver.street}</div>
              <div>
                {receiver.postalCode} {receiver.city}
              </div>
              {receiver.country}
            </div>
          </div>
        </div>
        <div className="l-grid--w-100pc-s">
          <div className="frc__modal-attribute-color">{t('general|Summary')}</div>

          <div className="frc__summary-modal-row l-grid--left-s">
            <div>{t('products|common|productLabel')}: </div>
            <div>{shipment.productName}</div>
          </div>
          <div className="frc__summary-modal-row l-grid--left-s">
            <div>{t(`general|Estimated Price`)}: </div>
            <div>{displayedPrice || ''}</div>
          </div>
          <div className="frc__summary-modal-row l-grid--left-s">
            <div>{t('general|labels|metrics|quantity')}: </div>
            <div>{shipment.totalNumberOfPieces}</div>
          </div>
          <div className="frc__summary-modal-row l-grid--left-s">
            <div>{t('general|labels|metrics|Weight')}: </div>
            <div>{shipment.totalWeight + ' Kg'}</div>
          </div>
        </div>
      </div>
    </div>
  );
};
