import { createSelector, createSlice } from '@reduxjs/toolkit';
import { findFirstCommonDate } from '../utils/dates';

const initialState = {
  // accounts and products:
  accountNumber: undefined,
  accountsWithProducts: [],

  // residential address:
  enableResidentialFromAddress: true,
  enableResidentialToAddress: true,
  residentialAddressFromRequired: false,
  residentialAddressToRequired: false,

  // pickup dates:
  pickupDates: [],
  productPickupDates: {},
  selectedPickupDate: undefined,
};

export const portalOrderSlice = createSlice({
  name: 'portalOrder',
  initialState,
  reducers: {
    setProductPickupDates: (state, action) => {
      state.productPickupDates = action.payload;
      state.pickupDates = [];
      state.selectedPickupDate = undefined;
    },
    setPickupDates: (state, action) => {
      state.pickupDates = action.payload;
    },
    setSelectedPickupDate: (state, action) => {
      state.selectedPickupDate = action.payload;
    },
    setAccountNumber: (state, action) => {
      state.accountNumber = action.payload;
    },
    setAccountsWithProducts: (state, action) => {
      const accounts = action.payload;
      state.accountsWithProducts = accounts.map(account => {
        const disabled = account.productRangeOptions.every(productRangeOption => productRangeOption.disabled);
        return {
          ...account,
          disabled,
        };
      });
    },
    setEnableResidentialFromAddress: (state, action) => {
      state.enableResidentialFromAddress = action.payload;
    },
    setEnableResidentialToAddress: (state, action) => {
      state.enableResidentialToAddress = action.payload;
    },
    setResidentialAddressFromRequired: (state, action) => {
      state.residentialAddressFromRequired = action.payload;
    },
    setResidentialAddressToRequired: (state, action) => {
      state.residentialAddressToRequired = action.payload;
    },
    resetPortalOrderSlice: () => initialState,
  },
});

export const selectPortalOrder = state => state.portalOrder;
export const selectedPickupDateSelector = createSelector([selectPortalOrder], ({ selectedPickupDate }) => ({
  selectedPickupDate,
}));
export const pickupDatesSelector = createSelector([selectPortalOrder], ({ pickupDates }) => ({
  pickupDates,
}));
export const productPickupDatesSelector = createSelector([selectPortalOrder], ({ productPickupDates }) => {
  const defaultPickupDate = findFirstCommonDate(productPickupDates);
  return {
    productPickupDates,
    defaultPickupDate,
  };
});

export const {
  setAccountNumber,
  setAccountsWithProducts,
  setEnableResidentialFromAddress,
  setEnableResidentialToAddress,
  setResidentialAddressFromRequired,
  setResidentialAddressToRequired,
  setProductPickupDates,
  setPickupDates,
  setSelectedPickupDate,
  resetPortalOrderSlice,
} = portalOrderSlice.actions;

export const portalOrderReducer = portalOrderSlice.reducer;
