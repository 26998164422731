import React from 'react';
import { config } from '../../../data-config';

import './Custom.css';

export default function AddressCustomCell(props) {
  const { isSender, cellValue, isShipmentDetail, diffSenderReceiver = false, servicePoint = false, t } = props;
  const party = isSender ? cellValue.sender : cellValue.receiver;

  return party ? (
    <div className="frc__custom-cell-line-break">
      {isShipmentDetail && (
        <div className="margin-bottom-1">
          <strong>
            {diffSenderReceiver
              ? isSender
                ? `${t('general|Pickup Address')}:`
                : servicePoint
                ? party.countryCode === config.defaultCountry
                  ? `${t('general|Service Point Address')}:`
                  : `${t(`general|Delivery to`)}:`
                : `${t(`general|Delivery Address`)}:`
              : isSender
              ? `${t('general|Sender')}:`
              : `${t('general|Receiver')}:`}
          </strong>
          <div> {party.name} </div>

          <a href={`mailto:${party.email}`} className="frc__data-grid-email">
            {party.email}
          </a>

          {party.phone && <div> {party.phone} </div>}
        </div>
      )}

      <div>{party.companyName || ''}</div>
      <div>{party.street}</div>

      <div>
        {servicePoint && party.postalCode && party.countryCode
          ? party.countryCode + '-' + party.postalCode
          : party.postalCode || ''}{' '}
        {party.city || ''}
      </div>
      {party.country ? t(`countries|${party.country}`) : ''}
      {!diffSenderReceiver && <div> {isSender ? cellValue.sreference || '' : cellValue.rreference || ''}</div>}
    </div>
  ) : (
    ''
  );
}
