import { apiRequest } from 'globals/utils/requests';
import { CONST } from '../../data-config';
import { apiLinks } from 'config/api-config';
import { formRequestBodyForShipmentCall } from '../ActionUtils';

export const getUserDetails = async () => {
  const resultMap = {};

  await apiRequest(apiLinks.getUserData, 'GET')
    .then(result => {
      if (result.status === CONST.STATUS_OK) {
        resultMap.data = result.data;
      } else {
        resultMap.error = true;
      }
    })
    .catch(() => {
      resultMap.error = true;
    });

  return resultMap;
};

export const downloadShipmentHistoryCSV = async state => {
  const resultMap = {};

  const params = {
    headers: {
      accept: 'application/octet-stream',
      responseType: 'blob',
    },
  };

  params.body = formRequestBodyForShipmentCall(state);

  await apiRequest(apiLinks.downloadShipmentHistory, 'POST', params)
    .then(result => {
      if (result.size) {
        let blob = new Blob([result], {
          type: 'application/vnd.ms-excel',
        });

        const filename = result.filename;

        if (navigator.msSaveBlob) {
          // IE 10+
          navigator.msSaveBlob(blob, filename);
        } else {
          let link = document.createElement('a');

          if (link.download !== undefined) {
            let url = URL.createObjectURL(blob);

            link.setAttribute('href', url);
            link.setAttribute('download', filename);
            link.style = 'visibility:hidden';
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
          }
        }
      } else {
        resultMap.error = true;
      }
    })
    .catch(() => {
      resultMap.error = true;
    });

  return resultMap;
};
