import { t } from 'i18next';
import React from 'react';
import { config } from '../../portal-order/data-config';
import { TitleParagraph } from '../components/ThankYou/TitleParagraph';
import { formatDate, formatDateTimeReadableLocale } from '../utils/formatting';
import { shouldUseCommitedDelivery } from './product';

/**
 * Calculate necessary derived attributes and flags from Additional Services to
 * be used in DeliveryDate calculations
 * @param additionalServices additional services from context state
 */
export const parseVasDataForDeliveryDate = additionalServices => {
  const fixedDeliveryDateVAS = additionalServices.find(i => i.code === 'fixedDeliveryDate');
  const selectedFixedDeliveryDate = fixedDeliveryDateVAS?.value;
  const fixedDeliveryDate =
    fixedDeliveryDateVAS && fixedDeliveryDateVAS.groups ? fixedDeliveryDateVAS.groups[0]?.options[0][0]?.value : null;

  const timeDefiniteUnloadingVAS = additionalServices.find(i => i.code === 'timeDefiniteUnloading');
  const selectedTimeUnloadingDate = timeDefiniteUnloadingVAS?.value;
  const timeUnloadingDate =
    timeDefiniteUnloadingVAS && timeDefiniteUnloadingVAS.groups
      ? timeDefiniteUnloadingVAS.groups[0]?.options[0][0]?.value
      : null;

  return {
    selectedFixedDeliveryDate,
    fixedDeliveryDate,
    selectedTimeUnloadingDate,
    timeUnloadingDate,
  };
};

/**
 * "To date" logic for FDD, TDU, Estimated date,...
 * @param state state where is information about VAS list, selectedProduct
 * @param presenter determines how the data is presented on UI
 */
export const toDateComponent = (state, presenter) => {
  const productCode = state?.selectedProduct?.code;
  const estimatedDeliveryDate = state?.selectedProduct?.deliveryDate;
  const isCommitedDelivery = shouldUseCommitedDelivery(productCode);

  const { selectedFixedDeliveryDate, fixedDeliveryDate, selectedTimeUnloadingDate, timeUnloadingDate } =
    parseVasDataForDeliveryDate(state.additionalServices);

  if (selectedFixedDeliveryDate && fixedDeliveryDate) {
    return presenter({ label: t(`additionalServices|fixedDeliveryDate|name`), value: fixedDeliveryDate });
  }

  if (config.estimatedDeliveryNotVisibleForProducts.includes(productCode)) {
    return presenter({ label: t(`general|Delivery date unavailable`), value: '' });
  }

  if (selectedTimeUnloadingDate) {
    return presenter({
      label: t(`additionalServices|timeDefiniteUnloading|name`),
      value: formatDateTimeReadableLocale(timeUnloadingDate, false),
    });
  }

  if (estimatedDeliveryDate === 'ltc_error') {
    return presenter({ label: t('general|LTC Delivery date unavailable'), value: '' });
  }

  if (estimatedDeliveryDate && isCommitedDelivery) {
    return presenter({
      label: isCommitedDelivery ? t('general|committedDeliveryDate') : t('general|Estimated Delivery By'),
      value: estimatedDeliveryDate,
    });
  }

  if (estimatedDeliveryDate) {
    return presenter({ label: t(`general|Shipment Date`), value: estimatedDeliveryDate });
  }

  return presenter({ label: t(`general|Delivery date unavailable`), value: '' });
};

/**
 * "From date" logic for Pickup date, TDL, ...
 * @param state state where is information about VAS list, selectedProduct
 * @param presenter determines how the data is presented on UI
 */
export const fromDateComponent = (state, presenter) => {
  const selectedProduct = state?.selectedProduct?.code !== '' ? state[state?.selectedProduct?.code] : '';
  const timeDefiniteLoadingVAS = state.additionalServices.find(i => i.code === 'timeDefiniteLoading');
  const selectedTimeLoadingDate = timeDefiniteLoadingVAS?.value;
  const pickupDate = state?.pickupDate?.value;

  if (selectedTimeLoadingDate) {
    const timeLoadingDate =
      timeDefiniteLoadingVAS && timeDefiniteLoadingVAS.groups
        ? timeDefiniteLoadingVAS.groups[0]?.options[0][0]?.value
        : null;

    return presenter({
      label: t(`additionalServices|timeDefiniteLoading|name`),
      value: formatDateTimeReadableLocale(timeLoadingDate, false),
    });
  } else if (pickupDate === 'ltc_error') {
    return null;
  } else if (selectedProduct && pickupDate) {
    return presenter({ label: t(`general|Pickup Date`), value: formatDate(pickupDate) });
  } else {
    return null;
  }
};

/**
 * A presenter for SideSummaryPanel.
 * @param label label of the date or error message
 * @param value main value
 */
export const sidePanelDatePresenter = ({ label, value }) => {
  return (
    <div>
      {label}
      {value && ': ' + value}
    </div>
  );
};

/**
 * A presenter for ReviewAndBook.
 * @param label label of the date or error message
 * @param value main value
 */
export const reviewAndBookDatePresenter = ({ label, value }) => {
  return (
    <>
      <span>{label}</span>
      {value && <strong>{value}</strong>}
    </>
  );
};

/**
 * A presenter for Thank You Page.
 * @param label label of the date or error message
 * @param value main value
 */
export const thankYouPageDatePresenter = ({ label, value }) => {
  return <TitleParagraph label={label} value={value} />;
};
