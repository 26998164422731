import { useNavigate, useParams } from 'react-router-dom';
import { isValidCountryLanguage } from '../utils/urls';
import { ROUTES } from './routes';

export const useRouting = () => {
  const navigateRouter = useNavigate();
  const { lang } = useParams();

  const navigate = (route, options) => {
    if (lang && isValidCountryLanguage(lang)) {
      const url = `/${lang}/${route}`;
      navigateRouter(url, options);
    } else if (Object.values(ROUTES).includes(route)) {
      const url = `/${route}`;
      navigateRouter(url, options);
    }
    return '/';
  };

  const linkTo = route => {
    if (lang && isValidCountryLanguage(lang)) {
      const url = `/${lang}/${route}`;
      return url;
    } else if (Object.values(ROUTES).includes(route)) {
      const url = `/${route}`;
      return url;
    }
    return '/';
  };

  return {
    navigate,
    navigateRouter,
    linkTo,
  };
};
