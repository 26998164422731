import { AUTHORIZATION } from '../../Login/constants';
import { apiLinks } from '../../config/api-config';
import { rootApi } from './apiSlice';

export const USER_API_TAG = 'User';

const userApi = rootApi.injectEndpoints({
  tagTypes: [USER_API_TAG],
  endpoints: builder => ({
    getUserAccountsAndProducts: builder.query({
      providesTags: [USER_API_TAG],
      query: () => ({
        url: apiLinks.getUsersAccountsAndProductsData,
        headers: {
          Authorization: localStorage.getItem(AUTHORIZATION),
        },
      }),
      transformResponse: (response, meta, arg) => {
        const productSpecifications = response.productSpecifications.map(product => {
          const productCode = Object.keys(product)[0];
          return {
            ...product[productCode],
          };
        });
        const accountsWithProductsAndSpecs = response.accounts.map(account => {
          const products = account.products.map(product => {
            const productSpecification = productSpecifications.find(
              productSpec => productSpec.code === product.productCode,
            );
            return {
              ...product,
              productSpecification,
            };
          });
          return {
            ...account,
            products,
            atLeastOneProductB2C: products.some(product =>
              product.productSpecification?.marketAvailability?.some(market => market.name?.toUpperCase() === 'B2C'),
            ),
          };
        });
        return {
          ...response,
          accountsWithProductsAndSpecs,
        };
      },
      keepUnusedDataFor: 60 * 30,
    }),
  }),
});

export const { useGetUserAccountsAndProductsQuery, useLazyGetUserAccountsAndProductsQuery } = userApi;
