import { globalConfig, GLOBAL_CONST } from 'globals/data-config-global';
import { SERVICE_POINT_PRODUCT_CODE } from '../globals/constants';

const componentConfig = {
  maxSearchTermDigits: 35,
  initialPageSize: 15, // IMP: initialPageSize and paginationPerRow (1st item) are interdepent; should have same value
  shipmentStatus: [
    { label: 'Incomplete', name: GLOBAL_CONST.IN_COMPLETE, value: false },
    { label: 'Booked', name: GLOBAL_CONST.BOOKED, value: false },
    { label: 'InTransit', name: GLOBAL_CONST.IN_TRANSIT, value: false },
    { label: 'Delivered', name: GLOBAL_CONST.DELIVERED, value: false },
    { label: 'Order Sent', name: GLOBAL_CONST.ORDER_SENT, value: false },
  ],
  shipmentDate: [
    { label: 'Last 14 Days', value: 'last14Days' },
    { label: 'Last 30 Days', value: 'last30Days' },
    { label: 'Custom Date Range', value: 'customDateRange' },
  ],
  fromNoOfDays: 14,
  fromNoOfMonth: 6,
  paginationInitialNumberOfItems: 10,
  paginationItemsPerRow: [10, 25, 50, 100],
  optionsSchemaRequiredAsDateTime: ['dateTime', 'invoiceDate'],

  printDocumentTypes: [
    { type: 'label', label: 'Label' },
    { type: 'waybill', label: 'Waybill' },
    { type: 'shipmentlist', label: 'Shipment List' },
  ],
  productsReturnShipmentAvailable: [SERVICE_POINT_PRODUCT_CODE, '109'],
};

const COMPONENT_CONST = {
  API_DOMAIN: 'portalSavedShipments',
  PORTAL_SAVED_SHIPMENTS: 'PSS',
  HOURS_PER_MONTH: 720,
};

export const config = Object.assign({}, globalConfig, componentConfig);
export const CONST = Object.assign({}, GLOBAL_CONST, COMPONENT_CONST);
